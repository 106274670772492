import { useCallback } from "react";
import { useCustomFetch } from "../custom-fetch";
import IUser from "../shared/IUser";
import { storage } from "../utils/localstorage-utils";



export const useAuthService = () => {
  const customFetch = useCustomFetch();

  const authenticate = useCallback(async (token: string) => {
    const [message, resp] = await customFetch<string>('/authenticate', {
      method: "POST",
      body: JSON.stringify({ token })
    });
    if (!resp.ok) {
      throw new Error(message);
    }
    const authorization = resp.headers.get('Authorization');
    const expiration = resp.headers.get('Expiration');
  
    if (expiration && authorization) {
      storage.saveAuthentication(authorization.replace('Bearer ', ""), expiration);
    }
  }, [customFetch]);
  
  const getAuthUser = useCallback(async (): Promise<IUser> => {
    const [user] = await customFetch<IUser>('/api/users/me', {});
    return user;
  }, [customFetch]);

  return { authenticate, getAuthUser };
}




