import IProductType from "./IProductType";
import ISlabType from "./ISlabType";

export const WIRE_TYPE_THIN_KGM = 0.408;
export const WIRE_TYPE_THICK_KGM = 0.73;

interface IWireType {
  id: string;
  name: string;
  tendonCountThick: string;
  tendonCountThin: string;
  slabType?: ISlabType;
  productType?: IProductType;
  meterWeight: string;
  archived: boolean;
  updatedString: string;
}

export default IWireType;
