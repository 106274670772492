import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useImperativeHandle, useState } from "react";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { IExtraName } from "./SlabTypeExtraNames";
import { RefHandle } from "../../shared/RefHandle";

interface IProps {
  extraName?: IExtraName;
  onDelete: () => void;
}

enum EInputs {
  name = "name",
}

const SlabTypeExtraName: React.ForwardRefRenderFunction<
  RefHandle<IExtraName>,
  IProps
> = ({ extraName, onDelete }, ref) => {
  const { createInput, submit } = useExtraNameInputs(extraName);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      if (!data) return null;
      return data;
    },
  }));

  return (
    <div
      style={{
        margin: "1rem 0",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      {createInput(EInputs.name)}
      <Button
        onClick={onDelete}
        size={EButtonSize.X_SMALL}
        color={EButtonColor.DANGER}
        icon={faTrash}
        title="Poista"
      />
    </div>
  );
};

const useExtraNameInputs = (data?: IExtraName) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default forwardRef(SlabTypeExtraName);
