import { faCheck, faPen, faRoute } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useMemo, useState } from "react";
import { queryClient } from "../../App";
import imgTruckCab from '../../assets/truck-cab.png';
import { usePublicQRCodesService } from "../../services/publicQRCodes-service";
import { QueryKey } from "../../services/query-keys";
import { getFactoryAddress } from "../../shared/IOfferRow";
import IWaybill, { EWaitingReason, WAITING_REASON_OPTIONS } from "../../shared/IWaybill";
import IWaybillDTO from "../../shared/IWaybillDTO";
import { formatDate, getCurrentTime } from "../../utils/date-utils";
import Accordion from "../ui/Accordion/Accordion";
import Button, { EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  waybill: IWaybillDTO;
}

enum EInputs {
  arriveTime = "arriveTime",
  departTime = "departTime",
  loadEndTime = "loadEndTime",
  unloadStartTime = "unloadStartTime",
  waitingTime = "waitingTime",
  waitingReason = "waitingReason"
}

const elementNamesStyle = {
  fontWeight: '600'
}

const WaybillQR: React.FC<IProps> = ({ waybill }) => {
  const { elementDtos: elements, factoryDto: factory } = waybill;
  const { updateTimeWaybill } = usePublicQRCodesService();
  const openConfirmModal = useConfirmModal();
  const { createInput, inputs } = useWaybillInputs(waybill);

  const arriveTime = inputs[EInputs.arriveTime].value as string;
  const departTime = inputs[EInputs.departTime].value as string;
  const loadEndTime = inputs[EInputs.loadEndTime].value as string;
  const unloadStartTime = inputs[EInputs.unloadStartTime].value as string;
  const waitingTime = inputs[EInputs.waitingTime].value as string;
  const waitingReasonInput = inputs[EInputs.waitingReason].value as string;
  const waitingReason = waitingReasonInput as EWaitingReason;

  const [loading, setLoading] = useState(false);
  const arriveTimeSaved = useMemo(() => arriveTime && arriveTime === waybill.arriveTime, [arriveTime, waybill.arriveTime]);
  const departTimeSaved = useMemo(() => departTime && departTime === waybill.departTime, [departTime, waybill.departTime]);
  const loadEndTimeSaved = useMemo(() => loadEndTime && loadEndTime === waybill.loadEndTime, [loadEndTime, waybill.loadEndTime]);
  const unloadStartTimeSaved = useMemo(() => unloadStartTime && unloadStartTime === waybill.unloadStartTime, [unloadStartTime, waybill.unloadStartTime]);
  const waitingTimeSaved = useMemo(() => waitingTime && waitingTime === waybill.waitingTime, [waitingTime, waybill.waitingTime]);
  const waitingReasonSaved = useMemo(() => waitingReasonInput && waitingReasonInput === waybill.waitingReason, [waitingReasonInput, waybill.waitingReason]);

  const getSlotsWeight = (slots: string[]) => {
    const data = waybill.waybillRows?.filter((row) => row.slot && slots.includes(row.slot)).reverse();
    const totalWeightTons = data?.reduce((sum, row) => {
      const element = elements?.find(e => e.id === row.elementId);
      const weightTons = parseFloat(element?.weightTons ?? "0");
      return sum + weightTons;
    }, 0) ?? 0;
    return totalWeightTons;
  }

  const totalWeightCar = getSlotsWeight(["A", "B", "C", "D", "1", "2"]);
  const totalWeightTruck = getSlotsWeight(["E", "F", "G", "H", "I", "J", "3", "4", "5"]);
  const totalWeight = totalWeightCar + totalWeightTruck;

  const totalAmount = waybill.waybillRows?.length ?? "0";

  const elementText = "Kuorma: "+totalAmount+" kpl / "+totalWeight.toFixed(2)+" ton"

  const getSlotElements = (slot: string) => {
    const data = waybill.waybillRows?.filter((row) => row.slot === slot).reverse();
    const totalWeightTons = getSlotsWeight([slot]).toFixed(2)

    return (
      data && data.length > 0 && (
        <td colSpan={2} style={{textAlign: "left", border: "1px solid darkgray"}}>
          <table>
            <tbody>
              {data.map((row) => {
                const element = elements?.find((e) => e.id === row.elementId);
                return (
                  <tr key={element?.id}>
                    <td>{row.order}.</td>
                    <td style={{ ...elementNamesStyle, whiteSpace: "nowrap" }}>{element?.name}</td>
                    <td>{element?.length}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{element?.weightTons} t</td>
                    <td>{element?.provisionSummary.hasSplit && "H"}{element?.provisionSummary.hasDeepPlug && "T"}</td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={4} style={{ ...elementNamesStyle, whiteSpace: "nowrap", textAlign: "right" }}>{totalWeightTons} t</td>
              </tr>
            </tbody>
          </table>
        </td>
      )
    )
  };

  const slotElementsA = getSlotElements("A");
  const slotElementsB = getSlotElements("B");
  const slotElementsC = getSlotElements("C");
  const slotElementsD = getSlotElements("D");
  const slotElementsE = getSlotElements("E");
  const slotElementsF = getSlotElements("F");
  const slotElementsG = getSlotElements("G");
  const slotElementsH = getSlotElements("H");
  const slotElementsI = getSlotElements("I");
  const slotElementsJ = getSlotElements("J");
  const slotElements1 = getSlotElements("1");
  const slotElements2 = getSlotElements("2");
  const slotElements3 = getSlotElements("3");
  const slotElements4 = getSlotElements("4");
  const slotElements5 = getSlotElements("5");

  const updateTimeHandler = useCallback(
    async (text: string, paramWaybill: Partial<IWaybill>) => {
      const isConfirm = await openConfirmModal(
        "Oletko varma, että haluat päivittää "+text+"?"
      );
      if (waybill.uuid && isConfirm) {
        setLoading(true);
        const updated = await updateTimeWaybill(waybill.uuid, {...waybill, ...paramWaybill} as Partial<IWaybill>);
        queryClient.setQueryData([QueryKey.publicWaybills, waybill.uuid], updated);
        setLoading(false);
      }
    },
    [openConfirmModal, updateTimeWaybill, waybill]
  );

  const openGoogleMapsHandler = useCallback(() => {
    if (!waybill.destination || !waybill.factory) {
      return;
    }
    const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${encodeURIComponent(
      getFactoryAddress(waybill.factory)
    )}&destination=${encodeURIComponent(waybill.destination)}`;
    window.open(url, "_blank");
  }, [waybill.destination, waybill.factory]);

  return (
    <div style={{padding: "1rem"}}>
      <h3 style={{margin:"0", lineHeight: "1.2"}}>Lähete: {waybill.waybillId}</h3>
      <h3 style={{margin:"0", lineHeight: "1.2"}}>Toimitetaan: {formatDate(waybill.deliveryDate)} {waybill.deliveryTime} </h3>
      <div>Työmaa: {waybill.projectNumber} {waybill.projectName}</div>
      <div>{waybill.orderNumber? 'Tilausnumero: ' + waybill.orderNumber : ''}</div>
      <div><b>{waybill.destination}</b> {waybill.destination && waybill.factory && <Button onClick={openGoogleMapsHandler} icon={faRoute} size={EButtonSize.X_SMALL}/>}</div>
      <div>{waybill.kilometers} km </div>
      {/* <br /> */}
      <p>Huomautukset:</p>
      <p style={{paddingLeft: '20px'}}>{waybill.notes}</p>
      <p style={{paddingLeft: '20px'}}>{waybill.additionalNotes}</p>
      <p>Lähettämö: <a href={`tel:${factory?.phoneNumberShipping}`}>{factory?.phoneNumberShipping}</a></p>
      {waybill.contactPerson &&
        <p>Yhteyshenkilö: {waybill.contactPerson}</p>
      }
      {waybill.contactInfo &&
        <p>Yhteystieto: {waybill.contactInfo}</p>
      }
      
      <Accordion text="Lähettäjä ja kuljetusliike"  style={{backgroundColor: "#DDD"}}>
        <p>Pielisen Betoni Oy</p>
        <p>{factory?.streetAddress}</p>
        <p>{factory?.zip} {factory?.city}</p>
        <p>Puh: {factory?.phoneNumber}</p>

        <p>Fax: {factory?.fax}</p>
        <p>Email: <a href={`mailto:${factory?.email}`}>{factory?.email}</a></p>
        <p>Y-tunnus: 2872212-8 ALV rek.</p>

        <p>Kuljetusliike: {waybill.driverOrganization}</p>
        <p>Auto / Kuski: {waybill.car} / {waybill.driver}</p>
      </Accordion>
      <Accordion text={elementText}  style={{backgroundColor: "#fff"}}>
        {(slotElementsA || slotElementsB || slotElementsC || slotElementsD || slotElements1 || slotElements2) && 
        <>
        <div>Vetoauto, {totalWeightCar.toFixed(2)} ton</div>
        <img src={imgTruckCab} style={{ width: "100%" }} alt="truck" />
        <div style={{ overflow: "scroll" }}>
          <table style={{width: "95%", border: "none", margin: "10px"}}>
            <tbody>
            <tr><td colSpan={2}>{slotElementsA && "A"}</td><td colSpan={2}>{slotElementsB && "B"}</td></tr>
            <tr>
              {slotElementsA ? slotElementsA : <td colSpan={2}/>}
              {slotElementsB ? slotElementsB : <td colSpan={2}/>}
            </tr>
            <tr>
              <td></td>
              <td>{slotElements1 && "1."}</td>
            </tr>
            <tr>
              <td style={{width: "30%"}}></td>
              {slotElements1}
              <td style={{width: "30%"}}></td>
            </tr>
            <tr><td colSpan={2}>{slotElementsC && "C"}</td><td colSpan={2}>{slotElementsD && "D"}</td></tr>
            <tr>
              {slotElementsC ? slotElementsC : <td colSpan={2}/>}
              {slotElementsD ? slotElementsD : <td colSpan={2}/>}
            </tr>
            <tr>
              <td></td>
              <td>{slotElements2 && "2."}</td>
            </tr>
            <tr>
              <td style={{width: "30%"}}></td>
              {slotElements2}
              <td style={{width: "30%"}}></td>
            </tr>
            </tbody>
          </table>
        </div>
        <hr></hr>
        </>
        }
        {(slotElementsE || slotElementsF || slotElementsG || slotElementsH || slotElementsI || slotElementsJ || slotElements3 || slotElements4 || slotElements5) && 
        <>
        <div>Perävaunu, {totalWeightTruck.toFixed(2)} ton</div>
        <div style={{ overflow: "scroll" }}>
          <table style={{width: "95%", border: "none", margin: "10px"}}>
            <tbody>
            <tr><td colSpan={2}>{slotElementsE && "E"}</td><td colSpan={2}>{slotElementsF && "F"}</td></tr>
            <tr>
              {slotElementsE ? slotElementsE : <td colSpan={2}/>}
              {slotElementsF ? slotElementsF : <td colSpan={2}/>}
            </tr>
            <tr>
              <td></td>
              <td>{slotElements3 && "3."}</td>
            </tr>
            <tr>
              <td style={{width: "30%"}}></td>
              {slotElements3}
              <td style={{width: "30%"}}></td>
            </tr>
            <tr><td colSpan={2}>{slotElementsG && "G"}</td><td colSpan={2}>{slotElementsH && "H"}</td></tr>
            <tr>
              {slotElementsG ? slotElementsG : <td colSpan={2}/>}
              {slotElementsH ? slotElementsH : <td colSpan={2}/>}
            </tr>
            <tr>
              <td></td>
              <td>{slotElements4 && "4."}</td>
            </tr>
            <tr>
              <td style={{width: "30%"}}></td>
              {slotElements4}
              <td style={{width: "30%"}}></td>
            </tr>
            <tr><td colSpan={2}>{slotElementsI && "I"}</td><td colSpan={2}>{slotElementsJ && "J"}</td></tr>
            <tr>
              {slotElementsI ? slotElementsI : <td colSpan={2}/>}
              {slotElementsJ ? slotElementsJ : <td colSpan={2}/>}
            </tr>
            <tr>
              <td></td>
              <td>{slotElements5 && "5"}</td>
            </tr>
            <tr>
              <td style={{width: "30%"}}></td>
              {slotElements5}
              <td style={{width: "30%"}}></td>
            </tr>
            </tbody>
          </table>
        </div>
        </>
        }
        <span style={{fontSize: "70%"}}>
          * H = halkaistu <br/>
          * T = syvätulppa
        </span>
      </Accordion>
      
      <Accordion text="Elementtilista" style={{backgroundColor: "#DDD"}}>
        <div>Paikka	Tunnus Paino	Pituus*Leveys	Peti</div>
        <ul>
          {waybill?.waybillRows?.map((row) => {
            const element = elements?.find((e) => e.id === row.elementId);
            return (
              <li key={row.elementId}>
                {row.slot} {row.order}.
                <span style={elementNamesStyle}> {element?.name} </span>
                {element?.weightTons} t, {element?.length}*{element?.width} {element?.productionLineNumber}/{element?.position} {element?.provisionSummary.hasSplit && "H"}{element?.provisionSummary.hasDeepPlug && "T"}
              </li>
            )
          })}
        </ul>
      </Accordion>
      <p>VASTAANOTETTU ja Odotusaika hyväksytty:</p>
      <p><i>TODO allekirjoitus? email vahvistus?</i></p>
      <div style={{display: "flex"}}>
        <div style={{minWidth: "150px"}}>
          <label style={{color: loadEndTimeSaved ? "green" : "black"}}>Lastaus päättynyt {loadEndTimeSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.loadEndTime, {style: {marginTop: "5px"}})}
        </div>
        <div>
          <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}} loading={loading}
            onClick={()=>updateTimeHandler("Lastauksen päättymis ajan", {loadEndTime: loadEndTime ? loadEndTime : getCurrentTime()})}
          />
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{minWidth: "150px"}}>
          <label style={{color: arriveTimeSaved ? "green" : "black"}}>Saapunut klo {arriveTimeSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.arriveTime, {style: {marginTop: "5px"}})}
        </div>
        <div>
          <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}} loading={loading}
            onClick={()=>updateTimeHandler("Saapumis ajan", {arriveTime: arriveTime ? arriveTime : getCurrentTime()})}
          />
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{minWidth: "150px"}}>
          <label style={{color: unloadStartTimeSaved ? "green" : "black"}}>Purku alkanut {unloadStartTimeSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.unloadStartTime, {style: {marginTop: "5px"}})}
        </div>
        <div>
          <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}} loading={loading}
            onClick={()=>updateTimeHandler("Purkamisen aloitus ajan", {unloadStartTime: unloadStartTime ? unloadStartTime : getCurrentTime()})}
          />
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{minWidth: "150px"}}>
          <label style={{color: departTimeSaved ? "green" : "black"}}>Lähtenyt klo {departTimeSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.departTime, {style: {marginTop: "5px"}})}
        </div>
        <div>
          <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}} loading={loading}
            onClick={()=>updateTimeHandler("Lähtemis ajan", {departTime: departTime ? departTime : getCurrentTime()})}
          />
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{width: "150px"}}>
          <label style={{color: waitingTimeSaved ? "green" : "black"}}>Odotus aika h {waitingTimeSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.waitingTime)}
        </div>
        <div style={{minWidth: "200px"}}>
          <label style={{color: waitingReasonSaved ? "green" : "black"}}>Odotuksen syy {waitingReasonSaved && <FontAwesomeIcon icon={faCheck}/>}</label>
          {createInput(EInputs.waitingReason)}
        </div>
        <div>
          <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}} loading={loading}
            onClick={()=>updateTimeHandler("Odotus tiedot", {waitingTime: waitingTime, waitingReason: waitingReason})}
          />
        </div>
      </div>
    </div>
  );
}

const useWaybillInputs = (data?: IWaybillDTO) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.arriveTime]: {
      type: EInputType.time,
      value: "",
    },
    [EInputs.departTime]: {
      type: EInputType.time,
      value: "",
    },
    [EInputs.loadEndTime]: {
      type: EInputType.time,
      value: "",
    },
    [EInputs.unloadStartTime]: {
      type: EInputType.time,
      value: "",
    },
    [EInputs.waitingTime]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.waitingReason]: {
      type: EInputType.reactSelect,
      value: "",
      options: WAITING_REASON_OPTIONS
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

export default WaybillQR;