import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { useAuthContext } from "../../context/auth-context";
import { ErrorResponse } from "../../custom-fetch";
import { BASIC_ROLE, Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useUserService } from "../../services/users-service";
import IUser, { USER_ROLE_OPTIONS } from "../../shared/IUser";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";

interface IProps {
  id: string;
  user?: IUser;
  organizationId?: string;
}

enum EInputs {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  phoneNumber = "phoneNumber",
  allowLogin = "allowLogin",
  role = "role",
}

const UserEdit: React.FC<IProps> = ({ id, user, organizationId }) => {
  const isEdit = id !== "add";
  const navigate = useNavigate();
  const { user: currentUser } = useAuthContext();

  const redirectHandler = () => {
    if (organizationId || user?.organizationId) {
      return navigate(-1);
    } else {
      return navigate(Route.users);
    }
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useUserInputs(user);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      if (organizationId) {
        data.organizationId = organizationId;
      }
      saveOrUpdate(data);
    }
  }, [organizationId, saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.firstName)}
      {createInput(EInputs.lastName)}
      {createInput(EInputs.email)}
      {createInput(EInputs.phoneNumber)}
      {!organizationId && BASIC_ROLE.includes(currentUser!.role) && createInput(EInputs.role)}
      {createInput(EInputs.allowLogin)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa käyttäjän?"
      />
    </>
  );
};

const useUserInputs = (data?: IUser) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.firstName]: {
      type: EInputType.text,
      label: "Etunimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.lastName]: {
      type: EInputType.text,
      label: "Sukunimi",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.email]: {
      type: EInputType.email,
      label: "Sähköposti",
      value: "",
      disabled: !!data?.id,
      validation: {
        required: true,
      },
    },
    [EInputs.phoneNumber]: {
      type: EInputType.tel,
      label: "Puhelinnumero",
      value: "",
    },
    [EInputs.allowLogin]: {
      type: EInputType.checkbox,
      label: "Salli kirjautuminen",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.role]: {
      type: EInputType.reactSelect,
      options: USER_ROLE_OPTIONS,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Rooli",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateUser, saveUser } = useUserService();

  const mutationFn = (data: IUser) => {
    return isEdit ? updateUser(id, data) : saveUser(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IUser, ErrorResponse, IUser>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.users, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.users] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteUser } = useUserService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.users],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default UserEdit;
