import { useMemo } from "react";
import IElement from "../../shared/IElement";
import { EFactory } from "../../shared/IOfferRow";
import IProductType from "../../shared/IProductType";
import Table from "../ui/Table/Table";
import PageHeading from "../ui/PageHeading/PageHeading";

interface IProps {
  elements: IElement[];
}

interface IWireTypeItem {
  productTypeId?: string;
  productTypeName?: string;
  tendonCountThick?: string;
  tendonCountThin?: string;
  totalLength?: string;
  factory?: EFactory;
}

const ElementWireTypesTable: React.FC<IProps> = ({ elements }) => {
  const elementsByWireType: IWireTypeItem[] = useMemo(() => {
    const groupedElements = elements
      .filter((element) => !!element.productType)
      .reduce((result: Record<string, IElement[]>, currentValue: IElement) => {
        (result[currentValue.typeName!] =
          result[currentValue.typeName!] || []).push(currentValue);
        return result;
      }, {});

    return Object.entries(groupedElements).map(
      ([productTypeId, wireTypeElements]): IWireTypeItem => {
        const totalLength = wireTypeElements
          .map((element) => element.lengthInMeters ?? 0)
          .reduce((prev, cur) => (cur += prev))
          .toFixed(2);

        const exampleElement = wireTypeElements[0];
        return {
          productTypeId,
          productTypeName: (exampleElement.productType as IProductType)?.name,
          tendonCountThick: exampleElement.tendonCountThick,
          tendonCountThin: exampleElement.tendonCountThin,
          totalLength,
          factory: exampleElement.factory,
        };
      }
    );
  }, [elements]);

  return (
    <div>
      <PageHeading variant="h3" noHorizontalMargin>
        Vaijerityypeittäin
      </PageHeading>
      {elementsByWireType.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Tyyppi</th>
              <th>P</th>
              <th>O</th>
              <th>Metrit</th>
              <th>Tehdas</th>
            </tr>
          </thead>
          <tbody>
            {elementsByWireType.map((element) => (
              <tr key={element.productTypeId}>
                <td>{element.productTypeName}</td>
                <td>{element.tendonCountThick}</td>
                <td>{element.tendonCountThin}</td>
                <td>{element.totalLength}</td>
                <td>{element.factory}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Ei elementtejä.</p>
      )}
    </div>
  );
};

export default ElementWireTypesTable;
