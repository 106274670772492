import React from "react";
import Table from "../../ui/Table/Table";
import { useOfferRowCalculationContext } from "./offer-row-calculation-context";

interface IProps {
  showPrices: boolean;
}

const OfferRowCalculationTotalTable: React.FC<IProps> = ({ showPrices }) => {
  const { isHollowcore } = useOfferRowCalculationContext();
  return isHollowcore ? <HollowcoreTable showPrices={showPrices} /> : <DefaultTable showPrices={showPrices} />;
};

const HollowcoreTable: React.FC<IProps> = ({ showPrices }) => {
  const { offerRow } = useOfferRowCalculationContext();
  return (
    <div>
      <Table style={{ paddingLeft: "12rem", maxWidth: "1300px" }}>
        <tbody>
        <tr>
          <td>paino yhteensä ({offerRow.count} kpl, {offerRow.netArea} nettom²)</td>
          <td>{offerRow.rowWeight} ton</td>

          <td>teräsosat kpl / {offerRow.count} kpl</td>
          {showPrices && <td>{offerRow.steelpartsByElement} € / {offerRow.steelpartsTotal} €</td>}
          {showPrices && <td>hinta (ml. teräsosat)</td>}
          {showPrices && <td>{offerRow.hollowcoreSquareMeterPrice} € /m²</td>}
        </tr>
        {showPrices && (
          <>
            <tr>
              <td>kuljetus yhteensä</td>
              <td>{offerRow.rowDeliveryTotal} €</td>
              <td>teräsosat /m² </td>
              <td>{offerRow.steelpartsBySquareMeter} € /m²</td>
              <td></td>
              <td></td>
            </tr>
            <tr><td colSpan={6}></td></tr>
          </>
        )}
        </tbody>
      </Table>
      <hr />
    </div>
  );
};

const DefaultTable: React.FC<IProps> = ({ showPrices }) => {
  const { offerRow } = useOfferRowCalculationContext();
  return (
    <div>
      <Table style={{ paddingLeft: "12rem" }}>
        <thead>
          <tr>
            <th colSpan={2}>Keskimääräinen elementti</th>
            {showPrices && <th colSpan={2}>Hinnat</th>}
            <th colSpan={2}>Yhteensä</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "130px" }}>pituus</td>
            <td>{offerRow.offerRowCalculationConcrete.maxLengthInMeters} m</td>
            {showPrices && (
              <>
                <td style={{ width: "290px" }}>laskettu myyntihinta (sis. kate)</td>
                <td>
                  {offerRow.offerRowCalculationTotal.linealMetersPriceWithGrossMargin} € /jm 
                </td>
              </>
            )}
            <td style={{ width: "350px" }}></td>
            <td></td>
          </tr>
          <tr>
            <td>ala</td>
            <td>{offerRow.offerRowCalculationConcrete.maxArea} m²</td>
            {showPrices && (
              <>
                <td>laskettu myyntihinta (sis. kate)</td>
                <td>
                  {offerRow.offerRowCalculationTotal.squareMeterPriceWithGrossMargin} € /m² | {offerRow.offerRowCalculationTotal.cubicMeterPriceWithGrossMargin} € /m³
                </td>
              </>
            )}
            <td>tilavuus yhteensä ({offerRow.count} kpl)</td>
            <td>{offerRow.cubicMeters} m³</td>
          </tr>
          <tr>
            <td>tilavuus</td>
            <td>{offerRow.offerRowCalculationConcrete.totalVolume} m³</td>
            {showPrices && (
              <>
                <td>myyntihinnasta ({offerRow.unitPrice} € /jm) laskettu</td>
                <td>{offerRow.cubicMeterPrice} € /m³</td>
              </>
            )}
            <td>paino yhteensä ({offerRow.netArea} nettom²)</td>
          <td>{offerRow.rowWeight} ton</td>
            {/* <td>{offerRow.rowWeight} ton</td> */}
          </tr>
          <tr>
            <td>poikkipinta-ala</td>
            <td>{offerRow.offerRowCalculationConcrete.crossSectionArea} m²</td>
            {showPrices ? (
              <>
                <td>kuljetus m²</td>
                <td>
                  {offerRow.offerRowCalculationTotal.deliverySquareMeterPrice} € /m²
                </td>
                <td>kuljetus yhteensä</td>
                <td>{offerRow.rowDeliveryTotal} €</td>
              </>
            ) : <td colSpan={2}></td>}
          </tr>
          <tr>
            <td>kehämitta</td>
            <td >
              {offerRow.offerRowCalculationConcrete.circumference} m
            </td>
            {showPrices ? (
              <>
                <td>kuljetus m³</td>
                <td colSpan={3}>
                  {offerRow.offerRowCalculationTotal.deliveryCubicMeterPrice} € /m³
                </td>
              </>
            ) : <td colSpan={2}></td>}
          </tr>
        </tbody>
      </Table>
      <hr />

      <Table style={{ paddingLeft: "12rem" }}>
        <thead>
          <tr></tr>
        </thead>
        <tbody>
          <tr></tr>
          <tr></tr>
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
};

export default OfferRowCalculationTotalTable;
