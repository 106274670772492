import { format } from "date-fns";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useUserOptions } from "../../../hooks/useUserOptions";
import IOffer, { STATUS_OPTIONS } from "../../../shared/IOffer";
import IProject from "../../../shared/IProject";
import { RefHandle } from "../../../shared/RefHandle";
import SummaryTable from "../../Offers/OfferCalculationTabs/OfferCalculationSummary/SummaryTable";
import OrganizationUserSelect from "../../OrganizationUserSelect/OrganizationUserSelect";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ProjectPhases from "../ProjectPhases/ProjectPhases";
import { useProjectEditContext } from "./project-edit-context";
import classes from "./ProjectEdit.module.scss";

enum EInputs {
  offerNumber = "offerNumber",
  seller = "seller",
  offerDate = "offerDate",
  expireInDays = "expireInDays",
  status = "status",
  targetName = "targetName",
  deliveryStreetAddress = "deliveryStreetAddress",
  deliveryZip = "deliveryZip",
  deliveryCity = "deliveryCity",
  customerReferenceNumber = "customerReferenceNumber",
  estimatedDeliveryWeeks = "estimatedDeliveryWeeks",
  productionNotes = "productionNotes",
  numberOfFloors = "numberOfFloors",
  contactPerson = "contactPerson",
  contactInfo = "contactInfo",
}

const containerStyles: React.CSSProperties = { width: "200px" };
const containerStylesGroup: React.CSSProperties = { width: "100px" };

const DEFAULT_OFFER_DATE = format(new Date(), "yyyy-MM-dd");

const ProjectEdit: React.ForwardRefRenderFunction<RefHandle<IProject>> = (_, ref) => {
  const { id, project, customerRef, engineerRef } = useProjectEditContext();
  const { offer } = project;
  const { createInput, submit } = useOfferInputs({...offer, contactPerson: project.contactPerson, contactInfo: project.contactInfo});

  const { options: sellerUserOptions, loading: sellerUserOptionsLoading, users: sellers } = useUserOptions({ role: "SELLER" });

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      
      const customerData = await customerRef.current?.getData();
      if (customerData) {
        data.customer = customerData.organization;
        data.customerContactPerson = customerData.user;
      }

      const engineerData = await engineerRef.current?.getData();
      if (engineerData) {
        data.engineer = engineerData.organization;
        data.engineerContactPerson = engineerData.user;
      }

      const newProject: Partial<IProject> = {};
      newProject.contactPerson = data.contactPerson;
      newProject.contactInfo = data.contactInfo;

      if (data.seller) {
        data.seller = (sellers?.find((s) => s.id === (data.seller.id ?? data.seller)) ?? data.seller) || undefined;
      }

      // console.log("getData", selectData, userData, selectedOrganization);
      return {
        ...project,
        offer: {
          ...project.offer,
          ...data,
        },
        ...newProject,
      };
    },
  }), [submit, project, customerRef, engineerRef, sellers]);

  return (
    <div className={classes.Offer}>
      <div style={{ width: "100%" }}>
        <div className={classes.Inputs}>
          <div className={classes.OfferInputs}>
            {/* vasen */}
            <div>
              {createInput(EInputs.offerNumber, {
                disabled: true,
                containerStyles,
              })}
              {createInput(EInputs.seller, {
                disabled: true,
                options: sellerUserOptions,
                loading: sellerUserOptionsLoading,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createInput(EInputs.offerDate, {
                  disabled: true,
                  containerStyles: { width: "140px" },
                })}
                {createInput(EInputs.expireInDays, {
                  disabled: true,
                  containerStyles: { width: "60px" },
                })}
              </div>
              {createInput(EInputs.status, { disabled: true, containerStyles })}
            </div>
            {/* oikea */}
            <div>
              {createInput(EInputs.targetName, { disabled: true, containerStyles })}
              {createInput(EInputs.deliveryStreetAddress, {
                disabled: true,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createInput(EInputs.deliveryZip, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
                {createInput(EInputs.deliveryCity, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
              </div>

              {createInput(EInputs.customerReferenceNumber, {
                disabled: true,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createInput(EInputs.estimatedDeliveryWeeks, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
                {createInput(EInputs.numberOfFloors, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
              </div>
            </div>
          </div>

          <div>
            <div className={classes.Selects}>
              <OrganizationUserSelect
                type="CUSTOMER"
                label="Asiakas"
                ref={customerRef}
                initialData={{
                  organization: offer?.customer,
                  organizationId: offer?.customer?.id || "",
                  userId: offer?.customerContactPerson?.id || "",
                }}
                disabled={true}
              />

              <OrganizationUserSelect
                type="ENGINEER"
                label="Suunnittelutoimisto"
                ref={engineerRef}
                initialData={{
                  organization: offer?.engineer,
                  organizationId: offer?.engineer?.id ?? "",
                  userId: offer?.engineerContactPerson?.id ?? "",
                }}
              />
            </div>
            <div className={classes.Selects}>
              {createInput(EInputs.contactPerson, {
                disabled: false,
              })}
              {createInput(EInputs.contactInfo, {
                disabled: false,
              })}
            </div>
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            {createInput(EInputs.productionNotes, { disabled: true })}
            <SummaryTable summary={offer?.summary} />
          </div>
        </div>
        
        <ProjectPhases
          id={id}
          initialPhases={project.phases}
        />

      </div>
    </div>
  );
};

const useOfferInputs = (data?: IOffer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.offerNumber]: {
      type: EInputType.text,
      placeholder: "Tarjouksen numero",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.seller]: {
      type: EInputType.reactSelect,
      placeholder: "Myyjä",
      options: [],
      value: "",
    },
    [EInputs.offerDate]: {
      type: EInputType.date,
      placeholder: "Pvm",
      value: DEFAULT_OFFER_DATE,
    },
    [EInputs.expireInDays]: {
      type: EInputType.number,
      placeholder: "Voimassa",
      value: "14",
    },
    [EInputs.status]: {
      type: EInputType.reactSelect,
      placeholder: "Tila",
      options: STATUS_OPTIONS,
      value: "",
    },
    [EInputs.targetName]: {
      type: EInputType.textarea,
      placeholder: "Kohteen nimi",
      value: "",
      rows: 2,
    },
    [EInputs.deliveryStreetAddress]: {
      type: EInputType.text,
      placeholder: "Katuosoite",
      value: "",
    },
    [EInputs.deliveryZip]: {
      type: EInputType.text,
      placeholder: "Postinumero",
      value: "",
    },
    [EInputs.deliveryCity]: {
      type: EInputType.text,
      placeholder: "Kaupunki",
      value: "",
    },
    [EInputs.customerReferenceNumber]: {
      type: EInputType.text,
      placeholder: "Asiakkaan viite",
      value: "",
    },
    [EInputs.estimatedDeliveryWeeks]: {
      type: EInputType.number,
      placeholder: "ArvTVko",
      value: "",
    },
    [EInputs.productionNotes]: {
      type: EInputType.textarea,
      label: "Huom. tuotantoon",
      rows: 4,
      value: "",
    },
    [EInputs.numberOfFloors]: {
      type: EInputType.number,
      placeholder: "Kerroksia",
      value: "",
    },
    [EInputs.contactPerson]: {
      type: EInputType.text,
      placeholder: "Yhteyshenkilö",
      value: "",
    },
    [EInputs.contactInfo]: {
      type: EInputType.text,
      placeholder: "Yhteystieto",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
  });

  return { createInput, submit, inputs };
};

export default forwardRef(ProjectEdit);
