import QRCode from 'react-qr-code';
import imgCeLogo from '../../assets/ce-logo.svg';
import imgLogo from '../../assets/logo.png';
import { adminUiUrl } from '../../config';
import SquareFI from '../SquareLogos/SquareFI';
import classes from './ElementPrint.module.scss';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import IElementDTO from '../../shared/IElementDTO';
import { HOLLOWCORE_PRODUCT_GROUP_NAME, SHELL_PLATE_PRODUCT_GROUP_NAME } from '../../shared/IProductGroup';
import IProductType from '../../shared/IProductType';
import { formatDate } from '../../utils/date-utils';
import PrintCheckbox from '../PrintCheckbox/PrintCheckbox';

interface IProps {
  element: IElementDTO;
}
const ElementPrint: React.FC<IProps> = ({ element }) => {
  const { factoryDto: factory } = element;
  const productType = (element.productType as IProductType).productGroup.name;
  const isHollow = productType === HOLLOWCORE_PRODUCT_GROUP_NAME;
  const isShellPlate = productType === SHELL_PLATE_PRODUCT_GROUP_NAME;
  const provisionSummary = element.provisionSummary;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.row}>
          <div style={{width: "25%"}}>
            <p>Pituus:</p>
            <p>Tunnus:</p>
          </div>
          <div style={{width: "75%"}}>
            <p>{element.length}</p>
            <p>{element.name}</p>
          </div>
        </div>
        {(isHollow || isShellPlate) &&
        <div style={{borderStyle: "solid", borderWidth: "1px", marginBottom: "0.7rem"}}>
          <div className={classes.smallFont} style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: "0.5rem"}}>
            <div><img src={imgCeLogo} alt="CE" style={{ maxHeight: "3rem" }} /></div>
            <p>{isHollow ? factory?.dopYearHollow?.slice(-2) : factory?.dopYearShellPlate?.slice(-2)}</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>Pielisen Betoni Oy</p>
            <p>{factory?.streetAddress} {factory?.zip} {factory?.name}</p>
            <p>www.pielisenbetoni.fi</p>
            <p>
              <span>{element?.projectNumber}{element?.phaseName ? '/' +element?.phaseName : "" }:{element.name}</span>
              <span style={{marginLeft: "2rem"}}>Dop {isHollow ? factory?.dopNumHollow : factory?.dopNumShellPlate}</span>
            </p>
            <p style={{marginTop: "1rem"}}>{factory?.ceId}-{factory?.cett2}</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>{isHollow ? factory?.hollowStandardCett1 : factory?.shellPlateStandard}</p>
          </div>
        </div>
        }
        <div className={classes.row} style={{backgroundColor: "white"}}>
          <div style={{width: "50%"}}>
            <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "3rem" }} />
          </div>
          <div style={{width: "20%"}}>
            <div style={{marginLeft: "1rem", marginTop: "0.3rem"}}><SquareFI/></div>
          </div>
          <div style={{width: "30%"}}>
            <p className={classes.smallFont}>{element.productionLineNumber}/{element.position}</p>
          </div>
        </div>
        <p className={classes.smallFont} style={{marginTop: "0.5rem"}}>{factory?.zip} {factory?.name} puh {factory?.phoneNumber}</p>
        <div className={classes.row}>
          <div style={{width: "40%"}}>
            <p>Paino:</p>
            <p>Pituus:</p>
            <p>Halkaisu:</p>
            <p>Varauksia:</p>
            <p>Syvenn.:</p>
            <p>Vino V/O:</p>
          </div>
          <div style={{width: "30%", textAlign: "right"}}>
            <p>{element.weightTons}</p>
            <p>{element.length}</p>
            <p>{element.width}</p>
            <p>{(provisionSummary.countHole + provisionSummary.countCircle + provisionSummary.countRectangle + provisionSummary.countOther +
              provisionSummary.countFill + provisionSummary.countSlot + provisionSummary.countLiftingLoop + provisionSummary.countWireLoop) || ""}
            </p>
            <p>{provisionSummary.countHollow || ""}</p>
            <div><PrintCheckbox checked={provisionSummary.hasSlantedLeft}/>{" "}
              <PrintCheckbox checked={provisionSummary.hasSlantedRight}/></div>
          </div>
          <div style={{width: "30%", paddingLeft: "8px"}}>
            <p>t</p>
            <p>mm</p>
            <p>mm</p>
            <p>kpl</p>
            <p>kpl</p>
            <p></p>
          </div>
        </div>

        <div className={classes.row} style={{fontSize: "1.7rem", background: "darkgray", padding: "8px"}}>
          <p>{element.name}</p>
        </div>

        <div className={classes.row}>
          <div style={{width: "70%"}}>
            <div className={classes.row}>
              {/* <div style={{width: "55%"}}>
                <p>Työmaa:</p>
              </div> 
              <div style={{width: "45%"}}>
                <p>{element?.projectNumber}{element?.phaseName ? '/' +element?.phaseName : "" }</p>
              </div>*/}
            </div>
            <p className={classes.cutLongText}>{element.projectName}</p>
            <div className={classes.row}>
              <div style={{width: "55%"}}>
                <p>Valu pvm:</p>
                <p>Tehdas:</p>
              </div>
              <div style={{width: "45%"}}>
                <p>{formatDate(element.productionLineStartDate)}</p>
                <p>{factory?.factoryId}</p>
              </div>
            </div>
            <p>P {element.length} / {element.weightTons} t</p>
          </div>
          <div style={{width: "30%"}}>
            <QRCode style={{height: "96px", width: "96px", marginTop: "1rem"}} value={`${adminUiUrl}/qr/element/${element.elementId}`}></QRCode>
          </div>
        </div>
      </div>
    </>
  )
}

export default ElementPrint;