import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import IProvision, { DIRECTION_OPTIONS, EProvisionType, provisionOptions } from "../../shared/IProvision";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RefHandle } from "../../shared/RefHandle";
import { updateInputHandler } from "../ui/Input/input-utils";

enum EInputs {
  type = "type",
  edge1 = "edge1",
  edge2 = "edge2",
  count = "count",
  grossAreaPerCount = "grossAreaPerCount",
  provisionNumber = "provisionNumber",
  distance = "distance",
  direction = "direction",
}

interface IProps {
  provision?: IProvision;
  onDelete: () => void;
  onCopy: () => void;
}

const ProvisionEdit: React.ForwardRefRenderFunction<RefHandle<IProvision>, IProps> = ({ provision, onDelete, onCopy }, ref) => {
  const { createInput, submit, inputs } = useProvisionInputs(provision);

  const type = (inputs[EInputs.type].value as string) as EProvisionType

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      return { ...provision, ...data };
    },
  }));

  return (
    <tr>
      <td style={{ width: "80px", minWidth: "80px" }}>{createInput(EInputs.provisionNumber)}</td>
      <td style={{ width: "150px", minWidth: "150px" }}>{createInput(EInputs.type)}</td>
      {type && type !== EProvisionType.split && type !== EProvisionType.slanted && type !== EProvisionType.deepPlug && (
        <td style={{ width: "100px", minWidth: "100px" }}>{createInput(EInputs.count)}</td>
      )}
      {type && type === EProvisionType.circle &&
        <>
          <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.edge1, {label: "Säde"})}</td>
          <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.grossAreaPerCount)}</td>
        </>
      }
      {type && (type === EProvisionType.rectangle || type === EProvisionType.other || type === EProvisionType.fill || type === EProvisionType.hollow || type === EProvisionType.hole) && (
        <>
          <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.edge1)}</td>
          <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.edge2)}</td>
          <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.grossAreaPerCount)}</td>
        </>
      )}
      {type && (type === EProvisionType.slanted || type === EProvisionType.hollow) &&
        <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.distance)}</td>
      }
      {type && (type === EProvisionType.slanted || type === EProvisionType.fill || type === EProvisionType.slot) &&
        <td style={{ width: "120px", minWidth: "120px" }}>{createInput(EInputs.direction)}</td>
      }
      
      <td style={{ alignContent: "flex-start" }}>
        <div style={{maxWidth: "50px"}}>
        <Button style={{ marginRight: "0.25rem" }} onClick={(e) => { e.stopPropagation(); onDelete(); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" />
        <Button onClick={(e) => { e.stopPropagation(); onCopy(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" />
        </div>
      </td>
    </tr>
  );
};

const useProvisionInputs = (data?: IProvision) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.type]: {
      type: EInputType.reactSelect,
      options: provisionOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Varaustyyppi"
    },
    [EInputs.edge1]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "Kantti1 (mm)"
    },
    [EInputs.edge2]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "Kantti2 (mm)"
    },
    [EInputs.count]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "Kpl"
    },
    [EInputs.grossAreaPerCount]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "Varauksen A"
    },
    [EInputs.provisionNumber]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "VarNum"
    },
    [EInputs.distance]: {
      type: EInputType.number,
      value: "",
      placeholder: "",
      label: "Etäisyys mm"
    },
    [EInputs.direction]: {
      type: EInputType.radio,
      options: DIRECTION_OPTIONS,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Suunta V/O"
    },
  });

  const edge1 = inputs[EInputs.edge1].value;
  const edge2 = inputs[EInputs.edge2].value;
  const count = inputs[EInputs.count].value;
  const isCircle = EProvisionType.circle === ((inputs[EInputs.type].value as string) as EProvisionType);

  useEffect(() => {
    if ((!edge1 && !edge2) || !count) return;
    const timeoutId = setTimeout(() => {
      const grossAreaPerCount = isCircle
      ? ((Math.PI * Math.pow((+edge1 || 0), 2)) * (+count || 1) / 1000 / 1000).toFixed(2)
      : (((+edge1 || 0) * (+edge2 || 0)) * (+count || 1) / 1000 / 1000).toFixed(2);
      updateInputHandler(EInputs.grossAreaPerCount, grossAreaPerCount, setInputs);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [edge1, edge2, count, isCircle]);

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default forwardRef(ProvisionEdit);
