import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useElementsService } from "../services/elements-service";
import { QueryKey } from "../services/query-keys";
import IElement from "../shared/IElement";
import { EFactory } from "../shared/IOfferRow";

export const useElementOptions = (projectId?: string, factory?: EFactory, filterForWaybill?: boolean, waybillNumber?: string) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending,
    isError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elementOptions, projectId, factory],
    queryFn: ({ signal }) => fetchElements({ signal, projectId, ...(factory && { search: { productionLineFactory: factory } }) }),
    staleTime: 5000,
    enabled: !!projectId
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : elements
            ?.filter((element) => {
              if (filterForWaybill) {
                if (waybillNumber) {
                  return !element.projectWaybill?.waybillId || element.projectWaybill?.waybillId === waybillNumber;
                } else {
                  return !element.projectWaybill?.waybillId;
                }
              } 
              return true;
            })
            .map(
              (element) => ({ value: element.id, label: element.name } as IOption)
            ),
    [elements, filterForWaybill, isError, waybillNumber]
  );

  return { options, loading: isPending, elements };
};
