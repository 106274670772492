import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import IElementsBatchResponseDTO from "../../shared/IElementsBatchResponseDTO";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";

interface IProps {
  onAccept: (data: IElementsBatchResponseDTO) => Promise<IElementsBatchResponseDTO | null>;
  onCancel: () => void;
  onUpload: (files: File[]) => Promise<IElementsBatchResponseDTO | null>;
}

enum EInputs {
  file = "file",
}

type TempFile = {
  id: string;
  file: File;
}

const BatchImportModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  onUpload,
}) => {
  const [loading, setLoading] = useState(false);
  const { createInput } = useEliplanInputs();
  const [files, setFiles] = useState<TempFile[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);
  const [uploadResponse, setUploadResponse] = useState<IElementsBatchResponseDTO | null>(null);
  const [submitResponse, setSubmitResponse] = useState<IElementsBatchResponseDTO | null>(null);

  const allowSubmit = useMemo(() => (uploadResponse?.dtos?.length ?? 0) > 0, [uploadResponse?.dtos]);

  const submitHandler = useCallback(async () => {
    if (!uploadResponse) return;
    setLoading(true);
    const resp = await onAccept(uploadResponse);
    setSubmitResponse(resp);
    setLoading(false);
  }, [uploadResponse, onAccept]);

  const uploadHandler = useCallback(async () => {
    if (!files || !files.length) {
      setFileError("Tiedosto(t) puuttuu!");
      return;
    }
    setUploadResponse(null);
    setLoading(true);
    const resp = await onUpload(files.map(file => file.file));
    setUploadResponse(resp);
    setFileError(null);
    if (resp?.error) {
      setFiles([]);
    }
    setLoading(false);
  }, [files, onUpload]);

  const onDrop = useCallback((inputName: string, acceptedFiles: File[]) => {
    console.log(inputName, acceptedFiles);
    const file = acceptedFiles[0];
    if (file) {
      setFileError(null);
    }
    setFiles((files) => [ ...files, ...acceptedFiles.map((file) => ({ id: uuid(), file })) ]);
  }, []);

  const removeFileHandler = useCallback((id: string) => {
    setFiles(files => files.filter(file => file.id !== id));
  }, []);

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        {createInput(EInputs.file, { onDrop })}
        {fileError && <ErrorsAlert errors={[fileError]} />}
        {files?.length > 0 && (
          <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <span>Valitut tiedosto(t):</span>
            <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
              {files.map(file => (
                <div style={{ display: "flex", gap: "1rem", alignItems: "center" }} key={file.id}>
                  <span>{file?.file?.name}</span>
                  <Button onClick={() => removeFileHandler(file.id)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" />
                </div>
              ))}
            </div>
          </div>
        )}
        {uploadResponse && (
          uploadResponse.error ? (
            <ErrorsAlert errors={uploadResponse.error.messages} />
          ) : uploadResponse.dtos ? (
            <span>Tunnistettuja elementtejä yhteensä: {uploadResponse.dtos.length} kpl</span>
          ) : null
        )}
        {uploadResponse?.notices && uploadResponse.notices.map(notice => <span key={notice}>{notice}</span>)}
        {submitResponse?.error && <ErrorsAlert errors={submitResponse.error.messages} />}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={uploadHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Lue tiedosto
        </Button>
        {allowSubmit && (
          <Button
            onClick={submitHandler}
            style={{ marginRight: "0.5rem" }}
            loading={loading}
          >
            Tallenna
          </Button>
        )}
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useEliplanInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.file]: {
      type: EInputType.dropzone,
      label: "Tiedosto(t)",
      value: "",
      canPaste: true,
      multiple: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default BatchImportModal;
