import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useOfferService } from "../services/offers-service";
import { QueryKey } from "../services/query-keys";
import IOffer from "../shared/IOffer";
import { IOffersSearch } from "../components/Offers/OffersSearch";

export const useOfferOptions = (search?: IOffersSearch) => {
  const { fetchOffers } = useOfferService();

  const {
    data: offers,
    isPending,
    isError,
  } = useQuery<IOffer[], ErrorResponse>({
    queryKey: [QueryKey.offerOptions],
    queryFn: ({ signal }) => fetchOffers({ signal, search: { customerId: "", offerDateStart: "", offerDateEnd: "", status: "", ...search } }),
    staleTime: 5000,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : offers?.map(
            (offer) => ({ value: offer.id, label: offer.name } as IOption)
          ),
    [offers, isError]
  );

  return { options, loading: isPending, offers };
};
