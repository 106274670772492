import React, { useCallback, useEffect, useState } from "react";
import {
  ECommonValue,
  EInputType,
  IInputField,
  IInputFieldItem,
  IOption,
  IValidationResult,
  TInputValue
} from "./Input";
import { useInputContext } from "./input-context";

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

const isOptionArray = (value: TInputValue): value is IOption[] => Array.isArray(value) && value.length > 0 && isOption(value[0]);
const isOption = (value: TInputValue): value is IOption => (value as IOption | null)?.value !== undefined;


const getCheckboxValue = (value: string[]) => {
  return value.includes(ECommonValue.YES);
};


export function formatInputValue(value: TInputValue) {
  if (isOptionArray(value)) {
    value = value.map((item) => item.value);
  } else if (isOption(value)) {
    value = value.value;
  } else if (Array.isArray(value)) {
    value = getCheckboxValue(value as string[]);
  } else if (value === null) {
    value = "";
  }
  
  return value;
}


export function getInputData<T>(inputs: IInputField): T {
  let data: any = {};
  for (let key in inputs) {
    const input = inputs[key];
    let value = formatInputValue(input.value);
    data[key] = value;
  }
  return data;
};

export const initForm = function<T>(
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  data: T,
  disableFields?: boolean
): void {
  setInputs((inputs) => {
    let newInputs = {
      ...inputs
    };
    // Update input valid status when initForm, fixes cases where data doesn't have all the input keys.
    for (let key in inputs) {
      updateInputValid(newInputs, key);
    }
    // Set values from data and validate
    for (let key in data) {
      if (inputs[key]) {
        const input = inputs[key];
        let value: any = data[key];
        if (input.disableInit) {
          continue;
        }
  
        // This is here because coldfusion likes to convert YES -> True and NO -> False 
        // So we have to add empty space and trim here it from inputs.
        if (typeof value === "string") {
          value = value.trim();
        } else if (typeof value === "boolean") {
          if (input.type === EInputType.radio) {
            value = value ? ECommonValue.YES : "";
          } else if (input.type === EInputType.checkbox) {
            value = value ? [ECommonValue.YES] : [];
          }
        }

        const inputType = input.type;
        if ([EInputType.reactAsyncSelect, EInputType.reactSelect].includes(inputType)) {
          if (typeof value === "object") {
            if (Array.isArray(value)) {
              if (value.length > 0 && typeof value[0] === "object") {
                value = value.map((v) => (v.id));
              }
            } else {
              value = value?.id;
            }
          }
        }

        newInputs[key] = {
          ...inputs[key],
          value
        };
        updateInputValid(newInputs, key);
      }
    }
    return newInputs;
  });
};

const updateInputValid = (newState: IInputField, inputName: string): IInputField => {
  newState[inputName].validationResult = validateInput(newState, newState[inputName]);

  const validation = newState[inputName].validation;
  if(validation && validation.dependencies) {
    for (let dependencyName of validation.dependencies) {
      newState[dependencyName].validationResult = validateInput(newState, newState[dependencyName]);
    }
  }
  return newState;
}

export const updateInputHandler = (
  inputName: string,
  value: TInputValue,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>
) => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    newState[inputName].value = value;
    newState = updateInputValid(newState, inputName);
    return newState;
  });
};

export const disableInputs = (
  disabled: boolean,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  exclude?: string[]
): void => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    for (let key in newState) {
      if (exclude && exclude.indexOf(key) !== -1) {
        continue;
      }
      newState[key].disabled = disabled;
    }
    return newState;
  });
};

const getInvalid = (message?: string): IValidationResult => ({
  isValid: false,
  message: message ?? 'Pakollinen tieto'
})


const validateRequiredIf = (value: string, requiredIfValue?: string | string[]) => {
  if(!requiredIfValue) return false;
  if(Array.isArray(requiredIfValue)) {
    return requiredIfValue.indexOf(value) >= 0;
  } else {
    return requiredIfValue !== value;
  }
}

const isValidValue = (
  item: IInputFieldItem,
  requiredIfValue?: string | string[],
  requiredCompareValue?: string
): IValidationResult => {
  const { type, validation = {} } = item;
  let value;
  switch (type) {
    case EInputType.text:
    case EInputType.number:
    case EInputType.date:
    case EInputType.radio:
    case EInputType.tel:
    case EInputType.textarea:
    case EInputType.time:
    case EInputType.select:
    case EInputType.wysiwyg:
    case EInputType.email:
    case EInputType.reactSelect:
      value = item.value as string;

      if (type === EInputType.wysiwyg && value === "<p><br></p>") {
        value = '';
      }

      if (requiredCompareValue) {
        if (requiredCompareValue === value) return getInvalid(item.validation?.requiredMessage);
      } else if (value === "" && !validation.allowEmpty) {
        return getInvalid(item.validation?.requiredMessage);
      }
      // if (requireIfValue && requireIfValue !== value) return INVALID_DEFAULT;
      if (validateRequiredIf(value, requiredIfValue)) return getInvalid(item.validation?.requiredMessage);
      if(type === EInputType.email && !isValidEmail(value as string)) return {
        isValid: false,
        message: "Tarkista sähköposti"
      };
      if(validation.minLength && value.length < validation.minLength) {
        return {
          isValid: false,
          message: validation.minLengthMessage || `Anna vähintään ${validation.minLength} merkkiä.`
        }
      }
      if(validation.maxLength && value.length > validation.maxLength) {
        return {
          isValid: false,
          message: validation.maxLengthMessage || `Anna enintään ${validation.maxAmount} merkkiä.`,
        }
      }
      if(typeof validation.minAmount !== "undefined" && parseInt(value) < validation.minAmount) {
        return {
          isValid: false,
          message: validation.minAmountMessage || `Minimi on ${validation.minAmount}`,
        }
      }
      if(typeof validation.maxAmount !== "undefined" && parseInt(value) > validation.maxAmount) {
        return {
          isValid: false,
          message: validation.maxAmountMessage || `Maximi on ${validation.maxAmount}`,
        }
      }
      break;
    case EInputType.checkbox:
      value = item.value as string[];
      if (value.length === 0) return getInvalid(item.validation?.requiredMessage);
      break;
    default:
      return getInvalid(item.validation?.requiredMessage);
  }
  return {
    isValid: true,
    message: null
  };
};

export const validateInput = (inputs: IInputField, item: IInputFieldItem): IValidationResult => {
  const { validation } = item;
  if (validation) {
    if (validation.required) {
      return isValidValue(item, undefined, validation.requiredCompareValue);
    } else if (validation.requiredIf) {
      if (isValidValue(inputs[validation.requiredIf], validation.requiredIfValue).isValid) {
        return isValidValue(item);
      }
    } else if (validation.requiredIfNot) {
      if (!isValidValue(inputs[validation.requiredIfNot], validation.requiredIfValue).isValid) {
        return isValidValue(item);
      }
    }
  }
  return {
    isValid: true,
    message: null
  };
};

export const isInputsValid = (inputs: IInputField) => {
  const keys = Object.keys(inputs);
  for (let key of keys) {
    const item = inputs[key];
    const validationResult = validateInput(inputs, item);
    if(!validationResult.isValid) {
      return false;
    }

  }
  return true;
};

export const validateInputs = async (setInputs: React.Dispatch<React.SetStateAction<IInputField>>) => {
  const isValid = new Promise<boolean>((resolve) => {
    setInputs(inputs => {
      let isValid = true;
      const newInputs = {...inputs};
      const keys = Object.keys(newInputs);
      for (let key of keys) {
        const item = newInputs[key];
        item.validationResult = validateInput(inputs, item);
        isValid = isValid && item.validationResult.isValid;
      }
      resolve(isValid);
      return newInputs;
    });
  });
  return isValid;
};

export const useHasInvalidInputs = (inputs: IInputField) => {
  const [value, setValue] = useState(false);
  const { showValidation } = useInputContext();


  const isValid = useCallback((inputs: IInputField) => {
    const keys = Object.keys(inputs);
    for (let key of keys) {
      const input = inputs[key];
      if (input && !input.validationResult?.isValid) {
        return false;
      }
    }
    return true;
  }, []);

  useEffect(() => {
    if (showValidation) {
      setValue(!isValid(inputs));
    }
  }, [showValidation, inputs, isValid]);

  return value;
};
