import { IOfferCalculationSummary } from "../components/Offers/OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary";
import { IInstallations } from "../components/Offers/OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations";
import { IOfferCalculationKilometer } from "../components/Offers/OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer";
import { IOption } from "../components/ui/Input/Input";
import IOfferRow from "./IOfferRow";
import IOfferTerm from "./IOfferTerm";
import IOrganization from "./IOrganization";
import IUser from "./IUser";

export enum EOfferStatus {
  OFFER = "OFFER",
  WON = "WON",
  LOST = "LOST",
}

export const STATUS_OPTIONS: IOption[] = [
  { value: EOfferStatus.OFFER, label: "Tarjous" },
  { value: EOfferStatus.WON, label: "Voitettu" },
  { value: EOfferStatus.LOST, label: "Hävitty" },
];

export type OfferStatus = "OFFER" | "WON" | "LOST";

interface IOffer {
  id: string;
  offerNumber: string;
  seller: IUser;
  offerDate: string;
  expireInDays: string;
  status: OfferStatus;
  sellerNotes: string;
  targetName: string;
  // targetName2: string;
  deliveryStreetAddress: string;
  deliveryZip: string;
  deliveryCity: string;
  customerReferenceNumber: string;
  numberOfFloors: string;
  estimatedDeliveryWeeks: string;
  customer?: IOrganization;
  customerContactPerson?: IUser;
  engineer?: IOrganization;
  engineerContactPerson?: IUser;
  deliveryNotes: string;
  deliveryLocation: string;
  terms: IOfferTerm[];
  planningNotes: string;
  declineNotes: string;
  productionNotes: string;
  freightNotes: string;
  planningDeliveryNotes: string;
  archived: boolean;
  rows: IOfferRow[];
  projectNumber: string;
  kilometerCalculations: IOfferCalculationKilometer[];
  installations: IInstallations;
  summary: IOfferCalculationSummary;
  offerDateString?: string;
  name?: string;
  projectId?: string;

  contactPerson?: string;
  contactInfo?: string;
}

export default IOffer;
