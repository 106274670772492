import { IOffersSearch } from "../components/Offers/OffersSearch";
import { IOrganizationsSearch } from "../components/Organizations/OrganizationsSearch";
import { IProjectsSearch } from "../components/Projects/ProjectsSearch";
import { IWaybillsSearch } from "../components/Waybills/WaybillsSearch";
import { EFactory } from "../shared/IOfferRow";
import { OrganizationType } from "../shared/IOrganization";

enum LocalStorageKey {
  token = "token",
  expiration = "expiration",
  offersSearch = "offersSearch",
  waybillsSearch = "waybillsSearch",
  customersSearch = "customersSearch",
  engineersSearch = "engineersSearch",
  projectsSearch = "projectsSearch",
  searchFactory = "searchFactory",
}

export const storage = {
  saveAuthentication: (token: string, expiration: string) => {
    localStorage.setItem(LocalStorageKey.token, token);
    localStorage.setItem(LocalStorageKey.expiration, expiration);
  },
  deleteAuthentication: () => {
    localStorage.removeItem(LocalStorageKey.token);
    localStorage.removeItem(LocalStorageKey.expiration);
  },
  getToken: () => {
    return localStorage.getItem(LocalStorageKey.token);
  },
  getExpiration: () => {
    return localStorage.getItem(LocalStorageKey.expiration);
  },
  saveOffersSearch: (search: IOffersSearch) => {
    return localStorage.setItem(LocalStorageKey.offersSearch, JSON.stringify(search));
  },
  getOffersSearch: () => {
    return localStorage.getItem(LocalStorageKey.offersSearch);
  },
  saveWaybillsSearch: (search: IWaybillsSearch) => {
    return localStorage.setItem(LocalStorageKey.waybillsSearch, JSON.stringify(search));
  },
  getWaybillsSearch: () => {
    return localStorage.getItem(LocalStorageKey.waybillsSearch);
  },
  saveOrganizationsSearch: (search: IOrganizationsSearch) => {
    if (search.type === "CUSTOMER") {
      return localStorage.setItem(LocalStorageKey.customersSearch, JSON.stringify(search));
    } else if (search.type === "ENGINEER") {
      return localStorage.setItem(LocalStorageKey.engineersSearch, JSON.stringify(search));
    }
  },
  getOrganizationsSearch: (type: OrganizationType) => {
    if (type === "CUSTOMER") {
      return localStorage.getItem(LocalStorageKey.customersSearch);
    } else if (type === "ENGINEER") {
      return localStorage.getItem(LocalStorageKey.engineersSearch);
    }
  },
  saveProjectsSearch: (search: IProjectsSearch) => {
    return localStorage.setItem(LocalStorageKey.projectsSearch, JSON.stringify(search));
  },
  getProjectsSearch: () => {
    return localStorage.getItem(LocalStorageKey.projectsSearch);
  },
  saveSearchFactory: (factory: EFactory) => {
    return localStorage.setItem(LocalStorageKey.searchFactory, factory);
  },
  getSearchFactory: () => {
    return localStorage.getItem(LocalStorageKey.searchFactory);
  },
};
