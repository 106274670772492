import React, { useState } from 'react';
import ISteelParts from '../../shared/ISteelPart';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../routes';
import Table from '../ui/Table/Table';
import Button, { EButtonSize } from '../ui/Button/Button';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useSteelPartsService } from '../../services/steelParts-service';
import { queryClient } from '../../App';
import { QueryKey } from '../../services/query-keys';
import ISteelPart from '../../shared/ISteelPart';

interface IProps {
  steelParts: ISteelParts[];
  showUpdateArchived?: boolean
}

const SteelPartsTable: React.FC<IProps> = ({ steelParts, showUpdateArchived }) => {
  const navigate = useNavigate();
  const { archivedUpdateSteelPart } = useSteelPartsService();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);
  
  const openHandler = (id: string) => {
    navigate(Route.steelPart(id));
  };

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateSteelPart(id);
      queryClient.setQueryData([QueryKey.steelParts], (steelParts: ISteelPart[]) => 
        steelParts.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Nimikenumero</th>
          <th>Nimike</th>
          <th>Nettohinta</th>
          <th>Selite</th>
          <th>Lisätiedot</th>
          <th>Toimittaja</th>
          <th>Ryhmä</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {steelParts.map((steelPart) => (
          <tr key={steelPart.id} onClick={() => openHandler(steelPart.id)} style={{ color: steelPart.archived ? 'gray' : 'inherit', fontStyle: steelPart.archived ? "italic" : 'inherit' }}>
            <td>{steelPart.unitNumber}</td>
            <td>{steelPart.unitName}</td>
            <td>{steelPart.netPrice} €</td>
            <td>{steelPart.description}</td>
            <td>{steelPart.additionalInfo}</td>
            <td>{steelPart.supplierName}</td>
            <td>{steelPart.group}</td>
            <td>{steelPart.updatedString}</td>
            {showUpdateArchived ?
            <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(steelPart.id)} onClick={(event)=>archivedUpdateHandler(steelPart.id, event)} icon={steelPart.archived ? faEyeSlash : faEye} title={steelPart.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            :
            <td>{steelPart.archived && "arkistoitu"}</td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SteelPartsTable;