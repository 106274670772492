import { useCallback } from "react";
import IElement from "../../shared/IElement";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ElementsCopyProvisionsModal from "./ElementsCopyProvisionsModal";

export const useElementsCopyProvisionsModal = () => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.ELEMENTS_COPY_PROVISIONS),
    [closeModal]
  );

  const handler = (elements: IElement[]): Promise<string | null> => {
    let title = "Kopioi varaukset";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.ELEMENTS_COPY_PROVISIONS,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <ElementsCopyProvisionsModal
            onAccept={async (data) => {
              try {
                resolve(data);
                closeModalHandler();
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            elements={elements}
          />
        ),
      });
    });
  };

  return handler;
};
