import IElement from "../../shared/IElement";
import { WIRE_TYPE_THICK_KGM, WIRE_TYPE_THIN_KGM } from "../../shared/IWireType";
import Table from "../ui/Table/Table";

interface IProps {
  elements: IElement[];
  onClick?: (element: IElement) => void;
  selectedElements?: IElement[];
}

const sortElements = (e1: IElement, e2: IElement) => {
  const factoryComparison = (e1.factory ?? "").localeCompare(e2.factory ?? "");
  if (factoryComparison !== 0) return factoryComparison;

  const projectNameComparison = (e1.projectName ?? "").localeCompare(e2.projectName ?? "");
  if (projectNameComparison !== 0) return projectNameComparison;

  const phaseComparison = (e1.phaseName ?? "").localeCompare(e2.phaseName ?? "");
  if (phaseComparison !== 0) return phaseComparison;

  const thicknessDiff = +(e1.slabType?.thickness ?? 0) - +(e2.slabType?.thickness ?? 0);
  if (thicknessDiff !== 0) return thicknessDiff;

  const weight1 = +(e1.tendonCountThick ?? 0) * WIRE_TYPE_THICK_KGM + +(e1.tendonCountThin ?? 0) * WIRE_TYPE_THIN_KGM;
  const weight2 = +(e2.tendonCountThick ?? 0) * WIRE_TYPE_THICK_KGM + +(e2.tendonCountThin ?? 0) * WIRE_TYPE_THIN_KGM;
  const weightDiff = weight1 - weight2;
  if (weightDiff !== 0) return weightDiff;

  const name1 = parseFloat((e1.name?.split("-")[1] ?? "").replaceAll("[^\\d.]", "")) || 0;
  const name2 = parseFloat((e2.name?.split("-")[1] ?? "").replaceAll("[^\\d.]", "")) || 0;
  const nameComparison = name1 - name2;
  if (nameComparison !== 0) return nameComparison;

  return 0;
}

const ElementsTable: React.FC<IProps> = ({
  elements,
  onClick,
  selectedElements,
}) => {
  return (
    <Table hover={!!onClick}>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>Tehdas</th>
          <th style={{ width: "200px" }}>Työmaa</th>
          <th style={{ width: "80px" }}>Kerros / lohko</th>
          <th style={{ width: "125px", minWidth: "125px" }}>Tunnus</th>
          <th style={{ width: "125px" }}>Vaijerityyppi</th>
          <th style={{ width: "125px" }}>Pituus</th>
          <th style={{ width: "125px" }}>Varaukset</th>
          {/*<th style={{ width: "125px" }}>Paino</th>*/}
          <th style={{ width: "125px" }}>Halkaisija</th>
          <th style={{ width: "100px" }}>Vko</th>
          <th style={{ width: "100px" }}>Toim</th>
        </tr>
      </thead>
      <tbody>
        {elements.sort(sortElements).map((element) => (
          <tr
            key={element.id}
            onClick={() => onClick && onClick(element)}
            style={
              selectedElements &&
              selectedElements.findIndex((el) => el.id === element.id) !== -1
                ? { background: "#f5f5f5" }
                : {}
            }
          >
            <td>{element.factory}</td>
            <td>{element.projectNumber}</td>
            <td>{element.phaseName}</td>
            <td>{element.name}</td>
            <td>{element.typeName}</td>
            <td>{element.length}</td>
            <td>{element.provisionAmountShort}</td>
            {/*<td>{element.weightTons}</td>*/}
            <td>{element.width}</td>
            <td>{element.productionWeek}</td>
            <td>{element.deliveryWeek}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ElementsTable;
