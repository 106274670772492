import { useNavigate } from "react-router-dom";

import { Route } from "../../routes";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useOffersSearch } from "./useOffersSearch";
import { formatDate } from "../../utils/date-utils";
import { useOfferService } from "../../services/offers-service";
import { useState } from "react";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";
import IOffer from "../../shared/IOffer";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface IProps { }

const OffersList: React.FC<IProps> = () => {
  const { isError, error, isLoading, offers } = useOffersSearch();
  const { archivedUpdateOffer } = useOfferService();
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateOffer(id);
      queryClient.setQueryData([QueryKey.offers], (offers: IOffer[]) => 
        offers.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!offers || offers.length === 0) {
    return <p>Ei tarjouksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.offer(id));
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Tarjouksen numero</th>
            <th>Pvm</th>
            <th>Kohteen nimi</th>
            <th>Asiakas</th>
            <th>Myyjä</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => (
            <tr key={offer.id} onClick={() => openHandler(offer.id)} 
              style={{
                color: offer.archived ? "gray" : "inherit",
                fontStyle: offer.archived ? "italic" : "inherit",
              }}
            >
              <td>{offer.projectNumber && offer.projectNumber + " / "}{offer.offerNumber}</td>
              <td>{formatDate(offer.offerDate)}</td>
              <td>{offer.targetName}</td>
              <td>{offer.customer?.name}</td>
              <td>{offer.seller?.name}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(offer.id)} onClick={(event)=>archivedUpdateHandler(offer.id, event)} icon={offer.archived ? faEyeSlash : faEye} title={offer.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OffersList;
