import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useAttachmentsService } from "../../services/attachments-service";
import { Kind } from "../../services/kind";
import IProvision from "../../shared/IProvision";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ProvisionsEditModal from "./ProvisionsEditModal";

export const useProvisionsEditModal = (id: string, provisions: IProvision[]) => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.PROVISIONS_EDIT),
    [closeModal]
  );

  const { downloadMutate } = useDownloadLatestAttachments("Element", id);

  const handler = async (): Promise<IProvision[] | null> => {
    let title = "Muokkaa varauksia";
    const attachment = await downloadMutate();
    return new Promise((resolve) => {
      addModal({
        id: EModalId.PROVISIONS_EDIT,
        isOpen: true,
        size: EModalSize.FULL,
        title,
        backdropNotClose: true,
        content: (
          <ProvisionsEditModal
            onAccept={async (data) => {
              try {
                resolve(data);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            initialProvisions={provisions}
            attachment={attachment}
          />
        ),
      });
    });
  };

  return handler;
};

const useDownloadLatestAttachments = (kind: Kind, parentId: string) => {
  const { downloadLatestAttachment } = useAttachmentsService();

  const {
    mutateAsync: downloadMutate,
    isPending: isDownloading,
    isError: isDownloadError,
    error: downloadError,
  } = useMutation<Blob, ErrorResponse>({
    mutationFn: () => downloadLatestAttachment({ kind, parentId }),
  });

  return { downloadMutate, isDownloading, isDownloadError, downloadError };
}
