import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import IOfferRow from "../../../shared/IOfferRow";
import { RefHandle } from "../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { useOfferProductsContext } from "../OfferProducts/offer-products-context";
import { ProductRef } from "../OfferProducts/OfferProduct";
import OfferRowCalculationContextProvider from "./offer-row-calculation-context";
import OfferRowCalculationConcrete, { IOfferRowCalculationConcrete } from "./OfferRowCalculationConcretes/OfferRowCalculationConcrete";
import OfferRowCalculationHollowcore, { IOfferRowCalculationHollowcore } from "./OfferRowCalculationHollowcores/OfferRowCalculationHollowcore";
import OfferRowCalculationInsulations, { IOfferRowCalculationInsulation } from "./OfferRowCalculationInsulations/OfferRowCalculationInsulations";
import OfferRowCalculationMeshes, { IOfferRowCalculationMesh } from "./OfferRowCalculationMeshes/OfferRowCalculationMeshes";
import OfferRowCalculationSteelParts, { IOfferRowCalculationSteelPart } from "./OfferRowCalculationSteelParts/OfferRowCalculationSteelParts";
import OfferRowCalculationSteels, { IOfferRowCalculationSteel } from "./OfferRowCalculationSteels/OfferRowCalculationSteels";
import OfferRowCalculationSteelTendon, { IOfferRowCalculationSteelTendon } from "./OfferRowCalculationSteels/OfferRowCalculationSteelTendon";
import OfferRowCalculationTotal from "./OfferRowCalculationTotal/OfferRowCalculationTotal";
import OfferRowCalculationTotalTable from "./OfferRowCalculationTotalTable";
import OfferRowCalculationWork, { IOfferRowCalculationWork } from "./OfferRowCalculationWork/OfferRowCalculationWork";

enum EOfferRowTab {
  hollowcore,
  concrete,
  steelPart,
  steel,
  steelTendon,
  mesh,
  insulation,
  work,
  total,
}

interface IOfferRowTab {
  id: EOfferRowTab;
  name: string;
}

const offerRowTabsAll: IOfferRowTab[] = [
  // { id: EOfferRowTab.hollowcore, name: "Ontelot" },
  { id: EOfferRowTab.concrete, name: "Betoni" },
  { id: EOfferRowTab.steelPart, name: "Teräsosat" },
  { id: EOfferRowTab.steel, name: "Teräkset" },
  { id: EOfferRowTab.steelTendon, name: "Punokset" },
  { id: EOfferRowTab.mesh, name: "Verkot" },
  { id: EOfferRowTab.insulation, name: "Eristeet" },
  { id: EOfferRowTab.work, name: "Työ" },
  { id: EOfferRowTab.total, name: "Yhteenveto" },
];

const offerRowTabsHollowcore: IOfferRowTab[] = [
  { id: EOfferRowTab.hollowcore, name: "Ontelot" },
  { id: EOfferRowTab.steelPart, name: "Teräsosat" },
  // { id: EOfferRowTab.total, name: "Yhteenveto" },
];

interface IProps {
  offerRow: IOfferRow;
  isHollowcore: boolean;
  isWall: boolean;
  showPrices: boolean;
}

const OfferRowCalculation: React.ForwardRefRenderFunction<ProductRef, IProps> = ({ offerRow, isHollowcore, isWall, showPrices }, ref) => {
  const { updateCurrentRow } = useOfferProductsContext();
  const defaultTab = useMemo(() => isHollowcore ? EOfferRowTab.hollowcore : EOfferRowTab.concrete, [isHollowcore]);
  const offerRowTabs = useMemo(() => isHollowcore ? offerRowTabsHollowcore : offerRowTabsAll, [isHollowcore]);
  const [tab, setTab] = useState<EOfferRowTab>(defaultTab);

  const concreteRef = useRef<RefHandle<IOfferRowCalculationConcrete>>(null);
  const hollowcoreRef = useRef<RefHandle<IOfferRowCalculationHollowcore>>(null);
  const insulationRef = useRef<RefHandle<IOfferRowCalculationInsulation>>(null);
  const meshRef = useRef<RefHandle<IOfferRowCalculationMesh>>(null);
  const steelRef = useRef<RefHandle<IOfferRowCalculationSteel>>(null);
  const steelTendonRef = useRef<RefHandle<IOfferRowCalculationSteelTendon>>(null);
  const steelPartRef = useRef<RefHandle<IOfferRowCalculationSteelPart>>(null);
  const workRef = useRef<RefHandle<IOfferRowCalculationWork>>(null);
  
  const getTab = useCallback((id: EOfferRowTab) => {
    if (tab !== id) setTab(id)
    switch (id) {
      case EOfferRowTab.hollowcore:
        return <OfferRowCalculationHollowcore ref={hollowcoreRef} />;
      case EOfferRowTab.concrete:
        return <OfferRowCalculationConcrete ref={concreteRef} />;
      case EOfferRowTab.steelPart:
        return <OfferRowCalculationSteelParts ref={steelPartRef} />;
      case EOfferRowTab.steel:
        return <OfferRowCalculationSteels ref={steelRef} />;
      case EOfferRowTab.steelTendon:
        return <OfferRowCalculationSteelTendon ref={steelTendonRef} />;
      case EOfferRowTab.mesh:
        return <OfferRowCalculationMeshes ref={meshRef} />;
      case EOfferRowTab.insulation:
        return <OfferRowCalculationInsulations ref={insulationRef} />;
      case EOfferRowTab.work:
        return <OfferRowCalculationWork ref={workRef} />;
      case EOfferRowTab.total:
        return <OfferRowCalculationTotal />;
    }
  }, [tab]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      if (!offerRow) return null;
      const {
        offerRowCalculationConcrete,
        offerRowCalculationHollowcore,
        offerRowCalculationInsulation,
        offerRowCalculationMesh,
        offerRowCalculationSteel,
        offerRowCalculationSteelTendon,
        offerRowCalculationSteelPart,
        offerRowCalculationWork,
      } = offerRow;
      return {
        offerRowCalculationConcrete: { ...offerRowCalculationConcrete, ...await concreteRef.current?.getData() },
        offerRowCalculationHollowcore: { ...offerRowCalculationHollowcore, ...await hollowcoreRef.current?.getData() },
        offerRowCalculationInsulation: { ...offerRowCalculationInsulation, ...await insulationRef.current?.getData() },
        offerRowCalculationMesh: { ...offerRowCalculationMesh, ...await meshRef.current?.getData() },
        offerRowCalculationSteel: { ...offerRowCalculationSteel, ...await steelRef.current?.getData() },
        offerRowCalculationSteelTendon: { ...offerRowCalculationSteelTendon, ...await steelTendonRef.current?.getData() },
        offerRowCalculationSteelPart: { ...offerRowCalculationSteelPart, ...await steelPartRef.current?.getData() },
        offerRowCalculationWork: { ...offerRowCalculationWork, ...await workRef.current?.getData() },
      };
    },
  }), [offerRow]);
  
  return (
    <div style={{ display: "initial" }}>
      <OfferRowCalculationContextProvider
        value={{
          offerRow,
          updateOfferRow: updateCurrentRow,
          isHollowcore,
          isWall,
        }}
      >
      <OfferRowCalculationTotalTable showPrices={showPrices} />
      <div style={{ display: "flex", paddingBottom: "1rem" }}>
        {offerRowTabs.map((e) => (
          <Button
            key={e.id}
            onClick={() => setTab(e.id)}
            color={EButtonColor.SECONDARY}
            size={EButtonSize.SMALL}
            style={e.id === tab ? { background: "#f8f9fa", color: "black" } : {}}
          >
            {e.name}
          </Button>
        ))}
      </div>
      {offerRowTabs.findIndex((t) => t.id === tab) > -1 ? getTab(tab) : getTab(defaultTab)}
      </OfferRowCalculationContextProvider>
    </div>
  );
};

export default forwardRef(OfferRowCalculation);
