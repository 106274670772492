import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import IProductionLine from "../../shared/IProductionLine";
import { formatDate } from "../../utils/date-utils";
import Table from "../ui/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import IWireType from "../../shared/IWireType";

interface IProps {
  productionLines: IProductionLine[];
  showTotalRow?: boolean
}

const ProductionLinesTable: React.FC<IProps> = ({ productionLines, showTotalRow }) => {
  const navigate = useNavigate();

  const openHandler = useCallback(
    (id: string) => {
      navigate(Route.productionLine(id));
    },
    [navigate]
  );

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Valu pvm</th>
          <th>Peti</th>
          {/* <th>Purku pvm</th> */}
          <th>Järj.</th>
          <th>Tehdas</th>
          <th>TM</th>
          <th>Vaijerityyppi</th>
          <th>Paksuus</th>
          <th>Harjattu</th>
          <th>Jm</th>
          <th>Kpl</th>
        </tr>
      </thead>
      <tbody>
        {productionLines.map((productionLine) => (
          <tr
            key={productionLine.id}
            onClick={() => openHandler(productionLine.id!)}
          >
            <td>{formatDate(productionLine.startDate)}</td>
            <td>{productionLine.productionLineNumber}</td>
            {/* <td>{formatDate(productionLine.endDate)}</td> */}
            <td>{productionLine.position}</td>
            <td>{productionLine.factory}</td>
            <td>{productionLine.projectNumberPhasesName}</td>
            <td>{(productionLine.wireType as IWireType)?.name}</td>
            <td>{productionLine.height}</td>
            <td>{productionLine.brushed && <FontAwesomeIcon icon={faCheck} />}</td>
            <td>{productionLine.linealMeters}</td>
            <td>{productionLine.count}</td>
          </tr>
        ))}
        {showTotalRow && (
            <tr>
                <td colSpan={7}>Yhteensä</td>
                <td>{productionLines.map(productionLine => productionLine.linealMeters).reduce((prev, cur) => cur += prev).toFixed(2)}</td>
                <td></td>
            </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ProductionLinesTable;
