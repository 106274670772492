import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { Route } from "../routes";
import TransportOrdersList from "../components/TransportOrders/TransportOrdersList";
import { EFactory } from "../shared/IOfferRow";
import { storage } from "../utils/localstorage-utils";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { FactoryOptions } from "../shared/FactoryOptions";
import { useInputs } from "../components/ui/Input/useInputs";

enum EInputs {
  factory = "factory",
}

const TransportOrdersPage: React.FC = () => {
  const navigate = useNavigate();
  const { createInput, inputs } = useTransportOrdersInputs();

  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);

  useEffect(() => {
    if (factory) {
      storage.saveSearchFactory(factory);
    }
  }, [factory]);

  const addHandler = useCallback(() => {
    navigate(Route.transportOrder(factory ? `add?factory=${factory}` : "add"));
  }, [factory, navigate]);

  return (
    <>
      <PageHeading onAdd={addHandler}>Kuljetustilaukset</PageHeading>
      <Container>
        <div>{createInput(EInputs.factory)}</div>
        <hr />
        {factory && <TransportOrdersList factory={factory} />}
      </Container>
    </>
  );
};

const useTransportOrdersInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: storage.getSearchFactory() ?? "HOL",
      placeholder: "",
      boldContent: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default TransportOrdersPage;
