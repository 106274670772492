import { useCallback } from 'react';
import { IElementsSearch } from '../components/Elements/ElementsSearch';
import { ElementsCreateDTO } from '../components/ElementsCreateModal/ElementCreateEdit';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IElement from '../shared/IElement';
import IElementDTO from '../shared/IElementDTO';
import IElementsCSVResponseDTO from '../shared/IElementsCSVResponseDTO';
import IWeeklyProductionRow from '../shared/IWeeklyProductionRow';
import { IElementPhaseSummaryDTOMap } from '../shared/IElementPhaseSummaryDTO';

interface IFetchElements extends ISignal {
  projectId?: string;
  search?: IElementsSearch | null;
}

interface IFetchProductionLineElements extends ISignal {
  productionLineId?: string;
}

export interface IMassUpdateElements {
  element: IElement;
  elementIds: string[];
}

export const useElementsService = () => {
  const customFetch = useCustomFetch();

  const fetchElements = useCallback(
    async ({ signal, projectId, search }: IFetchElements) => {
      let url = "/api/elements/list";
      if (projectId) url += "/" + projectId;
      const [elements] = await customFetch<IElement[]>(url, {
        signal,
        method: "POST",
        body: JSON.stringify(search),
      });
      return elements;
    },
    [customFetch]
  );

  const saveElement = useCallback(async (id: string, data: IElement) => {
    const [elements] = await customFetch<IElement>("/api/elements/add/" + id, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);
 
  const updateElements = useCallback(async (data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/update", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const updateProductionLineElements = useCallback(async (productionLineId: string, data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/update-production-line/" + productionLineId, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const deleteElement = useCallback(async (id: string) => {
    await customFetch<IElement>("/api/elements/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const copyElement = useCallback(async (data: IElement) => {
    const [element] = await customFetch<IElement>("/api/elements/copy", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return element;
  }, [customFetch]);

  const createProjectElementsFromWeeklyProductionRows = useCallback(async (id: string, data: IWeeklyProductionRow[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/create/" + id + "/weekly", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const createProjectElementsNew = useCallback(async (id: string, data: ElementsCreateDTO) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/create/" + id + "/new", {
      method: "POST",
      body: JSON.stringify({
        element: {
          ...data,
          provisions: [],
        },
        nameStart: data.nameStart,
        nameEnd: data.nameEnd,
        createType: data.createType,
      }),
    });
    return elements;
  }, [customFetch]);

  const fetchProductionLineElements = useCallback(
    async ({ signal, productionLineId }: IFetchProductionLineElements) => {
      let url = "/api/elements/list-production-line/" + productionLineId;
      const [elements] = await customFetch<IElementDTO[]>(url, {
        signal,
      });
      return elements;
    },
    [customFetch]
  );

  const saveElements = useCallback(async (projectId: string, data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/add-multiple/" + projectId, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const readEliplanElements = useCallback(async (projectId: string, file: File, phaseId?: string, productTypeId?: string) => {
    const data = new FormData();
    data.append("file", file);
    data.append("phaseId", phaseId ?? "");
    data.append("productTypeId", productTypeId ?? "");
    const [aiResponse] = await customFetch<IElementsCSVResponseDTO>(`/api/elements/eliplan/${projectId}`,
      {
        method: "POST",
        body: data,
        multipart: true,
      }
    );
    return aiResponse;
  }, [customFetch]);

  const massUpdateElements = useCallback(async (data: IMassUpdateElements) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/mass-update", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);
  
  const massDeleteElements = useCallback(async (ids: string[]) => {
    const [success] = await customFetch<boolean>("/api/elements/mass-delete", {
      method: "DELETE",
      body: JSON.stringify(ids),
    });
    return success;
  }, [customFetch]);

  const getElementPhaseSummaryMap = useCallback(async (id: string) => {
    const [map] = await customFetch<IElementPhaseSummaryDTOMap[]>("/api/elements/phase-summary/" + id, {
      method: "GET"
    });
    return map;
  }, [customFetch]);

  const massCopyProvisions = useCallback(async (id: string, ids: string[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/mass-copy-provisions/" + id, {
      method: "PUT",
      body: JSON.stringify(ids),
    });
    return elements;
  }, [customFetch]);

  return { fetchElements, saveElement, updateElements, updateProductionLineElements, deleteElement, copyElement, createProjectElementsFromWeeklyProductionRows, createProjectElementsNew, fetchProductionLineElements, saveElements, readEliplanElements, massUpdateElements, massDeleteElements, getElementPhaseSummaryMap, massCopyProvisions };
}
