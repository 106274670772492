import { useEffect, useMemo, useState } from "react";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { EFactory } from "../../shared/IOfferRow";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, updateInputHandler } from "../ui/Input/input-utils";
import { useInputs } from "../ui/Input/useInputs";

enum EInputs {
  factory = "factory",
  projectId = "projectId",
  typeName = "typeName",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
}

export interface IElementsSearch {
  factory?: EFactory;
  productionLineFactory?: EFactory;
  projectId?: string;
  productionLineId?: string;
  typeName?: string;
  tendonCountThick?: string;
  tendonCountThin?: string;
  isPlanned?: boolean;
}

interface IProps {
  searchHandler: (data?: IElementsSearch | null) => void;
  factory?: string;
}

const ElementsSearch: React.FC<IProps> = ({ searchHandler, factory }) => {
  const { createInput, inputs, setInputs } = useElementsSearchInputs();

  // const onSearch = useCallback(async () => {
  //   const data = await submit();
  //   searchHandler(data);
  // }, [searchHandler, submit]);

  useEffect(() => {
    if (factory) {
      updateInputHandler(EInputs.factory, factory, setInputs);
    }
  }, [factory, setInputs]);

  useEffect(() => {
    if (inputs) {
      const data = getInputData<IElementsSearch>(inputs);
      if (data) {
        searchHandler(data);
      }
    }
  }, [inputs, searchHandler]);

  const selectedFactory = useMemo(() => inputs[EInputs.factory].value as string, [inputs]);
  const { loading, options } = useProjectOptions({ showProjectNumber: true, factory: selectedFactory, factoryRequired: true });

  return (
    <>
      <div style={{ display: "flex", gap: "1rem" }} onBlur={(event) => event.stopPropagation()}>
        {createInput(EInputs.factory)}
        {createInput(EInputs.projectId, { loading, options })}
        {createInput(EInputs.typeName)}
        {createInput(EInputs.tendonCountThick)}
        {createInput(EInputs.tendonCountThin)}
      </div>
      {/* <Button onClick={onSearch}>Hae</Button> */}
    </>
  );
};

const useElementsSearchInputs = (data?: IElementsSearch) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "Valitse tehdas",
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      options: [],
      value: "",
      placeholder: "Valitse työmaa",
    },
    [EInputs.typeName]: {
      type: EInputType.text,
      label: "Vaijerityyppi",
      value: "",
      placeholder: "Syötä vaijerityyppi",
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      label: "P",
      value: "",
      placeholder: "Syötä määrä",
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      label: "O",
      value: "",
      placeholder: "Syötä määrä",
    },
  });

  const { createInput, submit } = useInputs({ inputs, setInputs, data });

  return { createInput, submit, inputs, setInputs };
};

export default ElementsSearch;
