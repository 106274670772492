import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FactoryOptions } from "../../shared/FactoryOptions";
import IElement from "../../shared/IElement";
import { RefHandle } from "../../shared/RefHandle";
import { EInputType, IInputField, IOption } from "../ui/Input/Input";
import { updateInputHandler } from "../ui/Input/input-utils";
import { useInputs } from "../ui/Input/useInputs";
import { ECreateType } from "./ElementsCreateModal";

enum EInputs {
  length = "length",
  width = "width",
  height = "height",
  weightTons = "weightTons",
  name = "name",
  productType = "productType",
  productionStatus = "productionStatus",
  measurements = "measurements",
  provisions = "provisions",
  finishing = "finishing",
  phaseId = "phaseId",
  factory = "factory",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
  productionWeek = "productionWeek",
  deliveryWeek = "deliveryWeek",
  nameStart = "nameStart",
  nameEnd = "nameEnd",
}

interface IProps {
  element?: IElement;
  phaseId?: string;
  phaseOptions: IOption[];
  productTypesOptions: IOption[];
  productTypesLoading: boolean;
}

export type ElementsCreateDTO = {
  nameStart: string;
  nameEnd: string;
  createType: ECreateType;
} & IElement;

const ElementCreateEdit: React.ForwardRefRenderFunction<RefHandle<ElementsCreateDTO>, IProps> = ({
  element,
  phaseId,
  phaseOptions,
  productTypesOptions,
  productTypesLoading,
}, ref) => {
  const { createInput, submit, setInputs } = useElementInputs(element as ElementsCreateDTO);

  useEffect(() => {
    if (phaseId) {
      updateInputHandler(EInputs.phaseId, phaseId, setInputs);
    }
  }, [phaseId, setInputs]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      if (!data) return null;
      return data;
    },
  }), [submit]);

  return (
    <tr>
      <td>{createInput(EInputs.nameStart)}</td>
      <td>{createInput(EInputs.nameEnd)}</td>
      <td>{createInput(EInputs.phaseId, { options: phaseOptions })}</td>
      <td>
        {createInput(EInputs.productType, {
          options: productTypesOptions,
          loading: productTypesLoading,
        })}
      </td>
      <td>{createInput(EInputs.tendonCountThick)}</td>
      <td>{createInput(EInputs.tendonCountThin)}</td>
      <td>{createInput(EInputs.length)}</td>
      <td>{createInput(EInputs.width)}</td>
      <td>{createInput(EInputs.height)}</td>
      <td>{createInput(EInputs.productionWeek)}</td>
      <td>→</td>
      <td>{createInput(EInputs.deliveryWeek)}</td>
      <td>{createInput(EInputs.factory)}</td>
    </tr>
  );
};

const useElementInputs = (data?: ElementsCreateDTO) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.length]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.width]: {
      type: EInputType.number,
      value: "1200",
    },
    [EInputs.height]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.weightTons]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      validation: {
        required: true,
      },
    },
    [EInputs.productionStatus]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.measurements]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.provisions]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.finishing]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.phaseId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productionWeek]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.deliveryWeek]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.nameStart]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.nameEnd]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
      },
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, setInputs };
};

export default forwardRef(ElementCreateEdit);
