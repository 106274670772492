import { useCallback } from "react";
import { ISignal, useCustomFetch } from "../custom-fetch";
import IProductionLine from "../shared/IProductionLine";
import IElement from "../shared/IElement";
import IWeeklyProductionLine from "../shared/IWeeklyProductionLine";
import { IWaybillRow } from "../shared/IWaybill";

interface IFetchProductionLines extends ISignal {
  factory?: string;
}

interface IGetProductionLine extends ISignal {
  id: string;
}

export const useProductionLinesService = () => {
  const customFetch = useCustomFetch();

  const fetchProductionLines = useCallback(async ({ signal, factory }: IFetchProductionLines) => {
    let url = "/api/production-lines/list";
    if (factory) {
      url += "/" + factory;
    }
    const [productionLines] = await customFetch<IProductionLine[]>(url, { signal });
    return productionLines;
  }, [customFetch]);

  const getProductionLine = useCallback(async ({ signal, id }: IGetProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/get/" + id, { signal });
    return productionLine;
  }, [customFetch]);

  const saveProductionLine = useCallback(async (data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/add", { method: "POST", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  const updateProductionLine = useCallback(async (id: string, data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/update/" + id, { method: "PUT", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  const deleteProductionLine = useCallback(async (id: string) => {
    await customFetch<IProductionLine>("/api/production-lines/delete/" + id, { method: "DELETE" });
    return true;
  }, [customFetch]);

  const createProductionLines = useCallback(async (projectId: string, data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/production-lines/create/" + projectId, { method: "PUT", body: JSON.stringify(data) });
    return elements;
  }, [customFetch]);

  const fetchWeeklyProductionLines = useCallback(async ({ signal, factory }: IFetchProductionLines) => {
    let url = "/api/production-lines/list-weekly";
    if (factory) {
      url += "/" + factory;
    }
    const [productionLines] = await customFetch<IWeeklyProductionLine[]>(url, { signal });
    return productionLines;
  }, [customFetch]);

  const reorderProductionLines = useCallback(async (data: IProductionLine[]) => {
    await customFetch<boolean>("/api/production-lines/reorder", { method: "PUT", body: JSON.stringify(data) });
    return true;
  }, [customFetch]);

  const createUnloadingOrder = useCallback(async (data: IWaybillRow[]) => {
    const [rows] = await customFetch<IWaybillRow[]>("/api/production-lines/create-unloading-order", { method: "PUT", body: JSON.stringify(data) });
    return rows;
  }, [customFetch]);

  return { fetchProductionLines, getProductionLine, saveProductionLine, updateProductionLine, deleteProductionLine, createProductionLines, fetchWeeklyProductionLines, reorderProductionLines, createUnloadingOrder };
};
