import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { useOrganizationService } from "../../services/organizations-service";
import { QueryKey } from "../../services/query-keys";
import IOrganization, { GROUP_OPTIONS, OrganizationType } from "../../shared/IOrganization";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";

interface IProps {
  id: string;
  organization?: IOrganization;
  type: OrganizationType;
}

enum EInputs {
  number = "number",
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  group = "group",
}

const OrganizationEdit: React.FC<IProps> = ({ id, organization, type }) => {
  const isEdit = id !== "add";

  let redirect = null;
  let queryKey = null;
  switch (type) {
    case "CUSTOMER":
      redirect = Route.customers;
      queryKey = QueryKey.customers;
      break;
    case "ENGINEER":
      redirect = Route.engineers;
      queryKey = QueryKey.engineers;
      break;
  }

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirect, queryKey);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirect, queryKey);
  const { createInput, submit } = useOrganizationInputs(organization);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      data.type = type;
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit, type]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.number)}
      {createInput(EInputs.name)}
      {createInput(EInputs.businessId)}
      {createInput(EInputs.streetAddress)}
      {createInput(EInputs.zip)}
      {createInput(EInputs.city)}
      {createInput(EInputs.group)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa asiakkaan?"
      />
    </>
  );
};

const useOrganizationInputs = (data?: IOrganization) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.number]: {
      type: EInputType.number,
      label: "Numero",
      value: "",
      autoFocus: true
    },
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
    },
    [EInputs.businessId]: {
      type: EInputType.text,
      label: "Y-Tunnus",
      value: "",
    },
    [EInputs.streetAddress]: {
      type: EInputType.text,
      label: "Katuosoite",
      value: "",
    },
    [EInputs.zip]: {
      type: EInputType.text,
      label: "Postinumero",
      value: "",
    },
    [EInputs.city]: {
      type: EInputType.text,
      label: "Kaupunki",
      value: "",
    },
    [EInputs.group]: {
      type: EInputType.reactSelect,
      label: "Ryhmä",
      value: "",
      options: GROUP_OPTIONS
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirect: Route, queryKey: QueryKey) => {
  const navigate = useNavigate();

  const { updateOrganization, saveOrganization } = useOrganizationService();

  const mutationFn = (data: IOrganization) => {
    return isEdit ? updateOrganization(id, data) : saveOrganization(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IOrganization, ErrorResponse, IOrganization>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [queryKey, id] });
      queryClient.invalidateQueries({ queryKey: [queryKey] });
      navigate(redirect);
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirect: Route, queryKey: QueryKey) => {
  const { deleteOrganization } = useOrganizationService();

  const navigate = useNavigate();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteOrganization(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKey],
        refetchType: "none",
      });
      navigate(redirect);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default OrganizationEdit;
