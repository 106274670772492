import { useState } from "react";
import IOffer from "../../shared/IOffer";
import Button, { EButtonColor } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import InputGroup from "../ui/InputGroup/InputGroup";

enum EInputs {
  projectNumber = "projectNumber",
}

interface IProps {
  onAccept: (data: IOffer) => Promise<void>;
  onCancel: () => void;
}

const ProjectAddModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
}) => {
  const { createInput, submit } = usePhaseInputs();
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      setLoading(true);
      await onAccept(data);
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBody style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <span>Jos työmaanumero on tyhjä, se luodaan automaattisesti.</span>
        <InputGroup>
          {createInput(EInputs.projectNumber)}
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const usePhaseInputs = (data?: IOffer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectNumber]: {
      type: EInputType.number,
      value: "",
      label: "Työmaanumero",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default ProjectAddModal;
