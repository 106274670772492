import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IConcreteType from "../../shared/IConcreteType";
import { useConcreteTypeService } from "../../services/concreteTypes-service";
import { useState } from "react";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";

interface IProps {
  concreteTypes: IConcreteType[];
  showUpdateArchived?: boolean
}

const ConcreteTypesTable: React.FC<IProps> = ({ concreteTypes, showUpdateArchived }) => {
  const navigate = useNavigate();
  const { archivedUpdateConcreteType } = useConcreteTypeService();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateConcreteType(id);
      queryClient.setQueryData([QueryKey.concreteTypes], (concreteTypes: IConcreteType[]) => 
        concreteTypes.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.concreteType(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Paino kg /m³</th>
          <th>Puristuslujuus</th>
          <th>Hinta € /m³</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {concreteTypes.map((concreteType) => (
          <tr
            key={concreteType.id}
            onClick={() => openHandler(concreteType.id)}
            style={{
              color: concreteType.archived ? "gray" : "inherit",
              fontStyle: concreteType.archived ? "italic" : "inherit",
            }}
          >
            <td>{concreteType.unitName}</td>
            <td>{concreteType.weight}</td>
            <td>{concreteType.compressionStrength}</td>
            <td>{concreteType.price} €</td>
            <td>{concreteType.updatedString}</td>
            {showUpdateArchived ?
            <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(concreteType.id)} onClick={(event)=>archivedUpdateHandler(concreteType.id, event)} icon={concreteType.archived ? faEyeSlash : faEye} title={concreteType.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            :
            <td>{concreteType.archived && "arkistoitu"}</td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ConcreteTypesTable;
