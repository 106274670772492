import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import IElementsCSVResponseDTO from "../../shared/IElementsCSVResponseDTO";
import IProductType from "../../shared/IProductType";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import { updateInputHandler } from "../ui/Input/input-utils";

export interface IAIModalData {
  prePrompt: string;
  file: File;
  phaseId?: string;
  productTypeId?: string;
}

interface IProps {
  onAccept: (data: IElementsCSVResponseDTO) => Promise<IElementsCSVResponseDTO | null>;
  onCancel: () => void;
  onUpload: (data: IAIModalData) => Promise<IElementsCSVResponseDTO | null>;
  productTypeOptions?: IOption[];
  phaseOptions?: IOption[];
  phaseId?: string;
}

enum EInputs {
  prePrompt = "prePrompt",
  file = "file",
  phaseId = "phaseId",
  productTypeId = "productTypeId",
}

const AIModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  onUpload,
  productTypeOptions = [],
  phaseOptions = [],
  phaseId,
}) => {
  const openConfirmModal = useConfirmModal();

  const [loading, setLoading] = useState(false);
  const { createInput, submit, setInputs } = useAIInputs();
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [aiResponse, setAIResponse] = useState<IElementsCSVResponseDTO | null>(null);
  const [submitResponse, setSubmitResponse] = useState<IElementsCSVResponseDTO | null>(null);

  useEffect(() => {
    if (phaseId) {
      updateInputHandler(EInputs.phaseId, phaseId, setInputs);
    }
  }, [phaseId, setInputs]);

  const allowSubmit = useMemo(() => !!aiResponse?.newItems, [aiResponse?.newItems]);

  const submitHandler = useCallback(async () => {
    if (!aiResponse) return;
    setLoading(true);
    const resp = await onAccept(aiResponse);
    setSubmitResponse(resp);
    setLoading(false);
  }, [aiResponse, onAccept]);

  const uploadHandler = useCallback(async () => {
    const data = await submit();
    if (!file) {
      setFileError("Tiedosto puuttuu!");
      return;
    }
    setAIResponse(null);
    setLoading(true);
    if (data) {
      (data as any).file = file;
      const resp = await onUpload(data);
      setAIResponse(resp);
      setFileError(null);
      if (resp?.error) {
        setFile(null);
      }
    }
    setLoading(false);
  }, [file, onUpload, submit]);

  const onDrop = useCallback((inputName: string, acceptedFiles: File[]) => {
    console.log(inputName, acceptedFiles);
    const file = acceptedFiles[0];
    if (file) {
      setFileError(null);
    }
    setFile(file);
  }, []);

  const deleteElementHandler = useCallback(async (event: React.MouseEvent, elementId: string) => {
    if (!aiResponse) return;
    const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa elementin?");
    if (isConfirm) {
      setAIResponse(aiResponse => {
        if (!aiResponse) return null;
        const newResponse = {...aiResponse};
        if (newResponse.newItems) {
          newResponse.newItems = newResponse.newItems.filter(element => element.elementId !== elementId);
        }
        return newResponse;
      });
    }
  }, [aiResponse, openConfirmModal]);

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        <span>Jos valittu, nämä arvot laitetaan kaikille elementeille.</span>
        <InputGroup>
          {createInput(EInputs.phaseId, { options: phaseOptions })}
          {createInput(EInputs.productTypeId, { options: productTypeOptions, containerStyles: { width: "125px" } })}
        </InputGroup>
        <span>Jos palautetut elementit eivät näytä oikeanmuotoisilta, muokkaa syötettä ja lähetä tiedosto uudelleen.</span>
        {createInput(EInputs.prePrompt)}
        {createInput(EInputs.file, { onDrop })}
        {fileError && <ErrorsAlert errors={[fileError]} />}
        {file && (
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <span>Valittu tiedosto: {file?.name}</span>
            <Button onClick={() => setFile(null)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" />
          </div>
        )}
        {aiResponse && (
          aiResponse.error ? (
            <ErrorsAlert errors={aiResponse.error.messages} />
          ) : aiResponse.newItems ? (
            <>
              <Table>
                <caption>
                  <PageHeading variant="h3" noHorizontalMargin>Tunnistetut elementit</PageHeading>
                </caption>
                <thead>
                  <tr>
                    <th>Kerros / lohko</th>
                    <th>Tyyppi</th>
                    <th>Tunnus</th>
                    <th>Pituus</th>
                    <th>Leveys</th>
                    <th>Korkeus</th>
                    <th>m²</th>
                    <th>Paino ton</th>
                    <th>Varaukset</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {aiResponse.newItems.map(element => (
                    <tr key={element.elementId}>
                      <td>{element.phaseName}</td>
                      <td>{(element.productType as IProductType)?.name}</td>
                      <td>{element.name}</td>
                      <td>{element.length}</td>
                      <td>{element.width}</td>
                      <td>{element.height}</td>
                      <td>{element.area}</td>
                      <td>{element.weightTons}</td>
                      <td>{element.provisionAmountLong}</td>
                      <td><Button onClick={(event) => deleteElementHandler(event, element.elementId)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" /></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <span>Tunnistettuja elementtejä yhteensä: {aiResponse.newItems.length} kpl</span>
            </>
          ) : null
        )}
        {aiResponse?.failedCSVRows && aiResponse.failedCSVRows.map(warning => <span key={warning}>{warning}</span>)}
        {submitResponse?.error && <ErrorsAlert errors={submitResponse.error.messages} />}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={uploadHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Lue tiedosto
        </Button>
        {allowSubmit && (
          <Button
            onClick={submitHandler}
            style={{ marginRight: "0.5rem" }}
            loading={loading}
          >
            Tallenna
          </Button>
        )}
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useAIInputs = (data?: IAIModalData) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.prePrompt]: {
      type: EInputType.textarea,
      label: "Syöte",
      value: "",
      rows: 4,
    },
    [EInputs.file]: {
      type: EInputType.dropzone,
      label: "Tiedosto",
      value: "",
      canPaste: true,
    },
    [EInputs.phaseId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Kerros / lohko",
    },
    [EInputs.productTypeId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Tyyppi",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, setInputs };
};

export default AIModal;
