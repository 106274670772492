import { useCallback, useState } from "react";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IProductionLine from "../../../shared/IProductionLine";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";

export enum EInputs {
  productionLineLength = "productionLineLength",
  startDate = "startDate",
  endDate = "endDate",
  factory = "factory",
  position = "position",
  brushed = "brushed",
  wireType = "wireType",
  isPlanned = "isPlanned",
  notes = "notes",
}

const initialInputs: IInputField = {
  [EInputs.productionLineLength]: {
    type: EInputType.number,
    value: "115000",
    label: "Linjan pituus (mm)",
  },
  [EInputs.startDate]: {
    type: EInputType.date,
    value: "",
    label: "Valu pvm",
  },
  [EInputs.endDate]: {
    type: EInputType.date,
    value: "",
    label: "Purku pvm",
  },
  [EInputs.factory]: {
    type: EInputType.reactSelect,
    options: FactoryOptions,
    value: "",
    hideControls: true,
    menuPosition: "fixed",
    placeholder: "",
    label: "Tehdas",
    validation: {
      required: true,
    },
  },
  [EInputs.position]: {
    type: EInputType.number,
    value: "",
    label: "Järjestysnumero",
  },
  [EInputs.brushed]: {
    type: EInputType.checkbox,
    label: "Harjattu",
    options: [{ value: ECommonValue.YES }],
    value: [],
  },
  [EInputs.wireType]: {
    type: EInputType.reactSelect,
    options: [],
    value: "",
    hideControls: true,
    menuPosition: "fixed",
    placeholder: "",
    label: "Vaijerityyppi",
  },
  [EInputs.isPlanned]: {
    type: EInputType.checkbox,
    label: "Suunniteltu purkujärjestykseen",
    options: [{ value: ECommonValue.YES }],
    value: [],
  },
  [EInputs.notes]: {
    type: EInputType.textarea,
    label: "Huomio",
    value: "",
    rows: 4,
  },
};

export const useProductionLineInputs = (data?: IProductionLine) => {
  const [inputs, setInputs] = useState<IInputField>(structuredClone(initialInputs));
  const resetInputs = useCallback(() => setInputs({ ...structuredClone(initialInputs), [EInputs.factory]: { ...inputs[EInputs.factory] } }), [inputs]);
  const { createInput, submit } = useInputs({ data, inputs, setInputs });
  return { createInput, submit, inputs, setInputs, resetInputs };
};
