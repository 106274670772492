import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import IElementUnloading from "../../shared/IElementUnloading";
import ElementUnloadingTable from "./ElementUnloadingTable";

interface IProps {
  items: IElementUnloading[];
  dragUpdateHandler: (result: DragUpdate) => void;
  updateHandler: (result: DropResult) => Promise<void>;
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
  isWaybill?: boolean;
  waybillDeliveryDate?: string;
}

const ElementUnloadingDragAndDropTable: React.FC<IProps> = ({
  items,
  dragUpdateHandler,
  updateHandler,
  isDropDisabled = false,
  isDragDisabled = false,
  isWaybill = false,
  waybillDeliveryDate,
}) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      <DragDropContext
        onDragUpdate={dragUpdateHandler}
        onDragEnd={updateHandler}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            {items.map((item, index) =>
              index === 0 ? null : (
                <ElementUnloadingTable
                  key={item.slot}
                  item={{ slot: item.slot, elements: item.elements }}
                  isDropDisabled={isDropDisabled}
                  isDragDisabled={isDragDisabled}
                  style={{ flex: "1 0 34%" }}
                  isWaybill={isWaybill}
                  waybillDeliveryDate={waybillDeliveryDate}
                />
              )
            )}
          </div>
          <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
          <div>
            <ElementUnloadingTable
              key={items[0].slot}
              item={{ slot: items[0].slot, elements: items[0].elements }}
              isDropDisabled={isDropDisabled}
              isDragDisabled={isDragDisabled}
              isWaybill={isWaybill}
              waybillDeliveryDate={waybillDeliveryDate}
            />
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default ElementUnloadingDragAndDropTable;
