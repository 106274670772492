import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useOfferTermOptions } from "../../hooks/useOfferTermOptions";
import { Route } from "../../routes";
import { useOfferService } from "../../services/offers-service";
import { QueryKey } from "../../services/query-keys";
import IOffer from "../../shared/IOffer";
import { EOfferTermType } from "../../shared/IOfferTerm";
import { RefHandle } from "../../shared/RefHandle";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import SummaryTable from "../Offers/OfferCalculationTabs/OfferCalculationSummary/SummaryTable";
import OfferHeader from "../Offers/OfferHeader/OfferHeader";
import OrganizationUserSelect, {
  IOrganizationUserSelectHandle,
} from "../OrganizationUserSelect/OrganizationUserSelect";
import { useProjectAddModal } from "../ProjectAddModal/useProjectAddModal";
import Button from "../ui/Button/Button";
import Container from "../ui/Container/Container";
import Input, { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useDialog } from "../ui/Modal/useDialog";
import classes from "./Order.module.scss";

enum EInputs {
  productionNotes = "productionNotes",
  estimatedDeliveryWeeks = "estimatedDeliveryWeeks",
  customerReferenceNumber = "customerReferenceNumber",
  numberOfFloors = "numberOfFloors",
}

interface IProps {
  offer: IOffer;
  engineerRef: React.RefObject<IOrganizationUserSelectHandle>;
  onReturn: () => void;
  orderPrintHandler: () => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Order: React.ForwardRefRenderFunction<RefHandle<Partial<IOffer>>, IProps> = ({ offer, engineerRef, onReturn, orderPrintHandler, setIsDirty }, ref) => {
  const { createInput, submit } = useOrderInputs(offer);
  const { isPending, sendOrder } = useSendOrder(offer.id);
  const openProjectAddModal = useProjectAddModal(sendOrder);

  const { offerTerms: freightTerms } = useOfferTermOptions(EOfferTermType.FREIGHT_TERM, true);
  const [freightNotes, setFreightNotes] = useState<string>(offer?.freightNotes ?? "");
  useEffect(() => {
    if (!freightNotes && freightTerms && freightTerms[0]) {
      setFreightNotes(freightTerms[0].text);
    }
  }, [freightNotes, freightTerms]);

  const { offerTerms: planningDeliveryTerms } = useOfferTermOptions(EOfferTermType.PLANNING_DELIVERY, true);
  const [planningDeliveryNotes, setPlanningDeliveryNotes] = useState<string>(offer?.planningDeliveryNotes ?? "");
  useEffect(() => {
    if (!planningDeliveryNotes && planningDeliveryTerms && planningDeliveryTerms[0]) {
      setPlanningDeliveryNotes(planningDeliveryTerms[0].text);
    }
  }, [planningDeliveryNotes, planningDeliveryTerms]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      const engineerData = await engineerRef.current?.getData();
      return {
        ...data,
        engineer: engineerData?.organization || undefined,
        engineerContactPerson: engineerData?.user || undefined,
        freightNotes,
        planningDeliveryNotes,
      }
    }
  }), [submit, engineerRef, freightNotes, planningDeliveryNotes]);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    const engineerData = await engineerRef.current?.getData();
    if (!data || !engineerData) return;
    try {
      const isSuccess = await openProjectAddModal({
        ...offer,
        ...data,
        engineer: engineerData?.organization || undefined,
        engineerContactPerson: engineerData?.user || undefined,
        freightNotes,
        planningDeliveryNotes,
      });
      // await sendOrder();
      setIsDirty(!isSuccess);
    } catch (error) {
      console.error(error);
    }
  }, [engineerRef, freightNotes, offer, openProjectAddModal, planningDeliveryNotes, setIsDirty, submit]);

  const initialEngineerData = useMemo(() => ({
    organization: offer?.engineer,
    organizationId: offer?.engineer?.id ?? "",
    userId: offer?.engineerContactPerson?.id ?? "",
  }), [offer]);

  const isEdit = useMemo(() => offer?.id !== "add", [offer?.id]);
  const isWon = useMemo(() => offer?.status === "WON", [offer?.status]);

  // const { saveProjectMutate, isPending: saveProjectPending } = useSaveProject();

  // TODO tarkista onko olemassa
  // const createProjectHandler = useCallback(async () => {
  //   const isConfirm = await openConfirmModal("Oletko varma, että haluat tehdä tarjouksesta työmaan?");
  //   if (isConfirm) {
  //     await saveProjectMutate({ id: "", name: offer?.targetName ?? "", offer, phases: [] });
  //   }
  // }, [offer, openConfirmModal, saveProjectMutate]);

  // const allRowsSent = offer.rows.every(row => !!row.priceGuid);

  return (
    <>
      <OfferHeader
        onSubmit={offer.projectId ? undefined : submitHandler}
        isLoading={isPending}
        onReturn={onReturn}
        isEdit={isEdit}
        offerNumber={offer?.offerNumber}
        projectNumber={offer?.projectNumber}
        submitText="Tee työmaa"
        isWon={isWon}
        title="Tilaus"
      >
        <Button onClick={orderPrintHandler}>
          Tulosta tilausvahvistus
        </Button>
        {/* {isEdit && isWon && (
          <Button onClick={createProjectHandler} loading={saveProjectPending}>
            Tee työmaa
          </Button>
        )} */}
      </OfferHeader>
      <Container>
        <div className={classes.Header}>
          <h2>Tarjous {offer.offerNumber}</h2>
          <div className={classes.Customer}>
            <h2>{offer.targetName}</h2>
            <p>{offer.customer?.name}</p>
            <p>{offer.deliveryStreetAddress}</p>
            <p>{offer.deliveryZip}</p>
            <p>{offer.deliveryCity}</p>
          </div>
        </div>

        <div style={{maxWidth: "1124px", paddingTop: "2rem", paddingBottom: "2rem"}}>
        <SummaryTable summary={offer.summary} />
        </div>

        <div className={classes.Content} >
          <div style={{width: "500px"}}>
            {createInput(EInputs.productionNotes)}
            {createInput(EInputs.estimatedDeliveryWeeks)}
            {createInput(EInputs.customerReferenceNumber)}
            {createInput(EInputs.numberOfFloors)}
          </div>
          <div style={{width: "500px"}}>
            <Input
              value={freightNotes}
              type={EInputType.textarea}
              onChange={(value) => setFreightNotes(value.toString())}
              inputName={"freightNotes"}
              label={"Kuormien tilaus"}
              rows={4}
            />
            <Input
              value={planningDeliveryNotes}
              type={EInputType.textarea}
              onChange={(value) => setPlanningDeliveryNotes(value.toString())}
              inputName={"planningDeliveryNotes"}
              label={"Suunnitelmien toimitus"}
              rows={4}
            />
          </div>
          <OrganizationUserSelect
            type="ENGINEER"
            label="Suunnittelutoimisto"
            ref={engineerRef}
            initialData={initialEngineerData}
            // organizationRequired
          />
        </div>

      </Container>
    </>
  );
};

const useOrderInputs = (data?: IOffer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.productionNotes]: {
      type: EInputType.textarea,
      label: "Huom. tuotantoon",
      rows: 4,
      value: "",
    },
    [EInputs.estimatedDeliveryWeeks]: {
      type: EInputType.number,
      label: "ArvTVko",
      placeholder: "ArvTVko",
      value: "",
    },
    [EInputs.customerReferenceNumber]: {
      type: EInputType.text,
      label: "Asiakkaan tilausnumero",
      placeholder: "Asiakkaan tilausnumero",
      value: "",
      maxLength: 50,
    },
    [EInputs.numberOfFloors]: {
      type: EInputType.number,
      label: "Kerroksia",
      placeholder: "Kerroksia",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
  });

  return { createInput, submit };
};

const useSendOrder = (id: string) => {
  const { sendOffer } = useOfferService();
  const openDialog = useDialog();
  const navigate = useNavigate();

  const {
    mutateAsync: sendOrder,
    isPending,
    isError,
    error,
  } = useMutation<IOffer, ErrorResponse, {projectNumber?: string, data: IOffer}>({
    mutationFn: ({ data, projectNumber }) => sendOffer(id, data, projectNumber),
    onSuccess: async (data) => {
      // TODO: offerRows ei päivity, priceGuid pitää säilyä että ei tallenneta uudestaan
      queryClient.setQueryData([QueryKey.offers, data.id], { ...data });
      // queryClient.invalidateQueries({ queryKey: [QueryKey.offers] });
      // navigate(Route.project(newOffer.projectId));
      // const isConfirm = await openConfirmModal(<p><FontAwesomeIcon icon={faCheckCircle} color="green" /> Tilaus lähetetty onnistuneesti.</p>, {
      //   title: "Tee tilaus",
      //   acceptText: "Siirry työmaalle"
      // });
      // if (isConfirm) {
      navigate(Route.project(data.projectId!));
      // }
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.offers, id] });
      openDialog("Virhe", (
        <>
          <p><FontAwesomeIcon icon={faTimesCircle} color="red" /> Tilauksen lähetys epäonnistui.</p>
          <ErrorsAlert errors={error.messages} />
        </>
      ));
    },
  });

  return { sendOrder, isPending, isError, error };
};

// const useSaveProject = () => {
//   const { saveProject } = useProjectsService();
//   const openDialog = useDialog();

//   const {
//     mutateAsync: saveProjectMutate,
//     isPending,
//     isError,
//     error,
//   } = useMutation<IProject, ErrorResponse, IProject>({
//     mutationFn: (data) => saveProject(data),
//     onSuccess: () => {
//       openDialog("Tee työmaa", <p><FontAwesomeIcon icon={faCheckCircle} color="green" /> Työmaa luotu onnistuneesti.</p>);
//     },
//     onError: (error) => {
//       openDialog("Virhe", (
//         <>
//           <p><FontAwesomeIcon icon={faTimesCircle} color="red" /> Työmaan luonti epäonnistui.</p>
//           <ErrorsAlert errors={error.messages} />
//         </>
//       ));
//     },
//   });

//   return { saveProjectMutate, isPending, isError, error };
// }

export default forwardRef(Order);
