import { useMemo, useState } from "react";
import IElement from "../../shared/IElement";
import Button, { EButtonColor } from "../ui/Button/Button";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import Select from "../ui/Select/Select";
import Alert from "../ui/Alert/Alert";

interface IProps {
  onAccept: (id: string) => Promise<void>;
  onCancel: () => void;
  elements?: IElement[];
}

const ElementsCreateModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  elements = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedElement, setSelectedElement] = useState<IElement | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  const submitHandler = async () => {
    if (!selectedElement) {
      setError("Valitse elementti.");
      return;
    }
    setError(null);
    setLoading(true);
    await onAccept(selectedElement.id);
    setLoading(false);
  };

  const elementOptions = useMemo(
    () =>
      [...elements]
        .filter((el) => !!el.provisionAmountShort)
        .map((element) => ({
          value: element.id,
          label: element.name,
        })),
    [elements]
  );

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ width: "fit-content" }}>
            <label>Kopioi elementistä:</label>
            <Select
              name="selectElementId"
              value={selectedElement?.id as string}
              onChange={(value) => setSelectedElement(elements.find(element => element.id === value as string) ?? undefined)}
              options={elementOptions}
              isClearable
              hideControls
              placeholder=""
              autoFocus
              menuPosition="fixed"
            />
          </div>
          <div>
            {selectedElement?.provisionAmountLong}
          </div>
        </div>
        {error && <Alert>{error}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

export default ElementsCreateModal;
