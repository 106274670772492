import { IOption } from "../components/ui/Input/Input";

export enum EWaybillType {
	PROJECT = "PROJECT", 
	INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
}

export const WAYBILL_TYPE_OPTIONS: IOption[] = [
	{ value: EWaybillType.PROJECT, label: "Työmaalle" },
	{ value: EWaybillType.INTERNAL_TRANSFER, label: "Sisäinen siirto" },
];

interface IWaybill {
	id: string;
	waybillId: string;
	projectId: string;
	projectNumber: string;
	projectName: string;
	destination: string;
	driverOrganization: string;
	driver: string;
	car: string;
	deliveryDate: string;
	deliveryTime: string;
	deliveryPrice: string;
	waitingTime: string;
	waitingPrice: string;
	waitingReason: EWaitingReason;
	waitingReasonString: string;
	kilometers: string;
	accepted: string;
	price: string;
	tons: string;
	tonsInvoice: string;
	count: string;
	notes: string;
	additionalNotes: string;
	factory: string;
	orderNumber: string;
	nakki: boolean;
	waybillRows: IWaybillRow[];
	uuid: string;
	locations: ILocation[];
	arriveTime: string;
	departTime: string;
	loadEndTime: string;
	unloadStartTime: string;
	latestLocation?: ILocation;
	contactPerson: string;
	contactInfo: string;
	type: EWaybillType;
	transportOrderId: string;

	prevWaybillId?: string;
	nextWaybillId?: string;
}

export interface IWaybillRow {
	id: string;
	elementId?: string;
	slot?: string;
	order?: string;
}

export interface ILocation {
  latitude?: number;
  longitude?: number;
  accuracy?: number;
  created?: string;
  x?: number;
  y?: number;
  hwId?: string;
  hwName?: string;
}

export enum EWaitingReason {
  PRODUCTION = "PRODUCTION",
  CUSTOMER = "CUSTOMER",
  DRIVER_ORGANIZATION = "DRIVER_ORGANIZATION",
}

export const WAITING_REASON_OPTIONS: IOption[] = [
  { value: EWaitingReason.PRODUCTION, label: "Tuotanto" },
  { value: EWaitingReason.CUSTOMER, label: "Asiakas" },
  { value: EWaitingReason.DRIVER_ORGANIZATION, label: "Kuljetusliike" },
];

export const DriverOrganizationOptions: IOption[]=[
    {value:"Kuljetusliike Kontkanen", label:"Kuljetusliike Kontkanen"},
    {value:"Pielisen Betoni", label:"Pielisen Betoni"},
]

export default IWaybill;