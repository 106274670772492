import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Draggable,
  Droppable
} from "react-beautiful-dnd";
import { IWeeklyProductionLineDay } from "../../shared/IWeeklyProductionLine";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import {
  getItemStyle,
  getListStyle,
} from "../WeeklyProductionLines/useWeeklyProductionLinesDragAndDrop";
import { Link } from "react-router-dom";
import { Route } from "../../routes";
import IWireType from "../../shared/IWireType";

interface IProps {
  showTotalRow?: boolean;
  day: IWeeklyProductionLineDay;
  productionWeek: string;
  isReordering: boolean;
}

const ProductionLinesDragAndDropTable: React.FC<IProps> = ({
  showTotalRow,
  day,
  productionWeek,
  isReordering,
}) => {
  // const [productionLines, setProductionLines] = useState<IProductionLine[]>([]);
  // const { dragEndHandler, dragUpdateHandler, dragStartHandler, items, getItemStyle, getListStyle } = useDragAndDrop(productionLines, setProductionLines);

  // const { reorderMutate, isReordering, isReorderError, reorderError } = useReorder();

  // const updateHandler = useCallback(async (result: DropResult) => {
  //   const newItems = dragEndHandler(result);
  //   if (newItems) {
  //     await reorderMutate(newItems);
  //   }
  // }, [dragEndHandler, reorderMutate]);

  // useEffect(() => {
  //   setProductionLines(initialProductionLines ?? []);
  // }, [initialProductionLines]);

  return (
    <>
      <Droppable
        droppableId={`${productionWeek}/${day.startDate}`}
        isDropDisabled={isReordering}
      >
        {(droppableProvided, droppableSnapshot) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={getListStyle(droppableSnapshot.isDraggingOver)}
            // style={{ background: "#ccc", padding: "1rem" }}
          >
            {/* {isReorderError && <ErrorsAlert errors={reorderError!.messages} />} */}
            <Table hover>
              <caption>
                <PageHeading variant="h3" noHorizontalMargin>
                  {day.startDateFin}
                  {/* {isReordering && <Spinner size={ESpinnerSize.SMALL} />} */}
                </PageHeading>
              </caption>
              <thead>
                <tr>
                  <th style={{ width: "125px" }}>Valu pvm</th>
                  <th style={{ width: "125px" }}>Purku pvm</th>
                  <th style={{ width: "125px" }}>Järj.</th>
                  <th style={{ width: "125px" }}>Peti</th>
                  <th style={{ width: "125px" }}>Vaijerityyppi</th>
                  <th style={{ width: "125px" }}>Paksuus</th>
                  <th style={{ width: "125px" }}>Harjattu</th>
                  <th style={{ width: "125px" }}>Suunniteltu purkujärjestys</th>
                  <th style={{ width: "125px" }}>Jm</th>
                  <th style={{ width: "125px" }}>Kpl</th>
                  <th style={{ width: "125px" }}>TM</th>
                </tr>
              </thead>
              <tbody>
                {day.productionLines.map((productionLine, index) => (
                  <Draggable
                    key={productionLine.id}
                    draggableId={productionLine.id!}
                    index={index}
                    isDragDisabled={isReordering}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <tr
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style
                        )}
                      >
                        <td style={{ width: "125px" }}>
                          {productionLine.startDateFin}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.endDateFin}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.position}
                        </td>
                        <td style={{ width: "125px" }}>
                          <Link to={Route.productionLine(productionLine.id!)}>{productionLine.productionLineNumber}</Link>
                        </td>
                        <td style={{ width: "125px" }}>
                          {(productionLine.wireType as IWireType)?.name}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.height}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.brushed && (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.isPlanned ? (
                            <FontAwesomeIcon icon={faCheck} />
                            ) : <b>!</b>
                          }
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.linealMeters}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.count}
                        </td>
                        <td style={{ width: "125px" }}>
                          {productionLine.projectNumberPhases}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </tbody>
              <tfoot>
                {showTotalRow && (
                  <tr>
                    <td colSpan={8}>Yhteensä</td>
                    <td>
                      {day.productionLines.length > 0 &&
                        [...day.productionLines]
                          .map((productionLine) => productionLine.linealMeters ?? 0)
                          .reduce((prev, cur) => (cur += prev))
                          .toFixed(2)}
                    </td>
                    <td>
                      {day.productionLines.length > 0 &&
                        [...day.productionLines]
                          .map((productionLine) => productionLine.count ?? 0)
                          .reduce((prev, cur) => (cur += prev))}
                    </td>
                    <td></td>
                  </tr>
                )}
                {/* {isReordering && <tr><td colSpan={9}><Spinner center /></td></tr>} */}
              </tfoot>
            </Table>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default ProductionLinesDragAndDropTable;
