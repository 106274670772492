import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import ITransportationCost from "../../shared/ITransportationCost";
import { useTransportationCostService } from "../../services/transportationCosts-service";
import { useState } from "react";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";

interface IProps {
  transportationCosts: ITransportationCost[];
  showUpdateArchived?: boolean
}

const TransportationCostsTable: React.FC<IProps> = ({ transportationCosts, showUpdateArchived }) => {
  const navigate = useNavigate();
  const { archivedUpdateTransportationCost } = useTransportationCostService();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateTransportationCost(id);
      queryClient.setQueryData([QueryKey.transportationCosts], (transportationCosts: ITransportationCost[]) => 
        transportationCosts.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.transportationCost(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tehtaat</th>
          <th>Alku</th>
          <th>Loppu</th>
          <th>Hinta</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {transportationCosts.map((transportationCost) => (
          <tr
            key={transportationCost.id}
            onClick={() => openHandler(transportationCost.id)}
            style={{
              color: transportationCost.archived ? "gray" : "inherit",
              fontStyle: transportationCost.archived ? "italic" : "inherit",
            }}
          >
            <td>{transportationCost.factoriesString}</td>
            <td>{transportationCost.kilometerStart} km</td>
            <td>{transportationCost.kilometerEnd} km</td>
            <td>{transportationCost.price} €</td>
            <td>{transportationCost.updatedString}</td>
            {showUpdateArchived ?
            <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(transportationCost.id)} onClick={(event)=>archivedUpdateHandler(transportationCost.id, event)} icon={transportationCost.archived ? faEyeSlash : faEye} title={transportationCost.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            :
            <td>{transportationCost.archived && "arkistoitu"}</td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TransportationCostsTable;
