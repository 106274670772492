import { useCallback } from "react";
import {
  DraggingStyle,
  DragUpdate,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";

export type DraggableElement = { position?: number };
type DragItem<T> = T & { position?: number };

// a little function to help us with reordering the result
function reorder<T>(list: DragItem<T>[], startIndex: number, endIndex: number, propertyName: string) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return resetDisplayPositions(result, propertyName);
}

function resetDisplayPositions<T>(list: DragItem<T>[], propertyName: string) {
  return list.map((item, index) => ({ ...item, [propertyName]: index + 1 }));
}

const grid = 8;
const activeColor = "#fff";
const inactiveColor = "#ccc";

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //   padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? activeColor : inactiveColor,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? activeColor : inactiveColor,
  padding: grid,
  width: 250,
});

const disableScroll = () => {
  // Get the current page scroll position
  const x = window.pageXOffset || document.documentElement.scrollLeft;
  const y = window.pageYOffset || document.documentElement.scrollTop;
  // if any scroll is attempted,
  // set this to the previous value
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
};

const enableScroll = () => {
  window.onscroll = function () {};
};

export function useDragAndDrop<T>(
  items: DragItem<T>[],
  setItems: React.Dispatch<React.SetStateAction<DragItem<T>[]>>,
  propertyName = "position",
  isScrollDisabled = true,
) {
  const dragEndHandler = useCallback(
    (result: DropResult) => {
      if (isScrollDisabled) enableScroll();
      // dropped outside the list
      if (!result.destination) {
        setItems((items) =>
          items.map((item, index) => ({ ...item, [propertyName]: index + 1 }))
        );
        return;
      }
      if (result.destination.index === result.source.index) return;
      const newItems = reorder([...items], result.source.index, result.destination.index, propertyName);
      setItems(newItems);
      return newItems;
    },
    [isScrollDisabled, items, propertyName, setItems]
  );

  // https://itnext.io/dynamically-update-positions-during-drag-using-react-beautiful-dnd-4a986d704c2e
  const dragUpdateHandler = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) return;
      // if (result.destination.index === result.source.index) return;
      const newItems = [...items];
      const dragged = newItems[result.source.index];
      const previousDraggedIndex = (dragged as any)[propertyName];
      (dragged as any)[propertyName] = result.destination.index + 1;
      const draggedIndexDifference = (dragged as any)[propertyName] - (previousDraggedIndex || 0);
      // console.log(result.destination.index,result.source.index);
      const updatedContent = newItems.map((item, index) => {
        let position = (item as any)[propertyName];
        if (position === result.destination!.index + 1 && index !== result.source.index) {
          position -= draggedIndexDifference;
        }
        return { ...item, [propertyName]: position };
      });
      setItems(updatedContent);
    },
    [items, propertyName, setItems]
  );

  const dragStartHandler = useCallback(() => {
    if (isScrollDisabled) disableScroll();
  }, [isScrollDisabled]);

  return { dragEndHandler, dragUpdateHandler, dragStartHandler, items, getItemStyle, getListStyle };
}
