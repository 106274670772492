import IElement from "./IElement";

export enum EElementSlot {
  TODO = "TODO",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
}

interface IElementUnloading {
  slot: EElementSlot;
  elements: IElement[];
}

export default IElementUnloading;
