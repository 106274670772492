import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useTransportOrdersService } from "../../services/transportOrders-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { EFactory } from "../../shared/IOfferRow";
import ITransportOrder from "../../shared/ITransportOrder";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import WaybillsList from "../Waybills/WaybillsList";
import FormButtons from "../ui/FormButtons/FormButtons";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import PageHeading from "../ui/PageHeading/PageHeading";
import IWaybill from "../../shared/IWaybill";
import { updateInputHandler } from "../ui/Input/input-utils";

interface IProps {
  id: string;
  transportOrder?: ITransportOrder;
  factoryParam?: string | null;
}

enum EInputs {
  factory = "factory",
  notes = "notes",
  internalNotes = "internalNotes",
  projectId = "projectId",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
}

const TransportOrderEdit: React.FC<IProps> = ({ id, transportOrder, factoryParam }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();
  const redirectHandler = () => {
    return navigate(Route.transportOrders);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit, inputs, setInputs } = useTransportOrderInputs(transportOrder);

  useEffect(() => {
    if (factoryParam) {
      updateInputHandler(EInputs.factory, factoryParam, setInputs);
    }
  }, [factoryParam, setInputs]);

  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);
  const { options, loading, projects } = useProjectOptions({showProjectNumber: true, factory, factoryRequired: true});
  
  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      if (data.projectId) {
        data.projectNumber = projects.find(project => project.id === data.projectId)?.projectNumber ?? "";
      } else {
        data.projectNumber = undefined;
      }
      if (isEdit) {
        const waybills = queryClient.getQueryData([QueryKey.waybills, id]) as IWaybill[];
        if (waybills && waybills.length > 0) {
          data.tons = waybills.reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0).toFixed(2);
          data.count = waybills.reduce((sum, waybill) => sum + +(waybill.count ?? 0), 0);
        }
      }
      saveOrUpdate(data);
    }
  }, [id, isEdit, projects, saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  const addHandler = useCallback(() => {
    navigate(Route.waybill(`add?transportOrderId=${id}`));
  }, [id, navigate]);

  return (
    <>
      <InputGroup>
        {createInput(EInputs.factory)}
        {createInput(EInputs.projectId, { options, loading })}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.deliveryDate)}
        {createInput(EInputs.deliveryTime)}
      </InputGroup>
      {createInput(EInputs.notes)}
      {createInput(EInputs.internalNotes)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {isEdit && (
        <>
          <hr />
          <PageHeading variant="h3" onAdd={addHandler} noHorizontalMargin>Rahtikirjat</PageHeading>
          <WaybillsList transportOrderId={id} showTotalRow />
          <hr />
        </>
      )}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa kuljetustilauksen?"
      />
    </>
  );
};

const useTransportOrderInputs = (data?: ITransportOrder) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      value: "",
      options: FactoryOptions,
      validation: {
        required: true,
      },
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
      options: [],
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomautukset",
      value: "",
      rows: 4,
    },
    [EInputs.internalNotes]: {
      type: EInputType.textarea,
      label: "Muistiinpanot",
      value: "",
      rows: 4,
    },
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Kuljetuksen pvm",
      value: "",
    },
    [EInputs.deliveryTime]: {
      type: EInputType.time,
      label: "Kuljetuksen aika",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateTransportOrder, saveTransportOrder } = useTransportOrdersService();

  const mutationFn = (data: ITransportOrder) => {
    return isEdit ? updateTransportOrder(id, data) : saveTransportOrder(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<ITransportOrder, ErrorResponse, ITransportOrder>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.transportOrders, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrders] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrderOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteTransportOrder } = useTransportOrdersService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteTransportOrder(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrders], refetchType: "none" });
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrderOptions], refetchType: "none" });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default TransportOrderEdit;
