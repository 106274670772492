import { useQuery } from "@tanstack/react-query";
import IProductionLine from "../../../shared/IProductionLine";
import Table from "../../ui/Table/Table";
import { QueryKey } from "../../../services/query-keys";
import IElementDTO from "../../../shared/IElementDTO";
import { ErrorResponse } from "../../../custom-fetch";
import { getCurrentDay } from "../../../utils/date-utils";
import classes from './ProductionLinePrint.module.scss';
import Spinner from "../../ui/Spinner/Spinner";
import PrintCheckbox from "../../PrintCheckbox/PrintCheckbox";
import Container from "../../ui/Container/Container";
import IWireType from "../../../shared/IWireType";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";

interface IProps {
  productionLine: IProductionLine;
}

const ProductionLinePrint: React.FC<IProps> = ({ productionLine }) => {
  const { fetchProductionLineElementsByUuid } = usePublicQRCodesService();
  let totalLength = 0;
  let totalWeight = 0;
  const wireType = productionLine.wireType as IWireType

  const {
    data: elements,
    isLoading: elementsIsLoading,
    /*isError,
    error,*/
  } = useQuery<IElementDTO[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLine.uuid, "print"],
    queryFn: ({ signal }) =>
      fetchProductionLineElementsByUuid({ signal, id: productionLine.uuid! }),
    staleTime: 5000,
  });
  
  return (
    <Container style={{maxWidth: "1000px"}}>
      <div>
        <div>Peti nro: {productionLine.productionLineNumber}</div>
        <div>Valu pvm: {productionLine.startDateFin}</div>
        <div>Vaijerityyppi: {wireType?.name}</div>
        <div>Huom: {productionLine.notes}</div>
        <div>Pedin työmaat:</div>
        {productionLine.projects?.map((project) => {
          const uniquePhases = Array.from(new Set(
            (elements ?? [])
              .filter((element) => element.projectId === project.id)
              .map((element) => element.phaseName)
          )).join(", ");
          return (
            <div key={project.id}>
              {project.projectNumber} {uniquePhases && ("/ "+uniquePhases+" /")} {project.name}
            </div>
          );
        })}
      </div>
      {elementsIsLoading ? <Spinner/> :
      <Table className={classes.printTable}>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan={3}>Sijainti</th>
            <th colSpan={2}>Käänt/vaik</th>
            <th>Pituus</th>
            <th>Syve</th>
            <th>Va</th>
            <th>U</th>
            <th>N</th>
            <th>P</th>
            <th>Halk</th>
            <th>V</th>
            <th>O</th>
            <th>Paino</th>
          </tr>
        </thead>
        <tbody>
          {elements?.map((element) => {
            totalLength += +(element.length ?? 0);
            totalWeight += +(element.weightTons ?? 0);
            const provisionSummary = element.provisionSummary;
            return (
              <tr key={element.id}>
                <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.productionLineLength}</td>
                <td>{element.position}</td>
                <td style={{width: '8px'}}><PrintCheckbox checked={element.sideBySide}/></td>
                <td>{provisionSummary.hasSlanted && "vino"}</td>
                <td style={{width: '8px'}}><PrintCheckbox checked={element.overlapLeft || element.overlapRight}/></td>
                <td>{element.overlapLeft ? provisionSummary.slantedDistanceLeft : element.overlapRight ? provisionSummary.slantedDistanceRight : ""}</td>
                <td>{element.projectNumber}{element.phaseName && ("/"+element.phaseName)}</td>
                <td>{element.name}</td>
                <td>{element.length}</td>
                <td>{provisionSummary.countHollow || ""}</td>
                <td>{(provisionSummary.countHole + provisionSummary.countCircle + provisionSummary.countRectangle + provisionSummary.countOther + provisionSummary.countFill) || ""}</td>
                <td>{provisionSummary.countSlot || ""}</td>
                <td>{provisionSummary.countLiftingLoop || ""}</td>
                <td>{provisionSummary.countWireLoop || ""}</td>
                <td>{provisionSummary.hasSplit ? element.width : ""}</td>
                <td><PrintCheckbox checked={provisionSummary.hasLeft}/></td>
                <td><PrintCheckbox checked={provisionSummary.hasRight}/></td>
                <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.weightTons}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>{elements?.at(elements?.length-1)?.productionLineLengthTotal}</th>
            <th colSpan={5}>{getCurrentDay()}</th>
            <th colSpan={9}>{totalLength.toFixed(0)}</th>
            <th>{totalWeight.toFixed(2)}</th>
          </tr>
        </tfoot>
      </Table>
      }
    </Container>
  )
}

export default ProductionLinePrint;