import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import IElementsStatusDTO from "../../../shared/IElementsStatusDTO";
import PageHeading from "../../ui/PageHeading/PageHeading";

interface IProps {
  projectId: string;
}

const ElementStatusTable: React.FC<IProps> = ({ projectId }) => {
  const {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  } = useFetchElementStatus(projectId);

  if (isFetchElementsError) {
    return <ErrorsAlert errors={fetchElementsError!.messages} />;
  }

  if (isFetchingElements) {
    return <Spinner />;
  }

  if (!elements || (elements.elementsByProductType?.length === 0 && elements.elementsByPhaseId?.length === 0)) {
    return <p>Ei suunniteltuja elementtejä</p>;
  }

  return (
    <>
      <PageHeading variant="h3">Laattatyypeittäin</PageHeading>
      {elements.elementsByProductType.length > 0 ? (
        <Table containerStyles={{ padding: "0 1rem" }}>
          <thead>
            <tr>
              <th>Tuote</th>
              <th title="määrä tilauksessa">Myyty (kpl / m²)</th>
              <th title="suunniteltuja elementtejä">Tekemättä (kpl / m²)</th>
              <th>Tehty (kpl / m²)</th>
              <th>Toimitettu (kpl / m²)</th>
            </tr>
          </thead>
          <tbody>
            {elements.elementsByProductType?.map((element) => (
              <tr key={element.productType.id}>
                <td>{element.productType.name}</td>
                <td>{element.sold}kpl / {element.soldArea}m²</td>
                <td>{element.notDone}kpl / {element.notDoneArea}m²</td>
                <td>{element.done}kpl / {element.doneArea}m²</td>
                <td>{element.delivered}kpl / {element.deliveredArea}m²</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : <p style={{ margin: "1rem" }}>Ei elementtejä</p>}

      <PageHeading variant="h3">Kerroksittain ja laattatyypeittäin</PageHeading>
      {elements.elementsByPhaseId.length > 0 ? (
        <Table containerStyles={{ padding: "0 1rem" }}>
          <thead>
            <tr>
              <th>Kerros / lohko</th>
              <th>Tuote</th>
              <th title="määrä tilauksessa">Myyty (kpl / m²)</th>
              <th title="suunniteltuja elementtejä">Tekemättä (kpl / m²)</th>
              <th>Tehty (kpl / m²)</th>
              <th>Toimitettu (kpl / m²)</th>
            </tr>
          </thead>
          <tbody>
            {elements.elementsByPhaseId?.map((element) => (
              <tr key={`${element.phase?.phaseId}-${element.productType.id}`}>
                <td>{element.phase?.name}</td>
                <td>{element.productType.name}</td>
                <td>{element.sold}kpl / {element.soldArea}m²</td>
                <td>{element.notDone}kpl / {element.notDoneArea}m²</td>
                <td>{element.done}kpl / {element.doneArea}m²</td>
                <td>{element.delivered}kpl / {element.deliveredArea}m²</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : <p style={{ margin: "1rem" }}>Ei elementtejä</p>}
    </>
  );
};

const useFetchElementStatus = (projectId: string | null) => {
  const { fetchProjectElementStatus } = useProjectsService();

  const {
    data: elements,
    isPending: isFetchingElements,
    isError: isFetchElementsError,
    error: fetchElementsError,
  } = useQuery<IElementsStatusDTO, ErrorResponse>({
    queryKey: [QueryKey.elementsStatus, projectId],
    queryFn: ({ signal }) => fetchProjectElementStatus({ signal, id: projectId! }),
    staleTime: 5000,
    enabled: !!projectId,
  });

  return {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  };
};

export default ElementStatusTable;
