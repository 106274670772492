import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../custom-fetch";
import { useProductionLinesService } from "../../services/productionLines-service";
import { QueryKey } from "../../services/query-keys";
import IProductionLine from "../../shared/IProductionLine";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import ProductionLinesTable from "./ProductionLinesTable";
import { EFactory } from "../../shared/IOfferRow";

interface IProps {
  factory: EFactory;
}

const ProductionLinesList: React.FC<IProps> = ({ factory }) => {
  const { fetchProductionLines } = useProductionLinesService();

  const {
    data: productionLines,
    isLoading,
    isError,
    error,
  } = useQuery<IProductionLine[], ErrorResponse>({
    queryKey: [QueryKey.productionLines, factory],
    queryFn: ({ signal }) => fetchProductionLines({ signal, factory }),
    staleTime: 5000,
    enabled: !!factory,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!productionLines || productionLines.length === 0) {
    return <p>Ei petejä</p>;
  }

  return (
    <>
      <ProductionLinesTable productionLines={productionLines} />
    </>
  );
};

export default ProductionLinesList;
