import { useCallback } from "react";
import {
  DraggingStyle,
  DragUpdate,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import IElement from "../../shared/IElement";
import IElementUnloading from "../../shared/IElementUnloading";

const DEBUG = false;

const grid = 8;
const activeColor = "#fff";
const inactiveColor = "#ccc";

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? activeColor : inactiveColor,
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: inactiveColor,
  padding: grid,
});

const destructureDragData = (result: DragUpdate) => {
  const sourceSlot = result.source.droppableId;
  const sourceIndex = result.source.index;

  if (DEBUG) {
    console.log("sourceSlot", sourceSlot, "sourceIndex", sourceIndex);
  }

  const destinationSlot = result.destination!.droppableId;
  const destinationIndex = result.destination!.index;

  if (DEBUG) {
    console.log(
      "destinationSlot",
      destinationSlot,
      "destinationIndex",
      destinationIndex
    );
  }

  const slotMatch = sourceSlot === destinationSlot;
  const indexMatch = sourceIndex === destinationIndex;

  if (DEBUG) {
    console.log("slotMatch", slotMatch, "indexMatch", indexMatch);
  }

  return {
    sourceSlot,
    destinationSlot,
    sourceIndex,
    destinationIndex,
    slotMatch,
    indexMatch,
  };
};

const findItem = (
  items: IElementUnloading[],
  slot: string,
  sourceIndex: number
) => {
  return [...items].find((item) => item.slot === slot)?.elements[sourceIndex];
};

const findItems = (items: IElementUnloading[], slot: string) => {
  return [...items].find((item) => item.slot === slot)?.elements ?? [];
};

export function useElementUnloadingDragAndDrop(
  items: IElementUnloading[],
  setItems: React.Dispatch<React.SetStateAction<IElementUnloading[]>>
) {
  const dragEndHandler = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        setItems((items) =>
          items.map((item) => ({
            ...item,
            elements: item.elements.map((element, index) => ({
              ...element,
              position: index + 1,
              slot: item.slot,
            })),
          }))
        );
        return;
      }

      const {
        sourceSlot,
        destinationSlot,
        sourceIndex,
        destinationIndex,
        slotMatch,
        indexMatch,
      } = destructureDragData(result);

      if (slotMatch && indexMatch) return;

      const newItems = [...items];

      const source = findItems(newItems, sourceSlot);

      let destination: IElement[] = [];
      if (slotMatch) {
        if (DEBUG) {
          console.log("destination === source");
        }
        destination = source;
      } else {
        destination = findItems(newItems, destinationSlot);
      }

      const [removed] = source.splice(sourceIndex, 1);
      destination.splice(destinationIndex, 0, removed);

      const updatedItems = newItems.map((item) => ({
        ...item,
        elements: item.slot === "TODO" ? item.elements : item.elements.map((element, index, arr) => ({
          ...element,
          position: arr.length > 0 ? arr.length - (index ?? 0) : 1,
          slot: item.slot,
        })),
      }));

      setItems(updatedItems);
      if (slotMatch) {
        if (DEBUG) {
          console.log("slotMatch, return destination");
        }
        return findItems(updatedItems, destinationSlot);
      }
      return [
        ...findItems(updatedItems, sourceSlot),
        ...findItems(updatedItems, destinationSlot),
      ];
    },
    [items, setItems]
  );

  const dragUpdateHandler = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) return;

      const { sourceSlot, sourceIndex, destinationSlot, destinationIndex } = destructureDragData(result);

      const newItems = [...items];
      const dragged = findItem(newItems, sourceSlot, sourceIndex);
      if (!dragged) {
        console.error("dragged NOT FOUND");
        return;
      }

      const previousDraggedIndex = dragged.position;
      dragged.position = destinationIndex + 1;
      const draggedIndexDifference = dragged.position - (previousDraggedIndex || 0);

      const updatedContent = newItems.map((item) => ({
        ...item,
        elements: item.slot === destinationSlot ? item.elements.map((element, index, arr) => {
          let position = element.position;
          if (position === destinationIndex + 1 && index !== sourceIndex) {
            position -= draggedIndexDifference;
          }
          return {
            ...element,
            position,
            slot: item.slot,
          };
        }) : item.elements,
      }));

      setItems(updatedContent);
    },
    [items, setItems]
  );

  return {
    dragEndHandler,
    dragUpdateHandler,
  };
}
