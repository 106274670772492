import { useMutation, useQuery } from "@tanstack/react-query";
import IProductionLine from "../../../shared/IProductionLine";
import Table from "../../ui/Table/Table";
import { useElementsService } from "../../../services/elements-service";
import { QueryKey } from "../../../services/query-keys";
import IElementDTO from "../../../shared/IElementDTO";
import { ErrorResponse } from "../../../custom-fetch";
import { getCurrentDay, getCurrentDayString } from "../../../utils/date-utils";
import imgLogo from '../../../assets/logo.png';
import classes from './ProductionLinePrint.module.scss';
import Spinner from "../../ui/Spinner/Spinner";
import OfferHeader from "../../Offers/OfferHeader/OfferHeader";
import PrintCheckbox from "../../PrintCheckbox/PrintCheckbox";
import IWireType from "../../../shared/IWireType";
import { useAttachmentsService } from "../../../services/attachments-service";
import { numToRoman } from "../../../utils/string-utils";
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { adminUiUrl } from "../../../config";
import Button from "../../ui/Button/Button";

interface IProps {
  productionLine: IProductionLine;
}

const ProductionLinePrint: React.FC<IProps> = ({ productionLine }) => {
  const { fetchProductionLineElements } = useElementsService();
  const { downloadLatestAttachment } = useAttachmentsService();
  let totalLength = 0;
  let totalWeight = 0;
  const wireType = productionLine.wireType as IWireType

  const {
    data: elements,
    isLoading: elementsIsLoading,
    /*isError,
    error,*/
  } = useQuery<IElementDTO[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLine.id, "print"],
    queryFn: ({ signal }) =>
      fetchProductionLineElements({ signal, productionLineId: productionLine.id }),
    staleTime: 5000,
  });

  const {
    data: attachment,
    isLoading: isDownloading,
    /*isError: isDownloadError,
    error: downloadError,*/
  } = useQuery<Blob, ErrorResponse>({
    queryKey: [QueryKey.latestAttachments, "WireType", wireType?.id],
    queryFn: ({ signal }) => downloadLatestAttachment({ signal, kind: "WireType", parentId: wireType.id }),
    staleTime: 5000,
    enabled: !!wireType?.id,
  });

  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (attachment) {
      setObjectUrl(URL.createObjectURL(attachment));
    }
  }, [attachment]);
  
  useEffect(() => {
    if (objectUrl) {
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [objectUrl]);

  const { downloadMergedAttachmentsMutate } = useProductionLineAttachments(productionLine.id!);
  const [mergedLoading, setMergedLoading] = useState(false);

  const downloadAttachmentsHandler = useCallback(async () => {
    setMergedLoading(true);
    try {
      const attachment = await downloadMergedAttachmentsMutate();
      const objectUrl = URL.createObjectURL(attachment);
      window.open(objectUrl, "_blank");
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error(error);
    }
    setMergedLoading(false);
  }, [downloadMergedAttachmentsMutate]);
  
  return (
    <div className={classes.container}>
      <OfferHeader
        onSubmit={() => window.print()}
        submitText="Tulosta"
        isLoading={false}
        title="Tulosta"
      >
        {productionLine.hasAttachments && <Button onClick={downloadAttachmentsHandler} loading={mergedLoading}>Lataa liitteet</Button>}
      </OfferHeader>
      <div className={classes.header}>
        <div style={{width: "20%"}}>
          <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "2rem" }} />
        </div>
        <div style={{width: "10%"}}>
          <div><b>Peti</b></div>
        </div>
        <div style={{width: "10%"}}>
          <div><b>{productionLine.productionLineNumber}</b></div>
        </div>
        <div style={{width: "20%"}}>
          <div><b>{productionLine.brushed && "Harjattu"}</b></div>
        </div>
        <div style={{width: "18%", fontSize: "medium"}}>
          <div>Mitattu venymä:</div>
        </div>
        <div style={{width: "18%", borderStyle: "solid", borderWidth: "1px", marginBottom: "0.5rem"}}/>
      </div>

      <div className={classes.row}>
        <div style={{width: "30%"}}>
          <div className={classes.row}>
            <div style={{width: "50%"}}>
              <div>Valu pvm:</div><br/>
              <div>Järjestys:</div>
              <div>Vaijerityyppi:</div>
              <div>Tyyppi:</div>
            </div>
            <div style={{width: "50%"}}>
              <div>{productionLine.startDateFin}<br/>{getCurrentDayString(productionLine.startDate)}</div>
              <div><b>{productionLine.position && numToRoman(productionLine.position)}</b></div>
              <div><b>{wireType?.name}</b></div>
              <div>{wireType?.slabType?.typeName}</div>
            </div>
          </div>
        </div>
        <div style={{width: "40%", paddingRight: "0.5rem"}}>
          {isDownloading ? <Spinner /> : attachment && objectUrl && <img style={{maxWidth: "100%"}} src={objectUrl} alt={"liite"} />}
        </div>
        <div style={{width: "30%"}}>
          <div>Pedin työmaat:</div>
          {productionLine.projects?.map((project) => {
            const uniquePhases = Array.from(new Set(
              (elements ?? [])
                .filter((element) => element.projectId === project.id)
                .map((element) => element.phaseName)
            )).join(", ");

            return (
              <div key={project.id}>
                {project.projectNumber} {uniquePhases && ("/ "+uniquePhases+" /")} {project.name}
              </div>
            );
        })}
        </div>
      </div>

      <div className={classes.row}>
        <div style={{width: "30%", paddingTop: '15px'}}>
          Huom: {productionLine.notes}
        </div>
        <div style={{width: "10%", paddingLeft: "0.5rem", border: 'thin lightgray solid', maxHeight: '70px'}}>
          <div>Paksut</div>
          <div style={{marginLeft: "1rem", fontSize: '2em'}}><b>{wireType?.tendonCountThick}</b></div>
        </div>
        <div style={{width: "10%", border: 'thin lightgray solid', textAlign: 'center', maxHeight: '70px'}}>
          <div>Ohuet</div>
          <div style={{marginLeft: "0.4rem", fontSize: '2em'}}><b>{wireType?.tendonCountThin}</b></div>
        </div>
        <div style={{paddingLeft: '15px', width: "50%", fontStyle: 'italic', color: '#666'}}>
          <div className={classes.row}>
            <div style={{width: "40%"}}>
              <div>Kone 840 OKU</div>
              <div>Vyyhti 1</div>
              <div>Vyyhti 2</div>
            </div>
            <div style={{width: "5%", fontWeight: "bolder"}}>
              <br/>
              <div>O</div>
              <div>P</div>
              <div>R</div>
            </div>
            <div style={{width: "25%", textAlign: "right"}}>
              <div>Jännitys</div>
              <div>900</div>
              <div>1000</div>
              <div>0</div>
            </div>
            <div style={{width: "25%", textAlign: "right"}}>
              <div>Kone lkm</div>
              <div>1,3756</div>
              <div>2,912</div>
            </div>
            <div style={{width: "5%"}}>
            </div>
          </div>
        </div>
      </div>
      {elementsIsLoading ? <Spinner/> :
      <Table className={classes.printTable}>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan={3}>Sijainti</th>
            <th colSpan={2}>Käänt/vaik</th>
            <th>Pituus</th>
            <th>Syve</th>
            <th>Va</th>
            <th>U</th>
            <th>N</th>
            <th>P</th>
            <th>Halk</th>
            <th>V</th>
            <th>O</th>
            <th>Paino</th>
          </tr>
        </thead>
        <tbody>
          {elements?.map((element) => {
            totalLength += +(element.length ?? 0);
            totalWeight += +(element.weightTons ?? 0);
            const provisionSummary = element.provisionSummary;
            return (
              <tr key={element.id}>
                <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.productionLineLength}</td>
                <td>{element.position}</td>
                <td style={{width: '8px'}}><PrintCheckbox checked={element.sideBySide}/></td>
                <td>{provisionSummary.hasSlanted && "vino"}</td>
                <td style={{width: '8px'}}><PrintCheckbox checked={element.overlapLeft || element.overlapRight}/></td>
                <td>{element.overlapLeft ? provisionSummary.slantedDistanceLeft : element.overlapRight ? provisionSummary.slantedDistanceRight : ""}</td>
                <td>{element.projectNumber}{element.phaseName && ("/"+element.phaseName)}</td>
                <td>{element.name}</td>
                <td>{element.length}</td>
                <td>{provisionSummary.countHollow || ""}</td>
                <td>{(provisionSummary.countHole + provisionSummary.countCircle + provisionSummary.countRectangle + provisionSummary.countOther + provisionSummary.countFill) || ""}</td>
                <td>{provisionSummary.countSlot || ""}</td>
                <td>{provisionSummary.countLiftingLoop || ""}</td>
                <td>{provisionSummary.countWireLoop || ""}</td>
                <td>{provisionSummary.hasSplit ? element.width : ""}</td>
                <td><PrintCheckbox checked={provisionSummary.hasLeft}/></td>
                <td><PrintCheckbox checked={provisionSummary.hasRight}/></td>
                <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.weightTons}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>{elements?.at(elements?.length-1)?.productionLineLengthTotal}</th>
            <th colSpan={5}>{getCurrentDay()}</th>
            <th colSpan={9}>{totalLength.toFixed(0)}</th>
            <th>{totalWeight.toFixed(2)}</th>
          </tr>
        </tfoot>
      </Table>
      }
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <QRCode style={{height: "96px", width: "96px", marginLeft: "auto", marginTop: "0.5rem"}} value={`${adminUiUrl}/qr/production-line/${productionLine.uuid}`}></QRCode>
      </div>
    </div>
  )
}

const useProductionLineAttachments = (productionLineId: string) => {
  const { downloadLatestAttachment } = useAttachmentsService();

  const {
    mutateAsync: downloadMergedAttachmentsMutate,
  } = useMutation<Blob, ErrorResponse>({
    mutationFn: () => downloadLatestAttachment({ kind: "ProductionLine", parentId: productionLineId }),
  });

  return { downloadMergedAttachmentsMutate };
}

export default ProductionLinePrint;
