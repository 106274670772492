import { useCallback } from "react";
import { ISignal, useCustomFetch } from "../custom-fetch";
import IAttachment from "../shared/IAttachment";
import IElementsBatchResponseDTO from "../shared/IElementsBatchResponseDTO";
import IElement from "../shared/IElement";
import { Kind } from "./kind";

interface IFetchAttachments extends ISignal {
  kind: Kind;
  parentId: string;
}

interface IGetAttachment extends ISignal {
  kind: Kind;
  id: string;
}

export const useAttachmentsService = () => {
  const customFetch = useCustomFetch();

  const fetchAttachments = useCallback(
    async ({ signal, kind, parentId }: IFetchAttachments) => {
      let url = `/api/attachments/list/${kind}/${parentId}`;
      const [attachments] = await customFetch<IAttachment[]>(url, { signal });
      return attachments;
    },
    [customFetch]
  );

  const getAttachment = useCallback(
    async ({ signal, kind, id }: IGetAttachment) => {
      const [attachment] = await customFetch<IAttachment>(
        `/api/attachments/get/${kind}/${id}`,
        { signal }
      );
      return attachment;
    },
    [customFetch]
  );

  const saveAttachment = useCallback(
    async (kind: string, parentId: string, file: File) => {
      const data = new FormData();
      data.append("file", file);
      const [attachment] = await customFetch<IAttachment>(
        `/api/attachments/add/${kind}/${parentId}`,
        {
          method: "POST",
          body: data,
          multipart: true,
        }
      );
      return attachment;
    },
    [customFetch]
  );

  const deleteAttachment = useCallback(
    async (kind: string, id: string) => {
      const [attachment] = await customFetch<IAttachment>(`/api/attachments/delete/${kind}/${id}`, {
        method: "DELETE",
      });
      return attachment;
    },
    [customFetch]
  );

  const downloadAttachment = useCallback(
    async ({ signal, kind, id }: IGetAttachment) => {
      const [attachment] = await customFetch<Blob>(
        `/api/attachments/download/${kind}/${id}`,
        { signal, responseType: "blob" },
      );
      return attachment;
    },
    [customFetch]
  );

  const downloadLatestAttachment = useCallback(
    async ({ signal, kind, parentId }: IFetchAttachments) => {
      const [attachment] = await customFetch<Blob>(
        `/api/attachments/latest/${kind}/${parentId}`,
        { signal, responseType: "blob" },
      );
      return attachment;
    },
    [customFetch]
  );

  const uploadBatchAttachments = useCallback(async (projectId: string, files: File[]) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append("files", file);
    }
    const [batchResponse] = await customFetch<IElementsBatchResponseDTO>(`/api/attachments/batch-upload/${projectId}`, { method: "POST", body: data, multipart: true });
    return batchResponse;
  }, [customFetch]);

  const copyBatchAttachments = useCallback(async (projectId: string, data: IElementsBatchResponseDTO) => {
    const [elements] = await customFetch<IElement[]>(`/api/attachments/batch-copy/${projectId}`, { method: "POST", body: JSON.stringify(data) });
    return elements;
  }, [customFetch]);

  return { fetchAttachments, getAttachment, saveAttachment, deleteAttachment, downloadAttachment, downloadLatestAttachment, uploadBatchAttachments, copyBatchAttachments };
};
