import { useCallback } from 'react';
import { IOfferRowCalculationConcrete } from '../components/Offers/OfferRowCalculation/OfferRowCalculationConcretes/OfferRowCalculationConcrete';
import { IOfferRowCalculationHollowcore } from '../components/Offers/OfferRowCalculation/OfferRowCalculationHollowcores/OfferRowCalculationHollowcore';
import { IOfferRowCalculationInsulation } from '../components/Offers/OfferRowCalculation/OfferRowCalculationInsulations/OfferRowCalculationInsulations';
import { IOfferRowCalculationMesh } from '../components/Offers/OfferRowCalculation/OfferRowCalculationMeshes/OfferRowCalculationMeshes';
import { IOfferRowCalculationSteelPart } from '../components/Offers/OfferRowCalculation/OfferRowCalculationSteelParts/OfferRowCalculationSteelParts';
import { IOfferRowCalculationSteel } from '../components/Offers/OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteels';
import { IOffersSearch } from '../components/Offers/OffersSearch';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IOffer from "../shared/IOffer";
import IOfferRow, { IOfferRowCalculationTotal, IOfferRowCalculationTotalRequest } from '../shared/IOfferRow';
import { IOfferRowCalculationWork } from '../components/Offers/OfferRowCalculation/OfferRowCalculationWork/OfferRowCalculationWork';
import { IOfferCalculationKilometer } from '../components/Offers/OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer';
import { IInstallations } from '../components/Offers/OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations';
import { IOfferCalculationSummary } from '../components/Offers/OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary';
import { IOfferRowCalculationSteelTendon } from '../components/Offers/OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteelTendon';

interface IFetchOffers extends ISignal {
  search?: IOffersSearch | null;
}

interface IGetOffer extends ISignal {
  id: string;
}

export interface ISendOffer {
  data: IOffer;
  projectNumber?: string;
}

export const useOfferService = () => {
  const customFetch = useCustomFetch();

  const fetchOffers = useCallback(async ({ signal, search }: IFetchOffers) => {
    const [offers] = await customFetch<IOffer[]>("/api/offers/list", {
      signal,
      method: "POST",
      body: JSON.stringify(search),
    });
    return offers;
  }, [customFetch]);

  const fetchOffersCustomer = useCallback(async ({ signal, search }: IFetchOffers) => {
    const [offers] = await customFetch<IOffer[]>("/api/offers/list-customer", {
      signal,
      method: "POST",
      body: JSON.stringify(search),
    });
    return offers;
  }, [customFetch]);
  
  const getOffer = useCallback(async ({ signal, id }: IGetOffer) => {
    const [offer] = await customFetch<IOffer>("/api/offers/get/" + id, { signal });
    return offer;
  }, [customFetch]);
  
  const saveOffer = useCallback(async (data: IOffer) => {
    const [offer] = await customFetch<IOffer>("/api/offers/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);
  
  const updateOffer = useCallback(async (id: string, data: IOffer) => {
    const [offer] = await customFetch<IOffer>("/api/offers/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);
  
  const deleteOffer = useCallback(async (id: string) => {
    await customFetch<IOffer>("/api/offers/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const calculateOfferInsulation = useCallback(async (data: IOfferRowCalculationInsulation) => {
    const [offer] = await customFetch<IOfferRowCalculationInsulation>("/api/offers/calculate/insulation", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferSteel = useCallback(async (data: IOfferRowCalculationSteel) => {
    const [offer] = await customFetch<IOfferRowCalculationSteel>("/api/offers/calculate/steel", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);
  
  const calculateOfferSteelTendon = useCallback(async (data: IOfferRowCalculationSteelTendon) => {
    const [offer] = await customFetch<IOfferRowCalculationSteelTendon>("/api/offers/calculate/steel-tendon", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferSteelPart = useCallback(async (data: IOfferRowCalculationSteelPart) => {
    const [offer] = await customFetch<IOfferRowCalculationSteelPart>("/api/offers/calculate/steel-part", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferMesh = useCallback(async (data: IOfferRowCalculationMesh) => {
    const [offer] = await customFetch<IOfferRowCalculationMesh>("/api/offers/calculate/mesh", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferConcrete = useCallback(async (data: IOfferRowCalculationConcrete) => {
    const [offer] = await customFetch<IOfferRowCalculationConcrete>("/api/offers/calculate/concrete", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferHollowcore = useCallback(async (data: IOfferRowCalculationHollowcore) => {
    const [offer] = await customFetch<IOfferRowCalculationHollowcore>("/api/offers/calculate/hollowcore", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferTotal = useCallback(async (data: IOfferRowCalculationTotalRequest) => {
    const [offer] = await customFetch<IOfferRowCalculationTotal>("/api/offers/calculate/total", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferWork = useCallback(async (data: IOfferRowCalculationWork) => {
    const [offer] = await customFetch<IOfferRowCalculationWork>("/api/offers/calculate/work", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferKilometer = useCallback(async (data: IOfferCalculationKilometer) => {
    const [offer] = await customFetch<IOfferCalculationKilometer>("/api/offers/calculate/kilometer", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferKilometers = useCallback(async (data: IOfferCalculationKilometer[]) => {
    const [offer] = await customFetch<IOfferCalculationKilometer[]>("/api/offers/calculate/kilometers", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferInstallations = useCallback(async (data: IInstallations) => {
    const [offer] = await customFetch<IInstallations>("/api/offers/calculate/installations", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferRow = useCallback(async (data: IOfferRow) => {
    const [offer] = await customFetch<IOfferRow>("/api/offers/calculate/row", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferSummary = useCallback(async (data: { oldSummary: IOfferCalculationSummary, newSummary: Partial<IOfferCalculationSummary>, installationDeclaredPrice: string }) => {
    const [offer] = await customFetch<IOfferCalculationSummary>("/api/offers/calculate/summary", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const calculateOfferSummaryRows = useCallback(async (rows: IOfferRow[], installationDeclaredPrice: string) => {
    const [offer] = await customFetch<IOfferCalculationSummary>("/api/offers/calculate/summary-rows", {
      method: "PUT",
      body: JSON.stringify({
        rows,
        installationDeclaredPrice,
      }),
    });
    return offer;
  }, [customFetch]);

  const sendOffer = useCallback(async (id: string, data: IOffer, projectNumber?: string) => {
    let url = `/api/offers/send-order/${id}`;
    if (projectNumber) url += `?projectNumber=${projectNumber}`;
    const [offer] = await customFetch<IOffer>(url, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offer;
  }, [customFetch]);

  const copyOfferRow = useCallback(async (id: string, rowId: string) => {
    const [offerRow] = await customFetch<IOfferRow>(`/api/offers/copy-row/${id}?rowId=${rowId}`, {
      method: "PUT",
    });
    return offerRow;
  }, [customFetch]);

  const copyOffer = useCallback(async ({ signal, id }: IGetOffer) => {
    const [offer] = await customFetch<IOffer>("/api/offers/copy/" + id, { signal });
    return offer;
  }, [customFetch]);
  
  const updateOfferPrices = useCallback(async (offer: IOffer) => {
    const [newOffer] = await customFetch<IOffer>(`/api/offers/update-prices`, {
      method: "PUT",
      body: JSON.stringify(offer),
    });
    return newOffer;
  }, [customFetch]);

  const getLatest5OfferNumbers = useCallback(async ({ signal }: ISignal) => {
    const [offerNumbers] = await customFetch<string>("/api/offers/latest", { signal });
    return offerNumbers;
  }, [customFetch]);

  const archivedUpdateOffer = useCallback(async (id: string) => {
    const [offer] = await customFetch<IOffer>("/api/offers/update-archived/" + id, {
      method: "PUT",
    });
    return offer;
  }, [customFetch]);
  
  return { fetchOffers, fetchOffersCustomer, getOffer, saveOffer, updateOffer, deleteOffer, calculateOfferInsulation, calculateOfferSteel, calculateOfferSteelTendon, calculateOfferSteelPart, calculateOfferMesh, calculateOfferConcrete, calculateOfferHollowcore, calculateOfferTotal, calculateOfferWork, calculateOfferKilometer, calculateOfferKilometers, calculateOfferInstallations, calculateOfferRow, calculateOfferSummary, calculateOfferSummaryRows, sendOffer, copyOfferRow, copyOffer, updateOfferPrices, getLatest5OfferNumbers, archivedUpdateOffer };
}
