import { faQrcode, faRoute } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { adminUiUrl } from "../../config";
import { ErrorResponse } from "../../custom-fetch";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { useTransportOrderOptions } from "../../hooks/useTransportOrderOptions";
import { Route } from "../../routes";
import { useElementsService } from "../../services/elements-service";
import { QueryKey } from "../../services/query-keys";
import { useWaybillsService } from "../../services/waybills-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import IElement from "../../shared/IElement";
import { EFactory } from "../../shared/IOfferRow";
import IWaybill, { DriverOrganizationOptions, EWaybillType, IWaybillRow, WAITING_REASON_OPTIONS, WAYBILL_TYPE_OPTIONS } from "../../shared/IWaybill";
import { formatDateTime } from "../../utils/date-utils";
import WaybillElementUnloadingDragAndDropTable from "../ElementUnloading/WaybillElementUnloadingDragAndDropTable";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonSize } from "../ui/Button/Button";
import Container from "../ui/Container/Container";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import PageHeading from "../ui/PageHeading/PageHeading";
import Spinner from "../ui/Spinner/Spinner";

interface IProps {
  id: string;
  waybill?: IWaybill;
  projectId?: string | null;
  transportOrderId?: string | null;
}

enum EInputs {
  projectId = "projectId",
  projectNumber = "projectNumber",
  destination = "destination",
  driverOrganization = "driverOrganization",
  driver = "driver",
  car = "car",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  deliveryPrice = "deliveryPrice",
  waitingTime = "waitingTime",
  waitingPrice = "waitingPrice",
  waitingReason = "waitingReason",
  kilometers = "kilometers",
  accepted = "accepted",
  price = "price",
  tons = "tons",
  tonsInvoice = "tonsInvoice",
  count = "count",
  notes = "notes",
  additionalNotes = "additionalNotes",
  factory = "factory",
  orderNumber = "orderNumber",
  nakki = "nakki",
  contactPerson = "contactPerson",
  contactInfo = "contactInfo",
  type = "type",
}

const WaybillEdit: React.FC<IProps> = ({ id, waybill, projectId: paramProjectId, transportOrderId }) => {
  const isEdit = id !== "add";

  const navigate = useNavigate();
  const redirectHandler = () => {
    return navigate(Route.waybills);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit, inputs, setInputs } = useWaybillInputs(waybill);

  const { transportOrders } = useTransportOrderOptions();

  useEffect(() => {
    if (transportOrderId) {
      const transportOrder = transportOrders.find(transportOrder => transportOrder.id === transportOrderId);
      if (transportOrder) {
        setInputs(inputs => {
          const newInputs = {...inputs};
          newInputs[EInputs.factory].value = transportOrder.factory ?? "";
          newInputs[EInputs.notes].value = transportOrder.notes ?? "";
          newInputs[EInputs.projectId].value = transportOrder.projectId ?? "";
          newInputs[EInputs.deliveryDate].value = transportOrder.deliveryDate ?? "";
          newInputs[EInputs.deliveryTime].value = transportOrder.deliveryTime ?? "";
          return newInputs;
        });
      }
    }
  }, [setInputs, transportOrderId, transportOrders]);

  const [waybillRows, setWaybillRows] = useState<IWaybillRow[]>([]);

  useEffect(() => {
    if (waybill?.waybillRows) {
      setWaybillRows(waybill.waybillRows);
    }
  }, [waybill?.waybillRows]);

  const projectId = paramProjectId ?? inputs[EInputs.projectId].value as string;
  const factory = inputs[EInputs.factory].value as string;

  const { projects, options: projectOptions, loading: projectOptionsLoading } = useProjectOptions({ showProjectNumber: true, factory, factoryRequired: true });

  const waitingTime = inputs[EInputs.waitingTime].value as string ?? "0";
  const waitingPrice = inputs[EInputs.waitingPrice].value as string ?? "0";
  const waitingTotal = (+waitingTime * +waitingPrice).toFixed(2);

  const project = useMemo(() => projects?.find((p) => p.id.toString() === projectId.toString()), [projectId, projects]);

  const projectNumber = project?.projectNumber;
  const projectName = project?.name;
  const { elements, isElementsLoading, isElementsError, elementsError } = useListElements(factory as EFactory, projectId);

  const tons = useMemo(() => waybillRows.reduce((sum, row) => {
      const element = elements?.find(e => e.id === row.elementId);
      const weightTons = parseFloat(element?.weightTons ?? "0");
      return sum + weightTons;
  }, 0), [elements, waybillRows]);

  const count = waybillRows.length;

  const kilometerCalculation = project?.offer?.kilometerCalculations?.find((kc) => kc.factory === factory);
  const deliveryPrice = +(kilometerCalculation?.offerFreightPrice ?? 0);
  const price = (deliveryPrice * tons).toFixed(2);
  const kilometers = kilometerCalculation?.freightKilometers;

  const contactPerson = project?.contactPerson??"";
  const contactInfo = project?.contactInfo??"";
  const orderNumber = project?.offer.customerReferenceNumber??"";

  const destination = [
    project?.offer.deliveryStreetAddress, 
    project?.offer.deliveryZip, 
    project?.offer.deliveryCity
  ].filter(Boolean).join(", ");

  useEffect(() => {
    if (paramProjectId && project) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.projectId].value = paramProjectId;
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        newInputs[EInputs.contactPerson].value = contactPerson;
        newInputs[EInputs.contactInfo].value = contactInfo;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramProjectId, project]);

  useEffect(() => {
    if (projectId && waybill?.projectId !== projectId) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        newInputs[EInputs.contactPerson].value = contactPerson;
        newInputs[EInputs.contactInfo].value = contactInfo;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleSubmitData = useCallback(async (redirectHandler?: () => void) => {
    const data = await submit();
    if (data) {
      data.kilometers = kilometers ?? "0";
      data.projectNumber = projectNumber ?? "";
      data.projectName = projectName ?? "";
      data.deliveryPrice = deliveryPrice.toString();
      data.price = price;
      data.waybillRows = projectId && factory ? waybillRows : [];
      data.count = count.toString();
      data.tons = tons.toString();
      data.transportOrderId = transportOrderId ?? "";
      saveOrUpdate(data, { onSuccess: redirectHandler });
    }
  }, [count, deliveryPrice, factory, kilometers, price, projectId, projectName, projectNumber, saveOrUpdate, submit, tons, transportOrderId, waybillRows]);

  const submitHandler = useCallback(async () => {
    await handleSubmitData();
  }, [handleSubmitData]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const waybillPrintHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrint(waybill!.uuid));
    });
  };

  const waybillPrintElementsHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrintElements(waybill!.uuid))
    });
  };

  const openGoogleMapsHandler = (latitude?: number, longitude?: number) => {
      if (!latitude || !longitude) {
        return;
      }

      const url = `http://maps.google.com/?q=${latitude.toString()},${longitude.toString()}&ll=${latitude.toString()},${longitude.toString()}&z=14`
      window.open(url, "_blank");
  }

  const openQRHandler = () => {
    if (waybill?.uuid) {
      const url = `${adminUiUrl}/qr/waybill/${waybill.uuid}`;
      window.open(url, "_blank");
    }
  }

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isDeletingError, error: deletingError },
    { isError: isElementsError, error: elementsError },
  );

  const deliveryDate = useMemo(() => inputs[EInputs.deliveryDate].value as string, [inputs]);

  const customDriverOrganizationOption = waybill?.driverOrganization ? { label: waybill?.driverOrganization, value: waybill?.driverOrganization } : undefined;
  const allDriverOrganizationOptions = customDriverOrganizationOption ? [...DriverOrganizationOptions, customDriverOrganizationOption] : [...DriverOrganizationOptions];

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>
          {waybill?.prevWaybillId ? <span onClick={() => navigate(Route.waybill(waybill?.prevWaybillId!))} style={{cursor: "pointer"}}>{"<"}</span> : ""}
            Rahtikirja {waybill?.waybillId}
          {waybill?.nextWaybillId ? <span onClick={() => navigate(Route.waybill(waybill?.nextWaybillId!))} style={{cursor: "pointer"}}>{">"}</span> : ""}
        </PageHeading>
        <FormButtons
          onSubmit={submitHandler}
          isLoading={isPending}
          onDelete={isEdit ? deleteMutate : undefined}
          isDeleting={isDeleting}
          deleteConfirmMessage="Haluatko varmasti poistaa rahtikirjan?"
          style={{ marginTop: "1rem", fontSize: "initial" }}
        >
          {isEdit && 
          <>
            <Button loading={isPending} onClick={waybillPrintHandler}>Rahtikirja</Button>
            <Button loading={isPending} onClick={waybillPrintElementsHandler}>Lähetyslista</Button>
            {waybill?.uuid && <Button onClick={openQRHandler} icon={faQrcode} title="QR"/>}
          </>
          }
        </FormButtons>
      </div>
    <Container>
      <div style={{ display: "flex", width: "1500px" }}>
        <div style={{ width: "500px", paddingRight: "20px" }}>
          {createInput(EInputs.factory)}
          {createInput(EInputs.projectId, {options: projectOptions, loading: projectOptionsLoading})}
          {createInput(EInputs.driverOrganization, {options: allDriverOrganizationOptions})}
          {/*createInput(EInputs.waitingTime)*/}
          {createInput(EInputs.type)}
          {createInput(EInputs.tons)}
          <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <span>Paino: {tons.toFixed(2)} t</span><br/>
            <span>Kappaleet: {count}</span><br/>
            <span>Kilometrit: {kilometers} km</span><br/>
            <span>Kuljetus hinta: {deliveryPrice} €/ton</span><br/>
            <span>Hinta: {price} €</span><br/><br/>
            <span>Odotuksen kulu: {waitingTotal} €</span><br/>
            <span>Lastaus päättynyt: {waybill?.loadEndTime}</span><br/>
            <span>Saapunut klo: {waybill?.arriveTime}</span><br/>
            <span>Purku alkanut: {waybill?.unloadStartTime}</span><br/>
            <span>Lähtenyt klo: {waybill?.departTime}</span><br/>
            <span>Odotus aika h: {waybill?.waitingTime}</span><br/>
            <span>Odotuksen syy: {waybill?.waitingReasonString}</span>
          </div>
          {createInput(EInputs.accepted)}
          {createInput(EInputs.nakki)}
        </div>
        <div style={{ width: "500px"}}>
          {createInput(EInputs.destination)}
          {createInput(EInputs.deliveryDate)}
          {createInput(EInputs.driver)}
          {/*createInput(EInputs.waitingReason)*/}
          {createInput(EInputs.tonsInvoice)}
          {createInput(EInputs.contactPerson)}
          {createInput(EInputs.notes)}
        </div>
        <div style={{ width: "500px", paddingLeft: "20px" }}>
          {createInput(EInputs.orderNumber)}
          {createInput(EInputs.deliveryTime)}
          {createInput(EInputs.car)}
          {createInput(EInputs.waitingPrice)}
          {createInput(EInputs.count)}
          {createInput(EInputs.contactInfo)}
          {createInput(EInputs.additionalNotes)}
        </div>
      </div>

      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}

      {isElementsLoading ? (
          <Spinner />
        ) : factory && projectId ? (
          <WaybillElementUnloadingDragAndDropTable
            initialElements={elements ?? []}
            waybillRows={waybillRows}
            setWaybillRows={setWaybillRows}
            waybillDeliveryDate={deliveryDate ?? ""}
          />
      ) : null}

      {waybill?.locations &&
      <div style={{marginTop: "3rem"}}>
        <h3>QR skannausten sijainnit</h3>
        {waybill?.locations.map((location, index) => (
          <div key={index} style={{display: "flex", marginBottom: "1rem"}}>
            <Button onClick={()=>openGoogleMapsHandler(location.latitude, location.longitude)} icon={faRoute} size={EButtonSize.X_SMALL}/>
            <div style={{paddingTop: '10px', paddingLeft: '10px'}}>{formatDateTime(location.created)}, Leveys: {location.latitude}, Pituus: {location.longitude}, Tarkkuus: {location.accuracy?.toFixed(0)} m</div>
          </div>
        ))}
      </div>
      }
    </Container>
    </>
  );
};

const useWaybillInputs = (data?: IWaybill) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
    },
    [EInputs.projectNumber]: {
      type: EInputType.text,
      label: "Työmaanumero",
      value: "",
    },
    [EInputs.destination]: {
      type: EInputType.text,
      label: "Sijainti",
      value: "",
    },
    [EInputs.driverOrganization]: {
      type: EInputType.reactSelect,
      label: "Kuljettaja liike",
      value: "",
      isCreatable: true,
    },
    [EInputs.driver]: {
      type: EInputType.text,
      label: "Kuski",
      value: "",
    },
    [EInputs.car]: {
      type: EInputType.text,
      label: "Auto",
      value: "",
    },
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Pvm",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.deliveryTime]: {
      type: EInputType.time,
      label: "Aika",
      value: "",
    },
    [EInputs.waitingTime]: {
      type: EInputType.number,
      label: "Odotukset, tuntia",
      value: "",
    },
    [EInputs.waitingPrice]: {
      type: EInputType.number,
      label: "Odotuksen hinta € /h",
      value: "",
    },
    [EInputs.waitingReason]: {
      type: EInputType.reactSelect,
      label: "Odotuksen syy",
      value: "",
      options: WAITING_REASON_OPTIONS
    },
    [EInputs.accepted]: {
      type: EInputType.checkbox,
      label: "Hyväksytty",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.tonsInvoice]: {
      type: EInputType.number,
      label: "Tonnit laskulta",
      value: "",
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomautukset",
      value: "",
      rows: 4,
    },
    [EInputs.additionalNotes]: {
      type: EInputType.textarea,
      label: "Lisä huom",
      value: "",
      rows: 4,
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      value: "",
      options: FactoryOptions,
    },
    [EInputs.orderNumber]: {
      type: EInputType.text,
      label: "Tilausnumero",
      value: "",
    },
    [EInputs.nakki]: {
      type: EInputType.checkbox,
      label: "nakki",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.contactPerson]: {
      type: EInputType.text,
      label: "Yhteyshenkilö",
      value: "",
    },
    [EInputs.contactInfo]: {
      type: EInputType.text,
      label: "Yhteystieto",
      value: "",
    },
    [EInputs.type]: {
      type: EInputType.reactSelect,
      label: "Siirto",
      value: EWaybillType.PROJECT,
      options: WAYBILL_TYPE_OPTIONS,
      validation: {
        required: true,
      },
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean) => {
  const { updateWaybill, saveWaybill } = useWaybillsService();
  const navigate = useNavigate();

  const mutationFn = (data: IWaybill) => {
    return isEdit ? updateWaybill(id, data) : saveWaybill(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IWaybill, ErrorResponse, IWaybill>({
    mutationFn,
    onSuccess: (data) => {
      console.log(data)
      queryClient.removeQueries({ queryKey: [QueryKey.waybills, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.waybills] });
      if (data.projectId && data.waybillRows) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.elements, data.projectId] });
      }
      if (!isEdit) navigate(Route.waybill(data.id), { replace: true });
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteWaybill } = useWaybillsService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteWaybill(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.waybills],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useListElements = (factory: EFactory, projectId: string) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isLoading,
    isError,
    error: elementsError,
    // refetch,
    isRefetching,
    isRefetchError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, factory, projectId],
    queryFn: ({ signal }) => fetchElements({ signal, projectId, search: { isPlanned: true, productionLineFactory: factory } }),
    staleTime: 5000,
    enabled: !!factory && !!projectId,
  });

  // console.log("useListElements", factory, projectId, elements);

  return { elements, isElementsLoading: isLoading || isRefetching, isElementsError: isError || isRefetchError, elementsError };
};

export default WaybillEdit;
