import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { useWaybillsSearch } from "./useWaybillsSearch";
import { formatDate } from "../../utils/date-utils";

interface IProps {
  projectId?: string;
  transportOrderId?: string;
  showTotalRow?: boolean;
}

const WaybillsList: React.FC<IProps> = ({ projectId, transportOrderId, showTotalRow }) => {
  const { isError, error, isLoading, waybills } = useWaybillsSearch({ projectId, transportOrderId });

  const navigate = useNavigate();

  const openHandler = (id: string) => {
    navigate(Route.waybill(id));
  };

  const openPrintHandler = (id: string, elements: boolean, event: React.MouseEvent) => {
    event.stopPropagation();
    if (elements) {
      navigate(Route.waybillPrintElements(id));
    } else {
      navigate(Route.waybillPrint(id));
    }
  };

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!waybills || waybills.length === 0) {
    return <p>Ei rahtikirjoja</p>;
  }

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Tehdas</th>
            <th>Numero</th>
            <th>Työmaa</th>
            <th>Sijainti</th>
            <th>Pvm</th>
            <th>Klo</th>
            <th>Km</th>
            <th>Kuljettaja</th>
            <th>Auto</th>
            <th>Tonnit</th>
            <th>Kpl</th>
            <th>Huomautus</th>
            <th>Tulostus</th>
          </tr>
        </thead>
        <tbody>
          {waybills.map((waybill) => (
            <tr key={waybill.id} onClick={() => openHandler(waybill.id)}>
              <td>{waybill.factory}</td>
              <td>{waybill.waybillId}</td>
              <td>{waybill.projectNumber}</td>
              <td>{waybill.destination}</td>
              <td>{formatDate(waybill.deliveryDate)}</td>
              <td>{waybill.deliveryTime}</td>
              <td>{waybill.kilometers}</td>
              <td>{waybill.driverOrganization}</td>
              <td>{waybill.car}</td>
              <td>{waybill.tons && (+waybill.tons).toFixed(2)}</td>
              <td>{waybill.count}</td>
              <td>{waybill.notes}</td>
              <td>
                <p>
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(waybill.uuid, false, event)}>Rahtikirja</span> {" / "}
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(waybill.uuid, true, event)}>Lähetyslista</span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
        {showTotalRow && waybills.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={8}></td>
              <td>Yhteensä</td>
              <td>{waybills.reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0).toFixed(2)}</td>
              <td>{waybills.reduce((sum, waybill) => sum + +(waybill.count ?? 0), 0)}</td>
              <td colSpan={2}></td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default WaybillsList;
