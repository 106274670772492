import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import IProductGroup from "../../shared/IProductGroup";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useProductGroupService } from "../../services/productGroups-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ProductGroupsList: React.FC = () => {
  const { fetchProductGroups, archivedUpdateProductGroup } = useProductGroupService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateProductGroup(id);
      queryClient.setQueryData([QueryKey.productGroups], (productGroups: IProductGroup[]) => 
        productGroups.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.productGroup(id));
  };

  const {
    data: productGroups,
    isPending,
    isError,
    error,
  } = useQuery<IProductGroup[], ErrorResponse>({
    queryKey: [QueryKey.productGroups],
    queryFn: fetchProductGroups,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!productGroups || productGroups.length === 0) {
    return <p>Ei tuoteryhmiä</p>;
  }

  const filteredProductGroups = productGroups.filter(
    (productGroup) =>
      productGroup.name?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="productGroupsFilter"
        type={EInputType.text}
        placeholder="Suodata nimellä"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Asennushinta</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredProductGroups.map((productGroup) => (
            <tr
              key={productGroup.id}
              onClick={() => openHandler(productGroup.id)}
              style={{
                color: productGroup.archived ? "gray" : "inherit",
                fontStyle: productGroup.archived ? "italic" : "inherit",
              }}
            >
              <td>{productGroup.name}</td>
              <td>{productGroup.installationCost && productGroup.installationCost+" €"}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(productGroup.id)} onClick={(event)=>archivedUpdateHandler(productGroup.id, event)} icon={productGroup.archived ? faEyeSlash : faEye} title={productGroup.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProductGroupsList;
