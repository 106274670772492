import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useProductionLinesService } from "../../../services/productionLines-service";
import IElement from "../../../shared/IElement";
import { IWaybillRow } from "../../../shared/IWaybill";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import PageHeading from "../../ui/PageHeading/PageHeading";
import ProductionLineElementUnloadingDragAndDropTable from "../../ElementUnloading/ProductionLineElementUnloadingDragAndDropTable";
import ProductionLineElementWarehouseDragAndDropTable from "../../ElementUnloading/ProductionLineElementWarehouseDragAndDropTable";

interface IProps {
  productionLineElements: IElement[];
  unloadingRows: IWaybillRow[];
  setUnloadingRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  warehouseRows: IWaybillRow[];
  setWarehouseRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  castingRows: IWaybillRow[];
  setCastingRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionLineUnloading: React.FC<IProps> = ({ productionLineElements, unloadingRows, setUnloadingRows, warehouseRows, setWarehouseRows, castingRows, setCastingRows, setIsDirty }) => {
  const { createUnloadingOrderMutate, isPending, isError, error } = useCreateUnloadingOrder();

  const createUnloadingOrderHandler = useCallback(async () => {
    try {
      const newRows = await createUnloadingOrderMutate(warehouseRows);
      setUnloadingRows(structuredClone(newRows));
      setCastingRows(structuredClone(newRows));
      setIsDirty(true);
    } catch (error) {
      console.error(error);
    }
  }, [createUnloadingOrderMutate, setCastingRows, setIsDirty, setUnloadingRows, warehouseRows]);

  const errorMessages = combineErrors({ isError, error });

  const isWarehouseDone = useMemo(() => productionLineElements?.length === warehouseRows?.length, [productionLineElements.length, warehouseRows.length]);
    
  return (
    <>
      <hr />
      <PageHeading variant="h3" noHorizontalMargin>2. Varastoon vienti järjestys</PageHeading>
      <ProductionLineElementWarehouseDragAndDropTable
        initialElements={productionLineElements}
        warehouseRows={warehouseRows}
        setWarehouseRows={setWarehouseRows}
        setIsDirty={setIsDirty}
      />
      {isWarehouseDone && <Button style={{ marginTop: "1rem" }} onClick={createUnloadingOrderHandler} loading={isPending}>Luo purkujärjestys</Button>}
      {unloadingRows.length > 0 && (
        <>
          <hr />
          {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
          <ProductionLineElementUnloadingDragAndDropTable
            elements={productionLineElements}
            unloadingRows={unloadingRows}
            castingRows={castingRows}
            setCastingRows={setCastingRows}
            setIsDirty={setIsDirty}
          />
        </>
      )}
    </>
  );
};

const useCreateUnloadingOrder = () => {
  const { createUnloadingOrder } = useProductionLinesService();

  const {
    mutateAsync: createUnloadingOrderMutate,
    isPending,
    isError,
    error,
  } = useMutation<IWaybillRow[], ErrorResponse, IWaybillRow[]>({
    mutationFn: (data) => createUnloadingOrder(data),
  });

  return { createUnloadingOrderMutate, isPending, isError, error };
};

export default ProductionLineUnloading;
