import { useQuery } from "@tanstack/react-query";
import { Params, useParams, useSearchParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import TransportOrderEdit from "../components/TransportOrders/TransportOrderEdit";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useTransportOrdersService } from "../services/transportOrders-service";
import ITransportOrder from "../shared/ITransportOrder";
import { useMemo } from "react";

const TransportOrderPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getTransportOrder } = useTransportOrdersService();

  const [searchParams] = useSearchParams();
  const factory = useMemo(() => searchParams.get("factory"), [searchParams]);

  const {
    data: transportOrder,
    isLoading,
    isError,
    error,
  } = useQuery<ITransportOrder, ErrorResponse>({
    queryKey: [QueryKey.transportOrders, id],
    queryFn: ({ signal }) => getTransportOrder({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Kuljetustilaus {transportOrder?.transportOrderNumber}</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <TransportOrderEdit id={id!} transportOrder={transportOrder} factoryParam={factory} />
        )}
      </Container>
    </>
  );
};

export default TransportOrderPage;
