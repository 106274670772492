import { useCallback } from "react";
import { useProjectsService } from "../../services/projects-service";
import { IProjectPhase } from "../../shared/IProject";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ProjectPhaseEditModal from "./ProjectPhaseEditModal";

export const useProjectPhaseEditModal = (id: string) => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.PROJECT_PHASE_EDIT),
    [closeModal]
  );

  const { saveProjectPhase } = useProjectsService();

  const handler = (): Promise<IProjectPhase | null> => {
    let title = "Lisää kerros / lohko";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.PROJECT_PHASE_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        // backdropNotClose: true,
        content: (
          <ProjectPhaseEditModal
            onAccept={async (data) => {
              try {
                const phase = await saveProjectPhase(id, data);
                // console.log(organization);
                resolve(phase);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
          />
        ),
      });
    });
  };

  return handler;
};
