import { useCallback, useEffect, useState } from "react";
import { useProductGroupOptions } from "../../hooks/useProductGroupOptions";
import { useProductTypeOptions } from "../../hooks/useProductTypeOptions";
import { useOrganizationService } from "../../services/organizations-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { STATUS_OPTIONS } from "../../shared/IOffer";
import IOrganization from "../../shared/IOrganization";
import { storage } from "../../utils/localstorage-utils";
import { IOrganizationsSearch } from "../Organizations/OrganizationsSearch";
import Button from "../ui/Button/Button";
import { EInputType, IInputField, TFetchOption } from "../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../ui/Input/input-utils";
import { useCreateInput } from "../ui/Input/useCreateInput";
import { useOffersSearch } from "./useOffersSearch";

export interface IOffersSearch {
  customerId?: string;
  customerName?: string;
  status?: string;
  offerDateStart?: string;
  offerDateEnd?: string;
  productGroupId?: string;
  productTypeId?: string;
  search?: string;
  factory?: string;
}

enum EInputs {
  customerId = "customerId",
  status = "status",
  offerDateStart = "offerDateStart",
  offerDateEnd = "offerDateEnd",
  productGroupId = "productGroupId",
  productTypeId = "productTypeId",
  search = "search",
  factory = "factory",
}

interface IProps {
  customerId?: string | null;
  customerName?: string | null;
}

const OffersSearch: React.FC<IProps> = ({ customerId, customerName }) => {
  const { search, setSearch, isLoading, refetch } = useOffersSearch(customerId);
  const { createInput, submit } = useInputs(search);
  //const { options, loading } = useOrganizationOptions("CUSTOMER");
  const { options: productGroupOptions, loading: productGroupsloading } = useProductGroupOptions();
  const { options: productTypeOptions, loading: productTypesloading } = useProductTypeOptions();
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const { fetchOrganizations } = useOrganizationService();

  const fetchOptions: TFetchOption = useCallback(async (input, signal) => {
    const search: IOrganizationsSearch = {type: "CUSTOMER", name: input};
    const fetchedOrganizations = await fetchOrganizations({ signal, search });
    setOrganizations(fetchedOrganizations);
    return fetchedOrganizations.map((organization: IOrganization) => ({
      value: organization.id,
      label: organization.name,
    }));
  }, [fetchOrganizations]);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      const selectedOrganization = organizations?.find(o => o.id === data.customerId);
      data.customerName = selectedOrganization?.name || search?.customerName;
      data.search = data.search?.trim();
      setSearch(data);
    }
  };

  useEffect(() => {
    if (!isLoading && search) {
      refetch();
      storage.saveOffersSearch(search);
    }
    // eslint-disable-next-line
  }, [search, refetch]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="Container" style={{ width: "80%"}}>
        <div style={{ display: "flex", gap: "1rem" }}>
          {createInput(EInputs.offerDateStart, { containerStyles: { width: "300px" }})}
          {createInput(EInputs.offerDateEnd, { containerStyles: { width: "300px" }})}
          {createInput(EInputs.customerId, { fetchOptions, containerStyles: { width: "340px" }, options: customerId && customerName ? [{value: customerId, label: customerName}] : search?.customerId && search?.customerName ? [{value: search.customerId, label: search.customerName}] : []})}
          {createInput(EInputs.status, { containerStyles: { width: "300px" }})}
          {createInput(EInputs.factory, { containerStyles: { width: "300px" }})}
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          {createInput(EInputs.search, { containerStyles: { width: "340px" }})} 
          {createInput(EInputs.productGroupId, { options: productGroupOptions, loading: productGroupsloading, containerStyles: { width: "340px" }})}
          {createInput(EInputs.productTypeId, { options: productTypeOptions, loading: productTypesloading, containerStyles: { width: "340px" }})}
        </div>
      </div>

      <Button loading={isLoading} onClick={submitHandler} type="submit">
        Hae
      </Button>
    </form>
  );
};

const useInputs = (search?: IOffersSearch | null) => {
  const [showValidation, setShowValidation] = useState(false);
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.customerId]: {
      type: EInputType.reactAsyncSelect,
      label: "Asiakas",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.status]: {
      type: EInputType.reactSelect,
      label: "Tila",
      options: STATUS_OPTIONS,
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.offerDateStart]: {
      type: EInputType.date,
      label: "Pvm alku",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.offerDateEnd]: {
      type: EInputType.date,
      label: "Pvm loppu",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.productGroupId]: {
      type: EInputType.reactSelect,
      label: "Tuoteryhmä",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.productTypeId]: {
      type: EInputType.reactSelect,
      label: "Tuote/TR2",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.search]: {
      type: EInputType.text,
      label: "Haku",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "",
      boldContent: true
    },
  });

  useEffect(() => {
    if (search) {
      initForm(setInputs, search);
    }
  }, [search]);

  const createInput = useCreateInput(inputs, setInputs, { showValidation });

  const submit = async () => {
    const isValid = await validateInputs(setInputs);
    if (isValid) {
      return getInputData<IOffersSearch>(inputs);
    }
    setShowValidation(true);
    return null;
  };

  return { createInput, submit };
};

export default OffersSearch;
