import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useAttachmentsService } from "../../services/attachments-service";
import IElement from "../../shared/IElement";
import IElementsBatchResponseDTO from "../../shared/IElementsBatchResponseDTO";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import BatchImportModal from "./BatchImportModal";
import { useProjectEditContext } from "../Projects/ProjectEdit/project-edit-context";

export const useBatchImportModal = (projectId: string) => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.BATCH_IMPORT),
    [closeModal]
  );

  const { uploadMutate } = useBatchUpload(projectId);
  const { copyMutate } = useBatchCopy(projectId);

  const handler = (): Promise<IElementsBatchResponseDTO | null> => {
    let title = "Lataa liitteet";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.BATCH_IMPORT,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <BatchImportModal
            onAccept={async (data) => {
              try {
                if (data.dtos) {
                  await copyMutate(data);
                }
                resolve(data);
                closeModalHandler();
                return data;
              } catch (error) {
                console.error(error);
                return { error: error as ErrorResponse };
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            onUpload={async (data) => {
              try {
                return await uploadMutate(data);
              } catch (error) {
                console.error(error);
                return { error: error as ErrorResponse };
              }
            }}
          />
        ),
      });
    });
  };

  return handler;
};

const useBatchUpload = (projectId: string) => {
  const { uploadBatchAttachments } = useAttachmentsService();
  
  const {
    mutateAsync: uploadMutate,
    isPending: isUploading,
    isError: isUploadError,
    error: uploadError,
  } = useMutation<IElementsBatchResponseDTO, ErrorResponse, File[]>({
    mutationFn: (files) => uploadBatchAttachments(projectId, files),
  });

  return { uploadMutate, isUploading, isUploadError, uploadError };
};

const useBatchCopy = (projectId: string) => {
  const { copyBatchAttachments } = useAttachmentsService();
  const { setElements } = useProjectEditContext();
  
  const {
    mutateAsync: copyMutate,
    isPending: isCopying,
    isError: isCopyError,
    error: copyError,
  } = useMutation<IElement[], ErrorResponse, IElementsBatchResponseDTO>({
    mutationFn: (data) => copyBatchAttachments(projectId, data),
    onSuccess: (data) => {
      setElements(elements => elements.map(el => {
        const findElement = data.find(element => el.id === element.id);
        if (findElement) return { ...el, hasAttachments: findElement?.hasAttachments, isPlanned: findElement?.isPlanned };
        return el;
      }));
    }
  });

  return { copyMutate, isCopying, isCopyError, copyError };
};
