import { useCallback } from "react";
import { ISendOffer } from "../../services/offers-service";
import IOffer from "../../shared/IOffer";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ProjectAddModal from "./ProjectAddModal";

export const useProjectAddModal = (sendOrder: (variables: ISendOffer) => Promise<IOffer>) => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.PROJECT_ADD),
    [closeModal]
  );

  const handler = (offer: IOffer): Promise<boolean> => {
    let title = "Tee työmaa";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.PROJECT_ADD,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        // backdropNotClose: true,
        content: (
          <ProjectAddModal
            onAccept={async (data) => {
              try {
                await sendOrder({ data: { ...offer }, projectNumber: data.projectNumber });
                // console.log(organization);
                resolve(true);
                closeModalHandler();
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(false);
            }}
          />
        ),
      });
    });
  };

  return handler;
};
