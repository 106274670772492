import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import Modal from "./Modal";

export enum EModalId {
  DEFAULT,
  CONFIRM,
  OFFER_ROW_COPY,
  DIALOG,
  NAVIGATION_BLOCKER,
  ORGANIZATION_EDIT,
  USER_EDIT,
  OFFER_ROW_IMPORT,
  OFFER_ROW_IMPORT_RESULT,
  OFFER_UPDATE_PRICES,
  PROJECT_PHASE_EDIT,
  PROVISIONS_EDIT,
  ELEMENTS_CREATE,
  ELEMENTS_UPDATE,
  ELEMENTS_COPY_PROVISIONS,
  AI_UPLOAD,
  ELIPLAN_UPLOAD,
  BATCH_IMPORT,
  PROJECT_ADD,
}

export enum EModalSize {
  FULL,
  LARGE,
  MEDIUM,
  SMALL,
  X_SMALL,
}

export interface IModal {
  id: EModalId;
  content: ReactNode;
  isOpen: boolean;
  title?: string;
  size: EModalSize;
  loading?: boolean;
  error?: string | null;
  disabled?: boolean;
  onModalClose?: () => void;
  noScroll?: boolean;
  overflow?: "auto" | "visible";
  backdropNotClose?: boolean;
}

export const defaultModal: IModal = {
  id: EModalId.DEFAULT,
  content: null,
  isOpen: false,
  title: "",
  size: EModalSize.SMALL,
  loading: false,
  disabled: false,
  onModalClose: () => {},
  overflow: "auto",
  backdropNotClose: false,
};

export const ModalContext = createContext<{
  addModal: (modal: IModal) => void;
  closeModal: (id: EModalId, animate?: boolean) => void;
  updateModal: (id: number, modal: Partial<IModal>) => void;
}>({
  addModal: (modal: IModal) => {},
  closeModal: () => {},
  updateModal: () => {},
});

interface IProps {
  children: ReactNode;
}

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider: React.FC<IProps> = ({ children }) => {
  const [modals, setModals] = useState<IModal[]>([]);

  const closeModal = useCallback((id: EModalId, animate: boolean = true) => {
    setModals((prevModals) => {
      if (animate) {
        return prevModals.map((modal) =>
          modal.id === id ? { ...modal, isOpen: false } : modal
        );
      } else {
        return prevModals.filter((modal) => modal.id !== id);
      }
    });
  }, []);

  const addModal = useCallback((modal: IModal) => {
    setModals((prevModals) => {
      const newModals = [...prevModals];
      const index = newModals.findIndex((m) => m.id === modal.id);
      if (index !== -1) {
        newModals[index] = modal;
      } else {
        newModals.push(modal);
      }
      return newModals;
    });
  }, []);

  const updateModal = useCallback((id: EModalId, modal: Partial<IModal>) => {
    setModals((prevModals) => {
      return prevModals.map((prevModal) =>
        prevModal.id === id ? { ...prevModal, ...modal } : prevModal
      );
    });
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, closeModal, updateModal }}>
      {modals.map((modal) => (
        <Modal key={modal.id} modal={modal} />
      ))}
      {children}
    </ModalContext.Provider>
  );
};
