import IProject from "../../../shared/IProject";
import Attachments from "../../Attachments/Attachments";
import Button, { EButtonColor } from "../../ui/Button/Button";
import PageHeading from "../../ui/PageHeading/PageHeading";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";

interface IProps {
  project: IProject;
}

const ProjectAttachments: React.FC<IProps> = ({ project }) => {
  const { deleteHandler, isDeleting } = useProjectEditContext();
  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin>Työmaan liitteet</PageHeading>
      <Attachments kind="Project" parentId={project.id} noScroll />
      <hr />
      <PageHeading variant="h3" noHorizontalMargin>Tilauksen liitteet</PageHeading>
      <Attachments kind="Offer" parentId={project.offer.id} uploadDisabled noScroll />
      {deleteHandler && (
        <>
          <PageHeading variant="h3" noHorizontalMargin>Työmaan poistaminen</PageHeading>
          <Button onClick={deleteHandler} loading={isDeleting} color={EButtonColor.DANGER}>Poista</Button>
        </>
      )}
    </>
  );
};

export default ProjectAttachments;
