import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { Route } from "../../../routes";
import { useOrganizationService } from "../../../services/organizations-service";
import { QueryKey } from "../../../services/query-keys";
import IOrganization from "../../../shared/IOrganization";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import OrganizationUserSelect from "../../OrganizationUserSelect/OrganizationUserSelect";
import Container from "../../ui/Container/Container";
import { EInputs } from "../Offer/Offer";
import SummaryTable from "../OfferCalculationTabs/OfferCalculationSummary/SummaryTable";
import OfferCalculationTabKilometers from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometers";
import OfferCalculationTabs from "../OfferCalculationTabs/OfferCalculationTabs";
import OfferCalculationTabsContextProvider from "../OfferCalculationTabs/offer-calculation-tabs-context";
import OfferHeader from "../OfferHeader/OfferHeader";
import OfferProducts from "../OfferProducts/OfferProducts";
import OfferProductsContextProvider from "../OfferProducts/offer-products-context";
import OfferTextTabs from "../OfferTextTabs/OfferTextTabs";
import classes from "./OfferEdit.module.scss";
import { useOfferEditContext } from "./offer-edit-context";
import Spinner from "../../ui/Spinner/Spinner";

const containerStyles: CSSProperties = { width: "200px" };
const containerStylesGroup: CSSProperties = { width: "100px" };

const OfferEdit: React.FC = () => {
  const {
    submitHandler,
    isPending,
    isEdit,
    deleteMutate,
    isDeleting,
    updateOfferPricesHandler,
    createOrderHandler,
    copy,
    offer,
    offerPrintModeHandler,
    offerInstalmentTableModeHandler,
    id,
    errorMessages,
    createInput,
    latest5OfferNumbers,
    isLatest5Pending,
    sellerUserOptions,
    sellerUserOptionsLoading,
    showOrderFields,
    customerRef,
    customerId,
    engineerRef,
    offerKilometers,
    setOfferKilometers,
    kilometersRef,
    openGoogleMapsHandler,
    summary,
    setSummary,
    installations,
    setInstallations,
    offerRows,
    setOfferRows,
    offerRowsRef,
    currentOfferRow,
    setCurrentOfferRow,
    updateCurrentRow,
    updateConditionalTerms,
    currentDeliveryPriceForTon,
    offerTerms,
    setOfferTerms,
    planningNotes,
    setPlanningNotes,
    overwriteDescription,
    setOverwriteDescription
  } = useOfferEditContext();
  const navigate = useNavigate();
  const { getOrganization } = useOrganizationService();
  const {
    data: customer,
    isPending: customerLoading,
  } = useQuery<IOrganization, ErrorResponse>({
    queryKey: [QueryKey.customers, customerId],
    queryFn: ({ signal }) => getOrganization({ signal, id: customerId! }),
    enabled: !!customerId,
  });

  return (
    <>
      <OfferHeader
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        isEdit={isEdit}
        onUpdatePrices={updateOfferPricesHandler}
        onCreateOrder={createOrderHandler}
        onCopy={!copy ? () => navigate(Route.offerCopy(id!)) : undefined}
        offerNumber={offer?.offerNumber}
        projectNumber={offer?.projectNumber}
        onPrint={offerPrintModeHandler}
        onInstalmentTable={offerInstalmentTableModeHandler}
        isCopy={copy}
        isWon={offer?.status === "WON"}
        onReturn={() => navigate(Route.home)}
        title="Tarjous"
      />
      <Container>
        {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
        <>
          <div className={classes.Offer}>
            <div>
              <div className={classes.Inputs}>
                <div className={classes.OfferInputs}>
                  {/* vasen */}
                  <div>
                    {createInput(EInputs.offerNumber, {
                      containerStyles,
                      title: latest5OfferNumbers,
                      loading: isLatest5Pending,
                    })}
                    {createInput(EInputs.seller, {
                      options: sellerUserOptions,
                      loading: sellerUserOptionsLoading,
                      containerStyles,
                    })}
                    <div className={classes.Group}>
                      {createInput(EInputs.offerDate, {
                        containerStyles: { width: "140px" },
                      })}
                      {createInput(EInputs.expireInDays, {
                        containerStyles: { width: "60px" },
                      })}
                    </div>
                    {createInput(EInputs.status, { containerStyles })}
                    {/*createInput(EInputs.archived, { containerStyles })*/}
                    {/* {createInput(EInputs.deliveryLocation, { containerStyles })} */}
                  </div>
                  {/* oikea */}
                  <div>
                    {createInput(EInputs.targetName, { containerStyles })}
                    {/* {createInput(EInputs.targetName2, { containerStyles })} */}
                    {createInput(EInputs.deliveryStreetAddress, {
                      containerStyles,
                    })}
                    <div className={classes.Group}>
                      {createInput(EInputs.deliveryZip, {
                        containerStyles: containerStylesGroup,
                      })}
                      {createInput(EInputs.deliveryCity, {
                        containerStyles: containerStylesGroup,
                      })}
                    </div>
                    {showOrderFields &&
                      createInput(EInputs.customerReferenceNumber, {
                        containerStyles,
                      })}
                    <div className={classes.Group}>
                      {showOrderFields &&
                        createInput(EInputs.estimatedDeliveryWeeks, {
                          containerStyles: containerStylesGroup,
                        })}
                    </div>
                  </div>
                </div>

                <div>
                  <div className={classes.Selects}>
                    {customerId && customerLoading ? <Spinner /> : (
                      <OrganizationUserSelect
                        type="CUSTOMER"
                        label="Asiakas"
                        ref={customerRef}
                        initialData={{
                          organization: customer ?? offer?.customer,
                          organizationId: customerId ?? (offer?.customer?.id || ""),
                          userId: offer?.customerContactPerson?.id || "",
                        }}
                      />
                    )}

                    {showOrderFields && (
                      <OrganizationUserSelect
                        type="ENGINEER"
                        label="Suunnittelutoimisto"
                        ref={engineerRef}
                        initialData={{
                          organization: offer?.engineer,
                          organizationId: offer?.engineer?.id ?? "",
                          userId: offer?.engineerContactPerson?.id ?? "",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/*createInput(EInputs.sellerNotes, {
            containerStyles: { width: "800px" },
          })*/}
              {createInput(EInputs.deliveryNotes, {
                containerStyles: { width: "800px" },
              })}
              {createInput(EInputs.sellerNotes, {
                containerStyles: { width: "800px" },
              })}
            </div>

            <OfferCalculationTabsContextProvider
              value={{
                offerKilometers,
                setOfferKilometers,
                openGoogleMapsHandler,
                summary,
                setSummary,
                installations,
                setInstallations,
                offerRows,
                setOfferRows,
              }}
            >
              <OfferCalculationTabs />
              <div className={classes.CalculationTabsRight}>
                <OfferCalculationTabKilometers ref={kilometersRef} />
                <SummaryTable summary={summary} />
              </div>
            </OfferCalculationTabsContextProvider>
          </div>

          <OfferProductsContextProvider
            value={{
              offerRows,
              setOfferRows,
              currentOfferRow,
              setCurrentOfferRow,
              updateCurrentRow,
              updateConditionalTerms,
              currentDeliveryPriceForTon,
              summary,
              setSummary,
              installations,
              setInstallations,
              offerKilometers,
              overwriteDescription,
              setOverwriteDescription,
              showControls: true,
              showPrices: true,
            }}
          >
            <OfferProducts ref={offerRowsRef} />
          </OfferProductsContextProvider>

          <OfferTextTabs
            offerTerms={offerTerms}
            setOfferTerms={setOfferTerms}
            planningNotes={planningNotes}
            setPlanningNotes={setPlanningNotes}
            showOrderFields={showOrderFields}
          />

        {/*  <div style={{ marginTop: "2rem" }}>
            {createInput(EInputs.archived, { containerStyles })}
          </div>
          <FormButtons
        style={{ marginTop: "1rem" }}
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa tarjouksen?"
      >
        {isEdit && <Button onClick={updateOfferPricesHandler} loading={isUpdatingPrices}>Päivitä hinnat</Button>}
        {createOrderHandler && <Button onClick={createOrderHandler}>Tee tilaus</Button>}
        {isEdit && <Button onClick={()=>navigate(Route.offerCopy(id))}>Kopioi pohjaksi</Button>}
      </FormButtons> */}
        </>
      </Container>
    </>
  );
};

export default OfferEdit;
