import { useEffect, useMemo, useState } from "react";
import WeeklyProductionRowsWeeksTable from "../components/Projects/WeeklyProductionRows/WeeklyProductionRowsWeeksTable";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { FactoryOptions } from "../shared/FactoryOptions";
import { EFactory } from "../shared/IOfferRow";
import { storage } from "../utils/localstorage-utils";

enum EInputs {
  factory = "factory",
}

const WeeklyProductionPage: React.FC = () => {
  const { createInput, inputs } = useWeeklyProductionInputs();
  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);

  useEffect(() => {
    if (factory) {
      storage.saveSearchFactory(factory);
    }
  }, [factory]);

  return (
    <>
      <PageHeading>Suunnittelu viikottain</PageHeading>
      <Container>
        {createInput(EInputs.factory)}
        <hr />
        {factory && <WeeklyProductionRowsWeeksTable factory={factory} />}
      </Container>
    </>
  );
};

const useWeeklyProductionInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: storage.getSearchFactory() ?? "HOL",
      placeholder: "",
      boldContent: true,
    },
  });

  const { createInput } = useInputs({ inputs, setInputs, data });

  return { createInput, inputs };
};

export default WeeklyProductionPage;
