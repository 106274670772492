import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ITransportOrder from '../shared/ITransportOrder';

interface IFetchTransportOrders extends ISignal {
  factory?: string;
}

interface IGetTransportOrder extends ISignal {
  id: string;
}

export const useTransportOrdersService = () => {
  const customFetch = useCustomFetch();

  const fetchTransportOrders = useCallback(async ({ signal, factory }: IFetchTransportOrders) => {
    let url = "/api/transport-orders/list";
    if (factory) url += `/${factory}`;
    const [transportOrders] = await customFetch<ITransportOrder[]>(url, { signal });
    return transportOrders;
  }, [customFetch]);
  
  const getTransportOrder = useCallback(async ({ signal, id }: IGetTransportOrder) => {
    const [transportOrders] = await customFetch<ITransportOrder>("/api/transport-orders/get/" + id, { signal });
    return transportOrders;
  }, [customFetch]);

  const saveTransportOrder = useCallback(async (data: ITransportOrder) => {
    const [transportOrders] = await customFetch<ITransportOrder>("/api/transport-orders/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return transportOrders;
  }, [customFetch]);
  
  const updateTransportOrder = useCallback(async (id: string, data: ITransportOrder) => {
    const [transportOrders] = await customFetch<ITransportOrder>("/api/transport-orders/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return transportOrders;
  }, [customFetch]);
  
  const deleteTransportOrder = useCallback(async (id: string) => {
    await customFetch<ITransportOrder>("/api/transport-orders/delete/" + id, { method: "DELETE" });
    return true;
  }, [customFetch]);
  
  return { fetchTransportOrders, getTransportOrder, saveTransportOrder, updateTransportOrder, deleteTransportOrder };
}
