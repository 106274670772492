import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useMeshOptions } from "../../../../hooks/useMeshOptions";
import { useOfferService } from "../../../../services/offers-service";
import Button from "../../../ui/Button/Button";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import OfferRowCalculationMesh, { EInputs, IOfferRowCalculationMeshRow } from "./OfferRowCalculationMesh";
import IOfferRow from "../../../../shared/IOfferRow";
import MeshesTotalTable from "./MeshesTotalTable";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationMesh {
  price: string;
  weight: string;
  rows: IOfferRowCalculationMeshRow[];
}

export const createMeshRow = (initialSquareMeters?: string): IOfferRowCalculationMeshRow => {
  const id = uuid();
  return { id, squareMeters: initialSquareMeters || "0", meshId: "", price: "0", weight: "0", unitName: "", type: "", weightSquare: "0", weightPrice: "0" };
}

const OfferRowCalculationMeshes: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationMesh>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationMesh: mesh, offerRowCalculationConcrete: concrete } = offerRow;
  const initialSquareMeters = useMemo(() => concrete.maxArea ? Number(concrete.maxArea).toFixed() : "0", [concrete.maxArea]);
  const { addHandler, deleteHandler, updateHandler } = useHandlers(mesh, updateOfferRow, initialSquareMeters);

  const { showControls } = useOfferProductsContext();

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (!showControls) return;
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "+":
          e.preventDefault();
          addHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addHandler, showControls]);

  const meshRowsRef = useRef<RefHandle<IOfferRowCalculationMeshRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const meshRows = meshRowsRef.current;
      const newRows: IOfferRowCalculationMeshRow[] = [];
      for (let i = 0; i < meshRows.length; i++) {
        const row = meshRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return { ...mesh, rows: [...newRows] };
    },
  }), [mesh]);

  return (
    <>
      <MeshesTotalTable calculation={mesh} total={offerRow.offerRowCalculationTotal} />
      <Table removeGap>
        <thead>
          <tr>
            <th>verkko</th>
            <th>ala, m²</th>
            {/* <th>tyyppi</th> */}
            <th>kg /m²</th>
            <th>€ /kg</th>
            <th>€ yhteensä</th>
            {/* <th>sEUR</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {mesh.rows?.map((calculation, i) => (
              <OfferRowCalculationMesh
                key={calculation.id}
                calculation={calculation}
                onDelete={() => deleteHandler(calculation.id)}
                ref={rowHandle => meshRowsRef.current[i] = rowHandle!}
                showControls={showControls}
              />
            ))}
          </InputContextProvider>
          {showControls && (
            <tr>
              <td colSpan={8}>
                <Button onClick={addHandler}>Uusi</Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

const useHandlers = (calculation: IOfferRowCalculationMesh, updateOfferRow: (newRow: Partial<IOfferRow>) => void, initialSquareMeters?: string) => {
  const { calculateOfferMesh } = useOfferService();
  const { meshes } = useMeshOptions();

  const addHandler = useCallback(() => {
    const offerRowCalculationMesh = {
      ...calculation,
      rows: [
        ...calculation.rows,
        createMeshRow(initialSquareMeters),
      ],
    };
    updateOfferRow({ offerRowCalculationMesh });
  }, [calculation, updateOfferRow, initialSquareMeters]);

  const deleteHandler = useCallback((id: string) => {
    const offerRowCalculationMesh = {
      ...calculation,
      rows: calculation.rows.filter((i) => i.id !== id),
    };
    updateOfferRow({ offerRowCalculationMesh });
  }, [calculation, updateOfferRow]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.MESH) {
      const data = _ as IOfferRowCalculationMeshRow;
      if (inputName === EInputs.meshId) {
        const selectedMesh = meshes?.find((s) => s.id === value);
        data.weightSquare = selectedMesh?.weightSquare ?? "0";
        data.weightPrice = selectedMesh?.weightPrice ?? "0";
      }
      const index = calculation.rows.findIndex(r => r.id === data.id);
      if (index > -1) {
        calculation.rows[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      const offerRowCalculationMesh = await calculateOfferMesh(calculation);
      updateOfferRow({ offerRowCalculationMesh });
    }
    return Promise.resolve(true);
  }, [calculateOfferMesh, calculation, meshes, updateOfferRow]);

  return { addHandler, deleteHandler, updateHandler };
}

export default forwardRef(OfferRowCalculationMeshes);
