import { useCallback, useMemo, useState } from "react";
import IElement from "../../../shared/IElement";
import IProductType from "../../../shared/IProductType";
import { IProjectPhase } from "../../../shared/IProject";
import { EInputType, IInputField, TInputValue } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import InputContextProvider, {
  EInputUpdateAction,
} from "../../ui/Input/input-context";

interface IProps {
  elements: IElement[];
  phase?: IProjectPhase;
  selectAllHandler: (productTypeId?: string) => void;
  unselectAllHandler: () => void;
}

enum EInputs {
  productTypeId = "productTypeId",
}

const ElementsSelectAll: React.FC<IProps> = ({ elements, phase, selectAllHandler, unselectAllHandler }) => {
  const { createInput } = useSelectAllInputs();

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.ELEMENTS_SELECT_ALL) {
        if (inputName === EInputs.productTypeId) {
            if (value) {
                selectAllHandler(value as string);
            } else {
                unselectAllHandler();
            }
        }
    }
    return Promise.resolve(true);
  }, [selectAllHandler, unselectAllHandler]);

  const selectAllProductTypeOptions = useMemo(() => {
    return elements
      ? [...(phase?.phaseId ? elements.filter((el) => el.phaseId === phase?.phaseId) : elements)]
          .filter((el) => !!el.productType)
          .map((el) => el.productType as IProductType)
          .filter((value, index, array) => array.findIndex((el) => el.id === value.id) === index)
          .map((productType) => ({ value: productType.id, label: productType.name }))
      : [];
  }, [elements, phase?.phaseId]);

  return (
    <div>
      {selectAllProductTypeOptions.length > 0 &&
      <InputContextProvider initDone onAutoUpdate={updateHandler}>
        {createInput(EInputs.productTypeId, {
          options: selectAllProductTypeOptions,
          containerStyles: { width: "200px" },
        })}
      </InputContextProvider>
      }
    </div>
  );
};

const useSelectAllInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.productTypeId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      menuPosition: "fixed",
      placeholder: "",
      label: "Valitse kaikki, joissa tyyppi",
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.ELEMENTS_SELECT_ALL,
  });

  return { createInput, submit, inputs };
};

export default ElementsSelectAll;
