import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ISlabType from "../../shared/ISlabType";
import Button from "../ui/Button/Button";
import { v4 as uuid } from "uuid";
import SlabTypeExtraName from "./SlabTypeExtraName";
import { RefHandle } from "../../shared/RefHandle";
import PageHeading from "../ui/PageHeading/PageHeading";

interface IProps {
  slabType?: ISlabType;
}

export interface IExtraName {
  id: string;
  name: string;
}

const SlabTypeExtraNames: React.ForwardRefRenderFunction<
  RefHandle<IExtraName[]>,
  IProps
> = ({ slabType }, ref) => {
  const [extraNames, setExtraNames] = useState<IExtraName[]>([]);

  useEffect(() => {
    if (slabType?.extraNames) {
      setExtraNames(
        slabType.extraNames.map((extraName) => ({
          id: uuid(),
          name: extraName,
        }))
      );
    }
  }, [slabType]);

  const addHandler = useCallback(() => {
    setExtraNames((extraNames) => [...extraNames, { id: uuid(), name: "" }]);
  }, []);

  const deleteHandler = useCallback((id: string) => {
    setExtraNames((extraNames) =>
      extraNames.filter((extraName) => extraName.id !== id)
    );
  }, []);

  const extraNamesRef = useRef<RefHandle<IExtraName>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const rows = extraNamesRef.current;
      const newRows: IExtraName[] = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return newRows;
    },
  }));

  return (
    <div style={{ margin: "1rem 0" }}>
      <PageHeading variant="h3" noHorizontalMargin>Lisänimet</PageHeading>
      {extraNames.map((extraName, i) => (
        <SlabTypeExtraName
          key={extraName.id}
          extraName={extraName}
          onDelete={() => deleteHandler(extraName.id)}
          ref={(rowHandle) => (extraNamesRef.current[i] = rowHandle!)}
        />
      ))}
      <Button onClick={addHandler}>Lisää nimi</Button>
    </div>
  );
};

export default forwardRef(SlabTypeExtraNames);
