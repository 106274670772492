import { CSSProperties, Fragment } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import IElementUnloading from "../../shared/IElementUnloading";
import { dateIsAfterDate, formatDate } from "../../utils/date-utils";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import { getItemStyle, getListStyle } from "./useElementUnloadingDragAndDrop";

interface IProps {
  item: IElementUnloading;
  isDropDisabled: boolean;
  isDragDisabled: boolean;
  style?: CSSProperties;
  isWaybill: boolean;
  waybillDeliveryDate?: string;
}

const ElementUnloadingTable: React.FC<IProps> = ({
  item,
  isDropDisabled,
  isDragDisabled,
  style,
  isWaybill,
  waybillDeliveryDate,
}) => {
  return (
    <>
      <Droppable droppableId={`${item.slot}`} isDropDisabled={isDropDisabled}>
        {(droppableProvided, droppableSnapshot) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={{
              ...getListStyle(droppableSnapshot.isDraggingOver),
              ...style,
            }}
          >
            <Table hover>
              <caption>
                <PageHeading variant="h3" noHorizontalMargin>
                  {item.slot === "TODO" ? "Vapaat elementit" : item.slot} {calculateItemTotalWeight(item)}
                </PageHeading>
              </caption>
              <thead>
                <tr>
                  <th style={{ width: "80px" }}>#</th>
                  <th style={{ width: "125px" }}>Työmaa</th>
                  <th style={{ width: "125px" }}>Tunnus</th>
                  <th style={{ width: "125px" }}>Pituus</th>
                  <th style={{ width: "125px" }}>Halkaisija</th>
                  <th style={{ width: "80px" }}>Paino ton</th>
                  {isWaybill && (
                    <>
                      <th style={{ width: "125px" }}>Petinumero</th>
                      <th style={{ width: "125px" }}>Valupvm</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {item.elements.map((element, index, array) => (
                  <Fragment key={element.id}>
                    <Draggable
                      key={element.id}
                      draggableId={element.id!}
                      index={index}
                      isDragDisabled={isDragDisabled || dateIsAfterDate(element.productionLineStartDate, waybillDeliveryDate, 1)}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <tr
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getItemStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style
                          )}
                        >
                          <td style={{ width: "80px" }}>{element.position}</td>
                          <td style={{ width: "125px" }}>{element.projectNumber ?? "!"}</td>
                          <td style={{ width: "125px" }}>{element.name ?? "!"}</td>
                          <td style={{ width: "125px" }}>{element.length ?? "!"}</td>
                          <td style={{ width: "125px", ...(element.provisionSummary?.hasSplit ? { background: "red", fontWeight: "bold" } : {}) }}>{element.width ?? "!"}</td>
                          <td style={{ width: "80px" }}>{element.weightTons ?? "!"}</td>
                          {isWaybill && (
                            <>
                              <td style={{ width: "125px" }}>{element.productionLineNumber ?? "!"}</td>
                              <td style={{ width: "125px", ...getDateStyle(element.productionLineStartDate, waybillDeliveryDate) }}>{element.productionLineStartDate ? formatDate(element.productionLineStartDate) : "!"}</td>
                            </>
                          )}
                        </tr>
                      )}
                    </Draggable>
                    {/* {index > 0 && index < array.length - 1 && (
                      <Button onClick={() => {}}>+</Button>
                    )} */}
                  </Fragment>
                ))}
                {droppableProvided.placeholder}
              </tbody>
            </Table>
          </div>
        )}
      </Droppable>
    </>
  );
};

const calculateItemTotalWeight = (item: IElementUnloading) => {
  let total = 0;
  if (item?.elements?.length > 0) {
    for (let i = 0; i < item.elements.length; i++) {
      const element = item.elements[i];
      total += +(element.weightTons ?? 0);
    }
  }
  return `(yhteensä ${total.toFixed(2)} ton)`;
};

const getDateStyle = (productionLineStartDate?: string, waybillDeliveryDate?: string): CSSProperties => {
  if (!productionLineStartDate || !waybillDeliveryDate) return {};
  if (dateIsAfterDate(productionLineStartDate, waybillDeliveryDate, 1)) return { fontFamily: "cursive" };
  return {};
}

export default ElementUnloadingTable;
