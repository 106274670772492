import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useUserOptions } from "../../hooks/useUserOptions";
import { useOrganizationService } from "../../services/organizations-service";
import IOrganization, { OrganizationType } from "../../shared/IOrganization";
import IUser from "../../shared/IUser";
import { useOrganizationEditModal } from "../OrganizationEditModal/useOrganizationEditModal";
import { IOrganizationsSearch } from "../Organizations/OrganizationsSearch";
import Input, { EInputType, IInputField, TFetchOption } from "../ui/Input/Input";
import { updateInputHandler } from "../ui/Input/input-utils";
import { useInputs } from "../ui/Input/useInputs";
import { useUserEditModal } from "../UserEditModal/useUserEditModal";

interface IProps {
  label?: string;
  type: OrganizationType;
  initialData?: IOrganizationUserSelect;
  organizationRequired?: boolean;
  disabled?: boolean;
}

enum EInputs {
  organizationId = "organizationId",
  businessId = "businessId",
  userId = "userId",
  phoneNumber = "phoneNumber",
  email = "email",
}

export interface IOrganizationUserSelectHandle {
  getData: () => Promise<IGetData | null | undefined>;
}

interface IGetData {
  organization: IOrganization;
  user?: IUser;
}

interface IOrganizationUserSelect {
  organization?: IOrganization;
  organizationId: string;
  userId: string;
}

const OrganizationUserSelect: React.ForwardRefRenderFunction<
  IOrganizationUserSelectHandle,
  IProps
> = ({ label, type, initialData, organizationRequired, disabled }, ref) => {
  const [data] = useState(initialData);
  const { createSelectInput, submitSelect, selectInputs, setSelectInputs } = useSelectInputs(data, organizationRequired);
  const [organizations, setOrganizations] = useState<IOrganization[]>(initialData?.organization ? [initialData.organization] : []);
  
  const organizationId = useMemo(() => selectInputs[EInputs.organizationId].value as string, [selectInputs]);

  const { options: userOptions, loading: userOptionsLoading, users } = useUserOptions({ organizationId });
  const userId = useMemo(() => selectInputs[EInputs.userId].value as string, [selectInputs]);

  const { fetchOrganizations } = useOrganizationService();

  const fetchOptions: TFetchOption = useCallback(async (input, signal) => {
    const search: IOrganizationsSearch = {type: type, name: input};
    const fetchedOrganizations = await fetchOrganizations({ signal, search });
    setOrganizations(fetchedOrganizations);
    return fetchedOrganizations.map((organization: IOrganization) => ({
      value: organization.id,
      label: organization.name,
    }));
  }, [fetchOrganizations, type]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const selectData = await submitSelect();
      // console.log(selectData);
      if (!selectData) return null;
      const selectedOrganization = organizations?.find(o => o.id === selectData.organizationId);
      const selectedUser = users?.find(u => u.id === selectData.userId);
      // console.log("getData", selectData, userData, selectedOrganization);
      return {
        organization: selectedOrganization!,
        user: selectedUser ? {
          ...selectedUser,
        } : undefined,
      };
    },
  }), [submitSelect, users, organizations]);

  const selectedOrganization = useMemo(() => organizations?.find(o => o.id === organizationId), [organizationId, organizations]);
  const openOrganizationEditModal = useOrganizationEditModal(type, selectedOrganization);

  const addOrganizationHandler = useCallback(async () => {
    const newOrganization = await openOrganizationEditModal();
    if (newOrganization) {
      setOrganizations(organizations => {
        const newOrganizations = [...organizations];
        const index = newOrganizations.findIndex(organization => organization.id === newOrganization.id);
        if (index > -1) {
          newOrganizations[index] = newOrganization;
        } else {
          newOrganizations.push(newOrganization);
        }
        return newOrganizations;
      });
      updateInputHandler(EInputs.organizationId, newOrganization.id, setSelectInputs);
    }
  }, [openOrganizationEditModal, setSelectInputs]);

  const selectedUser = useMemo(() => users?.find(u => u.id === userId), [users, userId]);
  const openUserEditModal = useUserEditModal(type, organizationId, selectedUser);

  const addUserHandler = useCallback(async () => {
    const userId = await openUserEditModal();
    if (userId) {
      updateInputHandler(EInputs.userId, userId, setSelectInputs);
    }
  }, [openUserEditModal, setSelectInputs]);

  const organizationOptions = useMemo(() => organizations.map(organization => ({value: organization.id, label: organization.name})) ?? [], [organizations]);
  return (
    <div>
      {createSelectInput(EInputs.organizationId, {
        disabled,
        options: organizationOptions,
        fetchOptions,
        placeholder: label,
        postButtonText: <FontAwesomeIcon icon={organizationId ? faEdit : faAdd} />,
        onPostButtonClick: disabled ? undefined : addOrganizationHandler,
      })}
      {createDisabledInput(EInputs.businessId, "Y-Tunnus", selectedOrganization?.businessId)}
      {createSelectInput(EInputs.userId, {
        disabled,
        options: userOptions,
        loading: userOptionsLoading,
        postButtonText: organizationId && <FontAwesomeIcon icon={userId ? faEdit : faAdd} />,
        onPostButtonClick: disabled ? undefined : organizationId ? addUserHandler : undefined,
      })}
      {createDisabledInput(EInputs.phoneNumber, "Puhelinnumero", selectedUser?.phoneNumber)}
      {createDisabledInput(EInputs.email, "Sähköposti", selectedUser?.email)}
    </div>
  );
};

const useSelectInputs = (data?: IOrganizationUserSelect, organizationRequired?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.organizationId]: {
      type: EInputType.reactAsyncSelect,
      options: [],
      validation: organizationRequired ? {required: true} : undefined,
      value: "",
    },
    [EInputs.userId]: {
      type: EInputType.reactSelect,
      placeholder: "Yht. Hlo",
      options: [],
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createSelectInput: createInput, submitSelect: submit, selectInputs: inputs, setSelectInputs: setInputs };
};

const createDisabledInput = (inputName: string, placeholder: string, value?: string) => (
  <Input inputName={inputName} type={EInputType.text} value={value ?? ""} placeholder={placeholder} disabled={true} onChange={() => {}} />
);

export default forwardRef(OrganizationUserSelect);
