import { RouteObject } from "react-router-dom";
import GuardedRoute from "./components/GuardedRoute";
import AuthPage from "./pages/AuthPage";
import ClaimPage from "./pages/ClaimPage";
import ClaimsPage from "./pages/ClaimsPage";
import ConcreteTypePage from "./pages/ConcreteTypePage";
import ConcreteTypesImportPage from "./pages/ConcreteTypesImportPage";
import ConcreteTypesPage from "./pages/ConcreteTypesPage";
import CustomerPage from "./pages/CustomerPage";
import CustomersPage from "./pages/CustomersPage";
import ElementQRCodePage from "./pages/ElementQRCodePage";
import EngineerPage from "./pages/EngineerPage";
import EngineersPage from "./pages/EngineersPage";
import FactoriesPage from "./pages/FactoriesPage";
import FactoryPage from "./pages/FactoryPage";
import InsulationPage from "./pages/InsulationPage";
import InsulationsImportPage from "./pages/InsulationsImportPage";
import InsulationsPage from "./pages/InsulationsPage";
import LogoutPage from "./pages/LogoutPage";
import MeshesPage from "./pages/MeshesPage";
import MeshImportPage from "./pages/MeshImportPage";
import MeshPage from "./pages/MeshPage";
import OfferPage from "./pages/OfferPage";
import OfferPrintPage from "./pages/OfferPrintPage";
import OffersPage from "./pages/OffersPage";
import OfferTermPage from "./pages/OfferTermPage";
import OfferTermsPage from "./pages/OfferTermsPage";
import OrderPrintPage from "./pages/OrderPrintPage";
import ProductGroupPage from "./pages/ProductGroupPage";
import ProductGroupsPage from "./pages/ProductGroupsPage";
import ProductionLineElementsPage from "./pages/ProductionLineElements";
import ProductionLinePage from "./pages/ProductionLinePage";
import ProductionLinePrintPage from "./pages/ProductionLinePrintPage";
import ProductionLinePrintQRPage from "./pages/ProductionLinePrintQRPage";
import ProductionLinesPage from "./pages/ProductionLinesPage";
import ProductTypePage from "./pages/ProductTypePage";
import ProductTypesPage from "./pages/ProductTypesPage";
import ProjectPage from "./pages/ProjectPage";
import ProjectsPage from "./pages/ProjectsPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import SlabTypePage from "./pages/SlabTypePage";
import SlabTypesPage from "./pages/SlabTypesPage";
import SteelPage from "./pages/SteelPage";
import SteelPartPage from "./pages/SteelPartPage";
import SteelPartsImportPage from "./pages/SteelPartsImportPage";
import SteelPartsPage from "./pages/SteelPartsPage";
import SteelsPage from "./pages/SteelsPage";
import TransportationCostPage from "./pages/TransportationCostPage";
import TransportationCostsImportPage from "./pages/TransportationCostsImportPage";
import TransportationCostsPage from "./pages/TransportationCostsPage";
import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import WaybillPage from "./pages/WaybillPage";
import WaybillPrintElementsPage from "./pages/WaybillPrintElementsPage";
import WaybillPrintPage from "./pages/WaybillPrintPage";
import WaybillQRCodePage from "./pages/WaybillQRCodePage";
import WaybillsPage from "./pages/WaybillsPage";
import WeeklyProductionLinesPage from "./pages/WeeklyProductionLinesPage";
import WeeklyProductionPage from "./pages/WeeklyProductionPage";
import WireTypePage from "./pages/WireTypePage";
import WireTypesPage from "./pages/WireTypesPage";
import { UserRole } from "./shared/IUser";
import HomePage from "./pages/HomePage";
import TransportOrdersPage from "./pages/TransportOrdersPage";
import TransportOrderPage from "./pages/TransportOrderPage";

export enum ERoute {
  home = "",
  auth = "auth",
  users = "users",
  settings = "settings",
  user = "users/:id",
  logout = "logout",
  customers = "customers",
  customer = "customers/:id",
  steelParts = "steel-parts",
  steelPart = "steel-parts/:id",
  steelPartsImport = "steel-parts/import",
  engineers = "engineers",
  engineer = "engineers/:id",
  offers = "offers",
  offer = "offers/:id/*",
  insulations = "insulations",
  insulation = "insulations/:id",
  insulationsImport = "insulations/import",
  meshes = "meshes",
  mesh = "meshes/:id",
  meshesImport = "meshes/import",
  steels = "steels",
  steel = "steels/:id",
  concreteTypes = "concrete-types",
  concreteType = "concrete-types/:id",
  concreteTypesImport = "concrete-types/import",
  transportationCosts = "transportation-costs",
  transportationCost = "transportation-costs/:id",
  transportationCostsImport = "transportation-costs/import",
  slabTypes = "slab-types",
  slabType = "slab-types/:id",
  productGroups = "product-groups",
  productGroup = "product-groups/:id",
  productTypes = "product-types",
  productType = "product-types/:id",
  offerTerms = "offer-terms",
  offerTerm = "offer-terms/:id",
  offerPrint = "offer-print/:id",
  orderPrint = "order-print/:id",
  // offerCopy = "offer-copy/:id",
  factories = "factories",
  factory = "factories/:id",
  claims = "claims",
  claim = "claims/:id",
  projects = "projects",
  project = "projects/:id/*",
  waybills = "waybills",
  waybill = "waybills/:id",
  waybillPrint = "waybill-print/:id",
  waybillPrintElements = "waybill-print-elements/:id",
  reports = "reports",
  weeklyProduction = "weekly-production",
  productionLines = "production-lines",
  productionLine = "production-lines/:id",
  weeklyProductionLines = "weekly-production-lines",
  elementQRCode = "qr/element/:uuid",
  waybillQRCode = "qr/waybill/:uuid",
  productionLineElements = "production-line-elements/:id",
  productionLinePrint = "production-line-print/:id",
  productionLineQRCode = "qr/production-line/:uuid",
  wireTypes = "wire-types",
  wireType = "wire-types/:id",
  transportOrders = "transport-orders",
  transportOrder = "transport-orders/:id",
}

interface Params {
  [key: string]: string;
}

export class Route {
  static home = "/";
  static auth = `/${ERoute.auth}`;
  static users = `/${ERoute.users}`;
  static user = (id: string) => `/${this.withParams(ERoute.user, { id })}`;
  static logout = `/${ERoute.logout}`;
  static customers = `/${ERoute.customers}`;
  static customer = (id: string) => `/${this.withParams(ERoute.customer, { id })}`;
  static engineers = `/${ERoute.engineers}`;
  static engineer = (id: string) => `/${this.withParams(ERoute.engineer, { id })}`;
  // static offer = (id: string) => `/${this.withParams(ERoute.offer, { id })}`;
  static offers = `/${ERoute.offers}`;
  static offer = (id: string) => `/offers/${id}`;
  static steelParts = `/${ERoute.steelParts}`;
  static steelPart = (id: string) => `/${this.withParams(ERoute.steelPart, { id })}`;
  static steelPartsImport = `/${ERoute.steelPartsImport}`;
  static insulations = `/${ERoute.insulations}`;
  static insulation = (id: string) => `/${this.withParams(ERoute.insulation, { id })}`;
  static insulationsImport = `/${ERoute.insulationsImport}`;
  static meshes = `/${ERoute.meshes}`;
  static mesh = (id: string) => `/${this.withParams(ERoute.mesh, { id })}`;
  static meshesImport = `/${ERoute.meshesImport}`;
  static steels = `/${ERoute.steels}`;
  static steel = (id: string) => `/${this.withParams(ERoute.steel, { id })}`;
  static concreteTypes = `/${ERoute.concreteTypes}`;
  static concreteType = (id: string) => `/${this.withParams(ERoute.concreteType, { id })}`;
  static concreteTypesImport = `/${ERoute.concreteTypesImport}`;
  static transportationCosts = `/${ERoute.transportationCosts}`;
  static transportationCost = (id: string) => `/${this.withParams(ERoute.transportationCost, { id })}`;
  static transportationCostsImport = `/${ERoute.transportationCostsImport}`;
  static slabTypes = `/${ERoute.slabTypes}`;
  static slabType = (id: string) => `/${this.withParams(ERoute.slabType, { id })}`;
  static productGroups = `/${ERoute.productGroups}`;
  static productGroup = (id: string) => `/${this.withParams(ERoute.productGroup, { id })}`;
  static productTypes = `/${ERoute.productTypes}`;
  static productType = (id: string) => `/${this.withParams(ERoute.productType, { id })}`;
  static offerTerms = `/${ERoute.offerTerms}`;
  static offerTerm = (id: string) => `/${this.withParams(ERoute.offerTerm, { id })}`;
  static factories = `/${ERoute.factories}`;
  static factory = (id: string) => `/${this.withParams(ERoute.factory, { id })}`;
  static offerPrint = (id: string) => `/${this.withParams(ERoute.offerPrint, { id })}`;
  static orderPrint = (id: string) => `/${this.withParams(ERoute.orderPrint, { id })}`;
  static offerCopy = (id: string) => `/offers/${id}/copy`;
  static claims = `/${ERoute.claims}`;
  static claim = (id: string) => `/${this.withParams(ERoute.claim, { id })}`;
  static projects = `/${ERoute.projects}`;
  // static project = (id: string) => `/${this.withParams(ERoute.project, { id })}`;
  static project = (id: string) => `/projects/${id}`;
  static waybills = `/${ERoute.waybills}`;
  static waybill = (id: string) => `/${this.withParams(ERoute.waybill, { id })}`;
  static waybillPrint = (id: string) => `/${this.withParams(ERoute.waybillPrint, { id })}`;
  static waybillPrintElements = (id: string) => `/${this.withParams(ERoute.waybillPrintElements, { id })}`;
  static reports = `/${ERoute.reports}`;
  static productionLines = `/${ERoute.productionLines}`;
  static productionLine = (id: string) => `/${this.withParams(ERoute.productionLine, { id })}`;
  static productionLineElements = (id: string) => `/${this.withParams(ERoute.productionLineElements, { id })}`;
  static productionLinePrint = (id: string) => `/${this.withParams(ERoute.productionLinePrint, { id })}`;
  static wireTypes = `/${ERoute.wireTypes}`;
  static wireType = (id: string) => `/${this.withParams(ERoute.wireType, { id })}`;
  static elementQRCode = (uuid: string) => `/${this.withParams(ERoute.elementQRCode, { uuid })}`;
  static waybillQRCode = (uuid: string) => `/${this.withParams(ERoute.waybillQRCode, { uuid })}`;
  static productionLineQRCode = (uuid: string) => `/${this.withParams(ERoute.productionLineQRCode, { uuid })}`;
  static transportOrders = `/${ERoute.transportOrders}`;
  static transportOrder = (id: string) => `/${this.withParams(ERoute.transportOrder, { id })}`;
  
  private static withParams(route: ERoute, params: Params): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
    }
    return routeWithParams;
  }
}

export type TRoute = {
  roles: UserRole[];
} & RouteObject;

export interface IRoutes {
  [key: string]: TRoute;
}

export const BASIC_ROLE: UserRole[] = ["BASIC", "ADMIN", "SELLER"];
// const ADMIN_ROLE: UserRole[] = ["ADMIN"];
export const PRODUCTION_ROLE: UserRole[] = ["PRODUCTION", "LOGISTICS"];

// Offer routes
const offerRoutes: IRoutes = {
  [ERoute.offers]: {
    element: <GuardedRoute route={OffersPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.offer]: {
    element: <GuardedRoute route={OfferPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.offerPrint]: {
    element: <GuardedRoute route={OfferPrintPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.orderPrint]: {
    element: <GuardedRoute route={OrderPrintPage} />,
    roles: BASIC_ROLE,
  },
  // [ERoute.offerCopy]: {
  //   element: <GuardedRoute route={OfferCopyPage} />,
  //   roles: BASIC_ROLE,
  // },
};

// User management routes
const userManagementRoutes: IRoutes = {
  [ERoute.settings]: {
    element: <GuardedRoute route={SettingsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.users]: {
    element: <GuardedRoute route={UsersPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.user]: {
    element: <GuardedRoute route={UserPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.customers]: {
    element: <GuardedRoute route={CustomersPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.customer]: {
    element: <GuardedRoute route={CustomerPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.engineers]: {
    element: <GuardedRoute route={EngineersPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.engineer]: {
    element: <GuardedRoute route={EngineerPage} />,
    roles: BASIC_ROLE,
  },
};

// Data routes
const dataRoutes: IRoutes = {
  [ERoute.steelParts]: {
    element: <GuardedRoute route={SteelPartsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.steelPart]: {
    element: <GuardedRoute route={SteelPartPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.steelPartsImport]: {
    element: <GuardedRoute route={SteelPartsImportPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.insulations]: {
    element: <GuardedRoute route={InsulationsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.insulation]: {
    element: <GuardedRoute route={InsulationPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.insulationsImport]: {
    element: <GuardedRoute route={InsulationsImportPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.meshes]: {
    element: <GuardedRoute route={MeshesPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.mesh]: {
    element: <GuardedRoute route={MeshPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.meshesImport]: {
    element: <GuardedRoute route={MeshImportPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.steels]: {
    element: <GuardedRoute route={SteelsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.steel]: {
    element: <GuardedRoute route={SteelPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.concreteTypes]: {
    element: <GuardedRoute route={ConcreteTypesPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.concreteType]: {
    element: <GuardedRoute route={ConcreteTypePage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.concreteTypesImport]: {
    element: <GuardedRoute route={ConcreteTypesImportPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.transportationCosts]: {
    element: <GuardedRoute route={TransportationCostsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.transportationCost]: {
    element: <GuardedRoute route={TransportationCostPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.transportationCostsImport]: {
    element: <GuardedRoute route={TransportationCostsImportPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.slabTypes]: {
    element: <GuardedRoute route={SlabTypesPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.slabType]: {
    element: <GuardedRoute route={SlabTypePage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.productGroups]: {
    element: <GuardedRoute route={ProductGroupsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.productGroup]: {
    element: <GuardedRoute route={ProductGroupPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.productTypes]: {
    element: <GuardedRoute route={ProductTypesPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.productType]: {
    element: <GuardedRoute route={ProductTypePage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.offerTerms]: {
    element: <GuardedRoute route={OfferTermsPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.offerTerm]: {
    element: <GuardedRoute route={OfferTermPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.factories]: {
    element: <GuardedRoute route={FactoriesPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.factory]: {
    element: <GuardedRoute route={FactoryPage} />,
    roles: BASIC_ROLE,
  },
  [ERoute.wireTypes]: {
    element: <GuardedRoute route={WireTypesPage} />,
    roles: [...BASIC_ROLE],
  },
  [ERoute.wireType]: {
    element: <GuardedRoute route={WireTypePage} />,
    roles: [...BASIC_ROLE],
  },
  [ERoute.reports]: {
    element: <GuardedRoute route={ReportsPage} />,
    roles: [...BASIC_ROLE],
  },
}

// Production, logistics routes
const productionRoutes: IRoutes = {
  [ERoute.claims]: {
    element: <GuardedRoute route={ClaimsPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
  [ERoute.claim]: {
    element: <GuardedRoute route={ClaimPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
  [ERoute.projects]: {
    element: <GuardedRoute route={ProjectsPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
  [ERoute.project]: {
    element: <GuardedRoute route={ProjectPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
  [ERoute.weeklyProduction]: {
    element: <GuardedRoute route={WeeklyProductionPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
  [ERoute.weeklyProductionLines]: {
    element: <GuardedRoute route={WeeklyProductionLinesPage} />,
    roles: [...BASIC_ROLE, "PRODUCTION"],
  },
};

const logisticsRoutes: IRoutes = {
  [ERoute.transportOrders]: {
    element: <GuardedRoute route={TransportOrdersPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.transportOrder]: {
    element: <GuardedRoute route={TransportOrderPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.waybills]: {
    element: <GuardedRoute route={WaybillsPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.waybill]: {
    element: <GuardedRoute route={WaybillPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.waybillPrint]: {
    element: <GuardedRoute route={WaybillPrintPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.waybillPrintElements]: {
    element: <GuardedRoute route={WaybillPrintElementsPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.productionLines]: {
    element: <GuardedRoute route={ProductionLinesPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.productionLine]: {
    element: <GuardedRoute route={ProductionLinePage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.productionLineElements]: {
    element: <GuardedRoute route={ProductionLineElementsPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  [ERoute.productionLinePrint]: {
    element: <GuardedRoute route={ProductionLinePrintPage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
};

// Public, no auth routes
const publicRoutes: IRoutes = {
  [ERoute.elementQRCode]: {
    element: <ElementQRCodePage />,
    roles: [],
  },
  [ERoute.waybillQRCode]: {
    element: <WaybillQRCodePage />,
    roles: [],
  },
  [ERoute.productionLineQRCode]: {
    element: <ProductionLinePrintQRPage />,
    roles: [],
  },
};

export const routes: IRoutes = {
  [ERoute.auth]: {
    element: <AuthPage />,
    roles: [],
  },
  [ERoute.logout]: {
    element: <LogoutPage />,
    roles: [],
  },
  [ERoute.home]: {
    index: true,
    element: <GuardedRoute route={HomePage} />,
    roles: [...BASIC_ROLE, ...PRODUCTION_ROLE],
  },
  ...offerRoutes,
  ...userManagementRoutes,
  ...dataRoutes,
  ...productionRoutes,
  ...logisticsRoutes,
  ...publicRoutes,
};
