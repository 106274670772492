import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import RootLayout from "./pages/RootLayout";
import { Route, routes } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalContextProvider } from "./components/ui/Modal/modal-context";
import ErrorPage from "./pages/ErrorPage";

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const router = createBrowserRouter([
  {
    path: Route.home,
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: Object.keys(routes).map((key) => ({
      path: key,
      ...routes[key],
    })),
  },
]);

function App() {
  return (
    <ModalContextProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ModalContextProvider>
  );
}

export default App;
