import { useQuery } from "@tanstack/react-query";
import { addMonths, format, subYears } from "date-fns";
import { useMemo, useState } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useProjectsService } from "../../services/projects-service";
import { QueryKey } from "../../services/query-keys";
import IProject from "../../shared/IProject";
import { storage } from "../../utils/localstorage-utils";
import { IProjectsSearch } from "./ProjectsSearch";

const DEFAULT_START_DATE = format(subYears(new Date(), 1), 'yyyy-MM-dd');
const DEFAULT_END_DATE = format(addMonths(new Date(), 1), 'yyyy-MM-dd');

export const useProjectsSearch = (customerId?: string | null) => {
  const searchJson = useMemo(() => storage.getProjectsSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IProjectsSearch | null>({
    ...initialSearch, 
    customerId: customerId ?? initialSearch?.customerId,
    offerDateStart: initialSearch?.offerDateStart || DEFAULT_START_DATE,
    offerDateEnd: initialSearch?.offerDateEnd || DEFAULT_END_DATE,
  });
  const { fetchProjects } = useProjectsService();

  const {
    data: projects,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projects],
    queryFn: ({ signal }) => fetchProjects({ signal, search }),
    staleTime: 5000,
  });

  return {
    projects,
    isLoading: isPending || isRefetching,
    isError,
    error,
    search,
    setSearch,
    refetch,
  };
};
