import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { useWaybillsService } from "../../services/waybills-service";
import IWaybill from "../../shared/IWaybill";
import { IWaybillsSearch } from "./WaybillsSearch";

interface IProps {
  search?: IWaybillsSearch | null;
  projectId?: string;
  transportOrderId?: string;
}

export const useWaybillsSearch = ({ search, projectId, transportOrderId }: IProps) => {
  const { fetchWaybills } = useWaybillsService();

  const {
    data: waybills,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IWaybill[], ErrorResponse>({
    queryKey: [QueryKey.waybills, projectId ?? transportOrderId ?? ""],
    queryFn: ({ signal }) => fetchWaybills({ signal, search: search ? search : { projectId, transportOrderId } }),
    staleTime: 5000,
  });

  return {
    waybills,
    isLoading: isPending || isRefetching,
    isError,
    error,
    refetch,
  };
};
