import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import IProductionLine from "../shared/IProductionLine";
import ProductionLinePrintQR from "../components/ProductionLines/ProductionLinePrint/ProductionLinePrintQR";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";

const ProductionLinePrintQRPage: React.FC = () => {
  const { uuid } = useParams();
  const { getProductionLineByUuid } = usePublicQRCodesService();
  
  const {
    data: productionLine,
    isLoading,
    isError,
    error,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, uuid],
    queryFn: ({ signal }) => getProductionLineByUuid({ signal, id: uuid! }),
  });
  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : productionLine && (
          <ProductionLinePrintQR productionLine={productionLine} />
        )}
    </>
  )
}
export default ProductionLinePrintQRPage;