import { useNavigate } from "react-router-dom";
import { useTransportOrdersService } from "../../services/transportOrders-service";
import { useQuery } from "@tanstack/react-query";
import ITransportOrder from "../../shared/ITransportOrder";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { Route } from "../../routes";
import Table from "../ui/Table/Table";
import { formatDate } from "../../utils/date-utils";
import { EFactory } from "../../shared/IOfferRow";

interface IProps {
  factory: EFactory;
}

const TransportOrdersList: React.FC<IProps> = ({ factory }) => {
  const { fetchTransportOrders } = useTransportOrdersService();

  const navigate = useNavigate();
  const {
    data: transportOrders,
    isPending,
    isError,
    error,
  } = useQuery<ITransportOrder[], ErrorResponse>({
    queryKey: [QueryKey.transportOrders, factory],
    queryFn: ({ signal }) => fetchTransportOrders({ signal, factory }),
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!transportOrders || transportOrders.length === 0) {
    return <p>Ei kuljetustilauksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.transportOrder(id));
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Numero</th>
          <th>Tehdas</th>
          <th>Työmaa</th>
          <th>Kuljetuksen pvm</th>
          <th>Tonnit</th>
          <th>Kpl</th>
        </tr>
      </thead>
      <tbody>
        {transportOrders.map((transportOrder) => (
          <tr key={transportOrder.id} onClick={() => openHandler(transportOrder.id)}>
            <td>{transportOrder.transportOrderNumber}</td>
            <td>{transportOrder.factory}</td>
            <td>{transportOrder.projectNumber}</td>
            <td>{formatDate(transportOrder.deliveryDate)} {transportOrder.deliveryTime}</td>
            <td>{transportOrder.tons}</td>
            <td>{transportOrder.count}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TransportOrdersList;
