import { useCallback } from "react";
import { useSlabProductTypeOptions } from "../../hooks/useSlabProductTypeOptions";
import IElement from "../../shared/IElement";
import { IOption } from "../ui/Input/Input";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ElementsUpdateModal from "./ElementsUpdateModal";

export const useElementsUpdateModal = () => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.ELEMENTS_UPDATE),
    [closeModal]
  );

  const {
    options: productTypesOptions,
    loading: productTypesLoading,
    slabProductTypes,
  } = useSlabProductTypeOptions();

  const handler = (phaseOptions: IOption[]): Promise<IElement | null> => {
    let title = "Muokkaa elementtejä";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.ELEMENTS_UPDATE,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <ElementsUpdateModal
            onAccept={async (data) => {
              try {
                resolve(data);
                closeModalHandler();
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            phaseOptions={phaseOptions}
            productTypesOptions={productTypesOptions}
            productTypesLoading={productTypesLoading}
            productTypes={slabProductTypes}
          />
        ),
      });
    });
  };

  return handler;
};
