import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IMesh from "../../shared/IMesh";
import { useMeshService } from "../../services/mesh-service";
import { useState } from "react";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";

interface IProps {
  meshes: IMesh[];
  showUpdateArchived?: boolean
}

const MeshTable: React.FC<IProps> = ({ meshes, showUpdateArchived }) => {
  const navigate = useNavigate();
  const { archivedUpdateMesh } = useMeshService();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateMesh(id);
      queryClient.setQueryData([QueryKey.meshes], (meshes: IMesh[]) => 
        meshes.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.mesh(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Tyyppi</th>
          <th>Neliöpaino</th>
          <th>Painohinta</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {meshes.map((mesh) => (
          <tr key={mesh.id} onClick={() => openHandler(mesh.id)} style={{ color: mesh.archived ? 'gray' : 'inherit', fontStyle: mesh.archived ? "italic" : 'inherit' }}>
            <td>{mesh.unitName}</td>
            <td>{mesh.type}</td>
            <td>{mesh.weightSquare}</td>
            <td>{mesh.weightPrice} €</td>
            <td>{mesh.updatedString}</td>
            {showUpdateArchived ?
            <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(mesh.id)} onClick={(event)=>archivedUpdateHandler(mesh.id, event)} icon={mesh.archived ? faEyeSlash : faEye} title={mesh.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            :
            <td>{mesh.archived && "arkistoitu"}</td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MeshTable;
