import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useTransportOrdersService } from "../services/transportOrders-service";
import ITransportOrder from "../shared/ITransportOrder";

interface IProps {
  factory?: string;
  factoryRequired?: boolean;
}

export const useTransportOrderOptions = (props: IProps = {}) => {
  const { factory, factoryRequired } = props;
  const { fetchTransportOrders } = useTransportOrdersService();

  const {
    data: transportOrders,
    isLoading: isPending,
    isError,
  } = useQuery<ITransportOrder[], ErrorResponse>({
    queryKey: [QueryKey.transportOrderOptions, factory ?? ""],
    queryFn: ({ signal }) => fetchTransportOrders({ signal, factory }),
    staleTime: 5000,
    enabled: factoryRequired ? !!factory : true,
  });

  const filteredTransportOrders = useMemo(() => factory ? [...transportOrders ?? []].filter((transportOrder) => transportOrder.factory === factory) : transportOrders ?? [], [factory, transportOrders]);

  const options = useMemo(() =>
      isError 
      ? [] 
      : filteredTransportOrders.map(transportOrder => ({ value: transportOrder.id, label: transportOrder.transportOrderNumber })),
    [filteredTransportOrders, isError]
  );

  return { options, loading: isPending, transportOrders: filteredTransportOrders };
};
