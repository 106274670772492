import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import IFactory from "../../shared/IFactory";
import { useFactoryService } from "../../services/factory-service";

interface IProps {
  id: string;
  factory?: IFactory;
}

enum EInputs {
  phoneNumber = "phoneNumber",
  phoneNumberShipping = "phoneNumberShipping",
  fax = "fax",
  email = "email",
  factoryFee = "factoryFee",
  ceId = "ceId",
  hollowStandardCett1 = "hollowStandardCett1",
  ceWallStandard = "ceWallStandard",
  cepId = "cepId",
  shellPlateStandard = "shellPlateStandard",
  cettStandard = "cettStandard",
  cett2 = "cett2",
  cett3 = "cett3",
  ceBeamStandardT1 = "ceBeamStandardT1",
  cept2 = "cept2",
  cept3 = "cept3",
  cept4 = "cept4",
  cept5 = "cept5",
  cept6 = "cept6",
  dopNumBeam = "dopNumBeam",
  dopYearBeam = "dopYearBeam",
  dopNumPillar = "dopNumPillar",
  dopYearPillar = "dopYearPillar",
  dopNumTtPlate = "dopNumTtPlate",
  dopYearTtPlate = "dopYearTtPlate",
  dopNumShellPlate = "dopNumShellPlate",
  dopYearShellPlate = "dopYearShellPlate",
  dopNumWall = "dopNumWall",
  dopYearWall = "dopYearWall",
  dopNumHollow = "dopNumHollow",
  dopYearHollow = "dopYearHollow",
}

const FactoryEdit: React.FC<IProps> = ({ id, factory }) => {
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.factories);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, redirectHandler);
  const { createInput, submit } = useFactoryInputs(factory);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error });

  return (
    <>
      <div style={{ display: "flex", width: "1000px" }}>
        <div style={{ width: "49%" }}>
          <div style={{ height: "72px" }}>
            <h2 style={{ margin: "0" }}>{factory?.factoryId} {factory?.name}</h2>
            <p><b>{factory?.streetAddress}, {factory?.city}, {factory?.zip}</b></p>
          </div>
          {createInput(EInputs.phoneNumber)}
          {createInput(EInputs.fax)}
          {createInput(EInputs.ceId)}
          {createInput(EInputs.hollowStandardCett1)}
          {createInput(EInputs.ceWallStandard)}
          {createInput(EInputs.shellPlateStandard)}
          {createInput(EInputs.cettStandard)}
          {createInput(EInputs.cett2)}
          {createInput(EInputs.cett3)}
        </div>
        <div style={{ width: "2%" }}/>
        <div style={{ width: "49%" }}>
          {createInput(EInputs.factoryFee)}
          {createInput(EInputs.phoneNumberShipping)}
          {createInput(EInputs.email)}
          {createInput(EInputs.cepId)}
          {createInput(EInputs.ceBeamStandardT1)}
          {createInput(EInputs.cept2)}
          {createInput(EInputs.cept3)}
          {createInput(EInputs.cept4)}
          {createInput(EInputs.cept5)}
          {createInput(EInputs.cept6)}
        </div>
      </div>
      <div style={{ display: "flex", width: "490px", marginTop: "2rem" }}>
        <div style={{ width: "55%" }}>
          <div style={{ height: "72px" }}><span><b>Jännebetonipalkit (P Palkit):</b></span></div>
          <div style={{ height: "72px" }}><span><b>Pilari (P Pilarit):</b></span></div>
          <div style={{ height: "72px" }}><span><b>Ripalaatta (todo):</b></span></div>
          <div style={{ height: "72px" }}><span><b>Kuorilaatta (L Kuoril):</b></span></div>
          <div style={{ height: "72px" }}><span><b>Seinäelementti (todo):</b></span></div>
          <div style={{ height: "72px" }}><span><b>Ontelolaatta (L Ontelot):</b></span></div>
        </div>
        <div style={{ width: "21%" }}>
          {createInput(EInputs.dopNumBeam)}
          {createInput(EInputs.dopNumPillar)}
          {createInput(EInputs.dopNumTtPlate)}
          {createInput(EInputs.dopNumShellPlate)}
          {createInput(EInputs.dopNumWall)}
          {createInput(EInputs.dopNumHollow)}
        </div>
        <div style={{ width: "3%" }}/>
        <div style={{ width: "21%" }}>
          {createInput(EInputs.dopYearBeam)}
          {createInput(EInputs.dopYearPillar)}
          {createInput(EInputs.dopYearTtPlate)}
          {createInput(EInputs.dopYearShellPlate)}
          {createInput(EInputs.dopYearWall)}
          {createInput(EInputs.dopYearHollow)}
        </div>
      </div>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
      />
    </>
  );
};

const useFactoryInputs = (data?: IFactory) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.phoneNumber]: {
      type: EInputType.tel,
      label: "Puhelinnumero",
      value: "",
    },
    [EInputs.phoneNumberShipping]: {
      type: EInputType.tel,
      label: "Puhelinnumero (Lähettämö)",
      value: "",
    },
    [EInputs.fax]: {
      type: EInputType.text,
      label: "Faksi",
      value: "",
    },
    [EInputs.email]: {
      type: EInputType.email,
      label: "Sähköposti",
      value: "",
    },
    [EInputs.factoryFee]: {
      type: EInputType.number,
      label: "KA Työkustannus",
      value: "",
    },
    [EInputs.ceId]: {
      type: EInputType.text,
      label: "CETunnus",
      value: "",
    },
    [EInputs.hollowStandardCett1]: {
      type: EInputType.text,
      label: "Ontelo normi CETT1",
      value: "",
    },
    [EInputs.ceWallStandard]: {
      type: EInputType.text,
      label: "CE Seinänormi",
      value: "",
    },
    [EInputs.cepId]: {
      type: EInputType.text,
      label: "CEPTunnus",
      value: "",
    },
    [EInputs.shellPlateStandard]: {
      type: EInputType.text,
      label: "Kuorilaattanormi",
      value: "",
    },
    [EInputs.cettStandard]: {
      type: EInputType.text,
      label: "CE TT Normi",
      value: "",
    },
    [EInputs.cett2]: {
      type: EInputType.text,
      label: "CE TT2",
      value: "",
    },
    [EInputs.cett3]: {
      type: EInputType.text,
      label: "CE TT3",
      value: "",
    },
    [EInputs.ceBeamStandardT1]: {
      type: EInputType.text,
      label: "CEPalkki normi T1",
      value: "",
    },
    [EInputs.cept2]: {
      type: EInputType.text,
      label: "CEPT2",
      value: "",
    },
    [EInputs.cept3]: {
      type: EInputType.text,
      label: "CEPT3",
      value: "",
    },
    [EInputs.cept4]: {
      type: EInputType.text,
      label: "CEPT4",
      value: "",
    },
    [EInputs.cept5]: {
      type: EInputType.text,
      label: "CEPT5",
      value: "",
    },
    [EInputs.cept6]: {
      type: EInputType.text,
      label: "CEPT6",
      value: "",
    },
    [EInputs.dopNumBeam]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearBeam]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
    [EInputs.dopNumPillar]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearPillar]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
    [EInputs.dopNumTtPlate]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearTtPlate]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
    [EInputs.dopNumShellPlate]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearShellPlate]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
    [EInputs.dopNumWall]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearWall]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
    [EInputs.dopNumHollow]: {
      type: EInputType.text,
      label: "Dop Numero",
      value: "",
    },
    [EInputs.dopYearHollow]: {
      type: EInputType.text,
      label: "Dop Vuosi",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, redirectHandler: () => void) => {
  const { updateFactory } = useFactoryService();

  const mutationFn = (data: IFactory) => {
    return updateFactory(id, data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IFactory, ErrorResponse, IFactory>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.factories, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.factories] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

export default FactoryEdit;
