import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IElementDTO from '../shared/IElementDTO';
import IWaybillDTO from '../shared/IWaybillDTO';
import IWaybill, { ILocation } from '../shared/IWaybill';
import { EFactory } from '../shared/IOfferRow';
import IProductionLine from '../shared/IProductionLine';

interface IGet extends ISignal {
    id: string;
}

export const usePublicQRCodesService = () => {
  const customFetch = useCustomFetch();

  const getElementByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [element] = await customFetch<IElementDTO>("/public/qr/elements/" + id, { signal });
    return element;
  }, [customFetch]);

  const getWaybillByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [waybill] = await customFetch<IWaybillDTO>("/public/qr/waybills/" + id, { signal });
    return waybill;
  }, [customFetch]);

  const getProductionLineByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [productionLine] = await customFetch<IProductionLine>("/public/qr/production-lines/" + id, { signal });
    return productionLine;
  }, [customFetch]);

  const fetchProductionLineElementsByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [elements] = await customFetch<IElementDTO[]>("/public/qr/production-lines-elements/" + id, { signal });
    return elements;
  }, [customFetch]);

  const saveLocationWaybill = useCallback(async (id: string, data: ILocation) => {
    await customFetch<IWaybill>("/public/qr/waybills-location/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return true;
  }, [customFetch]);

  const updateTimeWaybill = useCallback(async (id: string, data: Partial<IWaybill>) => {
    const [waybill] = await customFetch<IWaybill>("/public/qr/waybills-update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return waybill;
  }, [customFetch]);

  const locationInDistance = useCallback(async (factory: EFactory, data: ILocation) => {
    const [inDistance] = await customFetch<boolean>("/public/qr/elements-distance/" + factory, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return inDistance;
  }, [customFetch]);

  const saveLocationElement = useCallback(async (id: string, data: ILocation) => {
    await customFetch<IWaybill>("/public/qr/elements-location/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return true;
  }, [customFetch]);

  return { getElementByUuid, getWaybillByUuid, getProductionLineByUuid, fetchProductionLineElementsByUuid, saveLocationWaybill, updateTimeWaybill, locationInDistance, saveLocationElement };
}
